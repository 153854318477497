import React, { createContext, useCallback, useContext, useMemo, useRef, } from 'react';
import { refreshIfNeeded } from '../../utils/auth';
import { useAuthContext } from '../auth';
import styles from './download-conext.module.scss';
import { useAlert } from '../alert-context';
const DownloadContextRaw = createContext({
    download: () => Promise.resolve(),
});
export const useDownload = () => {
    const { download } = useContext(DownloadContextRaw);
    const { successAlert, errorAlert } = useAlert();
    return useCallback((link) => {
        download(link)
            .then(() => successAlert('Скачивание началось'))
            .catch(() => errorAlert('Ошибка при скачивании'));
    }, [download, successAlert, errorAlert]);
};
/** Контекст для загрузки файлов */
export const DownloadContext = ({ children }) => {
    /** Ссылка на anchor element */
    const linkRef = useRef(null);
    const { refreshToken } = useAuthContext();
    /** Контекстное значение */
    const contextValue = useMemo(() => ({
        download: link => refreshIfNeeded(refreshToken).then(() => {
            if (linkRef.current) {
                const anchor = linkRef.current;
                anchor.href = link;
                anchor.click();
                anchor.href = '/';
            }
        }),
    }), [refreshToken]);
    return (React.createElement(DownloadContextRaw.Provider, { value: contextValue },
        children,
        React.createElement("a", { download: true, "aria-label": 'download', href: '/', className: styles.hide, ref: linkRef })));
};
