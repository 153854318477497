import React from 'react';
import cn from 'classnames';
import styles from './tooltip.module.scss';
export const Tooltip = ({ isVisible = true, tooltipText, wrapperStyle = '', children, right, center, }) => (React.createElement("div", { className: cn(styles.tooltip, wrapperStyle) },
    children,
    React.createElement("span", { className: cn(styles.tooltipText, {
            [styles.tooltipTextVisible]: isVisible,
            [styles.tooltipTextRight]: right,
            [styles.tooltipTextCenter]: center,
        }) }, tooltipText)));
