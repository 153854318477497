export const parametersTabsLetters = [
    { tabTitle: 'Входящие', key: 0 },
    { tabTitle: 'Исходящие', key: 1 },
    { tabTitle: 'Черновики', key: 2 },
];
export const parametersTabsAccounts = [
    { tabTitle: 'Текущие', key: 0 },
    { tabTitle: 'Закрытые', key: 1 },
];
export const parametersTabsPayments = [
    { tabTitle: 'Все', key: 0 },
    { tabTitle: 'Черновики', key: 1 },
    { tabTitle: 'В обработке', key: 2 },
    { tabTitle: 'Завершены', key: 3 },
];
export const parametersTabsProfile = [{ tabTitle: 'Смена пароля', key: 0 }];
