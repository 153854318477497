import { LetterCategories, LetterStatuses, OrderStatuses } from '../api/apiModels';
/** Соответствие статусов письма */
export const StatusMapper = {
    [LetterStatuses.Draft]: 'Черновик',
    [LetterStatuses.Sent]: 'Отправлено',
    [LetterStatuses.Received]: 'Получено',
    [LetterStatuses.Signed]: 'Подписано',
    [LetterStatuses.Deleted]: 'Удалено',
};
/** Соответствие категорий */
export const CategoryMapper = {
    [LetterCategories.Base]: 'Письмо',
    [LetterCategories.Claim]: 'Обращение',
    [LetterCategories.RequestService]: 'Заявка',
};
/**
 * Соответствие категории письма и опции выбора
 * */
export const CategorySelectOptionMapper = {
    [LetterCategories.Base]: { label: 'Письмо', value: LetterCategories.Base },
    [LetterCategories.Claim]: { label: 'Обращение', value: LetterCategories.Claim },
    [LetterCategories.RequestService]: { label: 'Заявка', value: LetterCategories.RequestService },
};
/** Опции выбора категорий */
export const CategoriesForSelect = Object.values(CategorySelectOptionMapper);
/** Соответствие статусов платежа */
export const StatusSelectOptionPaymentMapper = {
    [OrderStatuses.New]: { value: OrderStatuses.New, label: 'Новый' },
    [OrderStatuses.Signed]: { value: OrderStatuses.Signed, label: 'Подписан' },
    [OrderStatuses.Sent]: { value: OrderStatuses.Sent, label: 'Принят к рассмотрению' },
    [OrderStatuses.Executed]: { value: OrderStatuses.Executed, label: 'Исполнен' },
    [OrderStatuses.Rejected]: { value: OrderStatuses.Rejected, label: 'Отказ' },
    [OrderStatuses.Processing]: { value: OrderStatuses.Processing, label: 'Принят в обработку' },
};
export const PaymentStatusSelectOptions = Object.values(StatusSelectOptionPaymentMapper);
