import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { LetterCategories } from '../../../submodules/inkh-letters/src/Inkh.Letters.Api.Client.TypeScript/Models/LetterCategories.Generated';
import { LetterDirections } from '../../../submodules/inkh-letters/src/Inkh.Letters.Api.Client.TypeScript/Models/LetterDirections.Generated';
import { BASE_PATH, CREATE_PATH, LETTERS } from '../../routes';
import { registerCurrentPath } from '../../routes/routes-mapper';
import { getDuplicateLetter, getLetterServices, getRepliedLetter, setDuplicateLetter, setRepliedLetter, } from '../../store/slice/appSlice';
import { useAppDispatch } from '../../store/store';
import { LetterStatuses, } from '../../utils/api/apiModels';
import { attachmentApi, letterApi } from '../../utils/api/generalApi';
import { CategoriesForSelect, CategorySelectOptionMapper, StatusMapper, } from '../../utils/enum-mappers';
import { LetterLayout, Preloader } from '../../utils/general-code-import/components-import';
import { extractErrorMessage, joinRoutePaths, useAlert, useAuthContext, useCustomNavigate, useDownload, } from '../../utils/general-code-import/config-import';
import { PLetterAttachmentsMapper, PLettersMapper, } from '../../utils/general-code-import/types-import';
/** Статические пропсы для карточки создания/просмотра письма */
const staticProps = {
    optionsSelect: CategoriesForSelect,
    isBase: (category) => category === LetterCategories.Base,
    isService: (category) => category === LetterCategories.RequestService,
};
/** Преобразование вложения для отображения в карточке */
const mapAttachments = (attachments) => attachments.map(item => ({
    id: item.id,
    name: item.realName ?? '',
    attachment: item,
}));
/** Обработчик загрузки файла */
const onUploadFile = (file) => attachmentApi.addAttachment2({ data: file, fileName: file.name }).then(res => ({
    files: res,
    id: res.id,
}));
/**
 * Страница письма
 * */
export const LetterForm = () => {
    /** Дубликат письма */
    const duplicateLetter = useSelector(getDuplicateLetter);
    /** Письмо на которое отвечают */
    const repliedLetter = useSelector(getRepliedLetter);
    const navigate = useCustomNavigate();
    const dispatch = useAppDispatch();
    const { errorAlert, successAlert } = useAlert();
    /** Услуги */
    const services = useSelector(getLetterServices);
    /** Опции услуг */
    const optionServices = useMemo(() => services.map(({ code, name, category }) => ({
        label: `${code} ${category} ${name}`,
        value: code,
    })), [services]);
    const download = useDownload();
    const { haveAccess, userCertificateThumbprints = [] } = useAuthContext();
    const pseudoStaticProps = useMemo(() => ({ ...staticProps, services: optionServices }), [optionServices]);
    /** Id письма из url */
    const { id } = useParams();
    /** режим создания */
    const isCreatePath = useLocation().pathname.endsWith(CREATE_PATH);
    /** Перейти в письма */
    const goToLetters = useCallback(() => navigate(BASE_PATH + LETTERS), [navigate]);
    const goToLetter = useCallback((id) => navigate(BASE_PATH + joinRoutePaths(LETTERS, id)), [navigate]);
    /** Текущее письмо */
    const [letter, setLetter] = useState(null);
    /** Дубликат письма */
    const [letterDuplicate, setLetterDuplicate] = useState(null);
    /** Создание дубликата или ответ */
    const [duplicateOrReplied, setDuplicateOrReplied] = useState(false);
    /** Загрузка письма */
    useEffect(() => {
        if (id && !letter) {
            letterApi
                .getLetter(id)
                .then(letter => {
                setLetter(letter);
                registerCurrentPath(`Письмо: ${letter.theme}`);
                setDuplicateOrReplied(false);
            })
                .catch(() => {
                errorAlert('Произошла ошибка');
                goToLetters();
            });
        }
    }, [errorAlert, goToLetters, id, letter]);
    /** Затирание дубликата письма */
    useEffect(() => {
        if (duplicateLetter !== null) {
            setLetterDuplicate(duplicateLetter);
            setDuplicateOrReplied(true);
            dispatch(setDuplicateLetter(null));
        }
    }, [dispatch, duplicateLetter]);
    /** Затирание ответного письма */
    useEffect(() => {
        if (repliedLetter) {
            setDuplicateOrReplied(true);
            dispatch(setRepliedLetter(null));
        }
    }, [repliedLetter, dispatch]);
    /** Загрузка вложения */
    const onDownloadAttachment = useCallback((id) => {
        if (haveAccess(PLetterAttachmentsMapper.GetBaseInfo)) {
            attachmentApi
                .downloadAttachmentLink(id)
                .then(download)
                .catch(() => errorAlert('Произошла ошибка'));
        }
    }, [download, errorAlert, haveAccess]);
    /** Создание дубдликата письма */
    const onCreateDuplicate = useCallback(() => {
        dispatch(setDuplicateLetter(letter));
        setLetter(null);
        navigate(BASE_PATH + joinRoutePaths(LETTERS, CREATE_PATH));
    }, [dispatch, letter, navigate]);
    /** Ответ на письмо */
    const onReply = useCallback(() => {
        dispatch(setRepliedLetter(letter));
        setLetter(null);
        navigate(BASE_PATH + joinRoutePaths(LETTERS, CREATE_PATH));
    }, [dispatch, letter, navigate]);
    /** Отправление письма */
    const onSendLetter = useCallback(() => {
        if (letter) {
            const promise = letter.status === LetterStatuses.Signed
                ? letterApi.sendSignedLetter({ letterId: letter.id })
                : letterApi.send({ letterId: letter.id });
            promise
                .then(() => {
                successAlert('Письмо успешно отправлено');
                goToLetters();
            })
                .catch(() => errorAlert('Произошла ошибка'));
        }
    }, [errorAlert, goToLetters, letter, successAlert]);
    /** Удаление письма */
    const onDeleteLetter = useCallback(() => {
        if (letter) {
            letterApi
                .delete({
                letterId: letter.id,
            })
                .then(() => {
                successAlert('Письмо успешно удалено');
                goToLetters();
            })
                .catch(() => errorAlert('Произошла ошибка'));
        }
    }, [errorAlert, goToLetters, letter, successAlert]);
    /** Создание данных для подписи */
    const createSignData = useCallback(({ body, originalLetterId, category, attachments, theme, serviceCode, }) => ({
        body,
        originalLetterId,
        category: category.value,
        theme,
        attachments: attachments.map(i => i.attachment),
        letterServiceName: services.find(i => i.code === serviceCode?.value)?.name,
    }), [services]);
    const onSubmit = useCallback((formLetter) => {
        if (letter) {
            return letterApi
                .updateLetter({
                letterId: letter.id,
                updateLetterProps: {
                    body: formLetter.body,
                    theme: formLetter.theme,
                    attachmentIds: formLetter.attachments.map(i => i.attachment.id),
                    letterServiceCode: formLetter?.serviceCode?.value,
                },
            })
                .then(({ id }) => {
                setLetter(null);
                successAlert('Письмо успешно изменено');
                goToLetter(id);
            })
                .catch(() => {
                errorAlert('Произошла ошибка');
                goToLetters();
            });
        }
        const newLetterProps = {
            theme: formLetter.theme,
            body: formLetter.body,
            category: formLetter.category.value,
            letterServiceCode: formLetter.serviceCode?.value,
            originalLetterId: formLetter.originalLetterId,
            attachmentIds: formLetter.attachments.map(item => item.attachment.id),
        };
        /** Создание письма */
        return letterApi
            .createLetter({ newLetterProps })
            .then(({ id }) => {
            goToLetter(id);
            successAlert('Письмо успешно создано');
        })
            .catch(() => {
            errorAlert('Произошла ошибка');
            goToLetters();
        });
    }, [errorAlert, goToLetter, goToLetters, letter, successAlert]);
    /** Мемоизированные методы API */
    const commonAPI = useMemo(() => ({
        onUploadFile,
        createSignData,
        onDownloadAttachment,
        onSubmit,
        onCreateDuplicate,
        onReply: letter?.direction === LetterDirections.Incoming ? onReply : undefined,
        onDeleteLetter,
        onSendLetter,
    }), [
        createSignData,
        letter?.direction,
        onCreateDuplicate,
        onDeleteLetter,
        onDownloadAttachment,
        onReply,
        onSendLetter,
        onSubmit,
    ]);
    /** API для существующего письма */
    const existingAPI = useMemo(() => ({
        ...commonAPI,
        onSignComplete: (withCertificateInfo, base64, signature, send) => {
            if (letter) {
                const promise = send
                    ? letterApi.saveAndSendSignedLetter({
                        signedLetterData: {
                            body: base64,
                            signature,
                            rawBody: withCertificateInfo,
                        },
                        letterId: letter.id,
                    })
                    : letterApi.saveSignedLetter({
                        letterId: letter.id,
                        signedLetterData: { body: base64, signature, rawBody: withCertificateInfo },
                    });
                promise
                    .then(({ id }) => {
                    successAlert('Письмо успешно подписано');
                    setLetter(null);
                    goToLetter(id);
                })
                    .catch((err) => {
                    errorAlert(extractErrorMessage(err));
                    goToLetters();
                });
            }
        },
    }), [commonAPI, errorAlert, goToLetter, goToLetters, letter, successAlert]);
    /** API для создания нового письма */
    const newLetterAPI = useMemo(() => ({
        ...commonAPI,
        onSignComplete: (withInfo, base64, signature, isSend, form) => {
            const { originalLetterId, body, category, theme, serviceCode, attachments } = form;
            /** Данные для создания письма */
            const newLetterProps = {
                originalLetterId,
                body,
                category: category.value,
                letterServiceCode: serviceCode?.value,
                theme,
                attachmentIds: attachments.map(i => i.attachment.id),
            };
            /** Подписанные данные */
            const signedLetterData = {
                body: base64,
                signature,
                rawBody: withInfo,
            };
            const promise = isSend
                ? letterApi.createAndSendSignedLetter({
                    signedLetterData,
                    newLetterProps,
                })
                : letterApi.createSignedLetter({
                    newLetterProps,
                    signedLetterData,
                });
            promise
                .then(({ id }) => {
                successAlert('Письмо успешно подписано');
                setLetter(null);
                goToLetter(id);
            })
                .catch(() => {
                errorAlert('Произошла ошибка');
                goToLetters();
            });
        },
    }), [commonAPI, errorAlert, goToLetter, goToLetters, successAlert]);
    /** Права доступа */
    const rightsAccess = useMemo(() => ({
        createAccess: haveAccess(PLettersMapper.Create),
        deleteAccess: haveAccess(PLettersMapper.Delete) &&
            (letter?.status === LetterStatuses.Signed || letter?.status === LetterStatuses.Draft),
        saveAndSendSignedAccess: haveAccess(PLettersMapper.SaveAndSendSigned),
        saveSignedAccess: haveAccess(PLettersMapper.SaveSigned),
        sendAccess: haveAccess(PLettersMapper.Send) &&
            (letter?.status === LetterStatuses.Signed ||
                (letter?.category === LetterCategories.Base && letter?.status === LetterStatuses.Draft)),
        addAttachmentAccess: haveAccess(PLetterAttachmentsMapper.AddAttachment),
        deleteAttachmentAccess: haveAccess(PLetterAttachmentsMapper.DeleteAttachment),
    }), [letter, haveAccess]);
    /** Фильтрация доступных сертификатов */
    const certificatePredicate = useCallback((cert) => userCertificateThumbprints.includes(cert.thumbprint), [userCertificateThumbprints]);
    return (React.createElement(React.Fragment, null,
        id && letter && !isCreatePath ? (React.createElement(LetterLayout, { isDraft: letter.status === LetterStatuses.Draft, isExistLetter: true, certificatePredicate: certificatePredicate, date: letter.createDate, initialValues: {
                category: CategorySelectOptionMapper[letter.category],
                theme: letter.theme,
                body: letter.body,
                serviceCode: optionServices.find(item => item.value === letter?.letterService?.code),
                attachments: letter.attachments ? mapAttachments(letter.attachments) : [],
            }, staticProps: pseudoStaticProps, api: existingAPI, statusText: StatusMapper[letter.status], rightsAccess: rightsAccess })) : (!isCreatePath && React.createElement(Preloader, null)),
        (duplicateOrReplied || letterDuplicate || repliedLetter) && isCreatePath && (React.createElement(LetterLayout, { isDraft: true, certificatePredicate: certificatePredicate, isExistLetter: false, initialValues: {
                category: letterDuplicate
                    ? CategorySelectOptionMapper[letterDuplicate.category]
                    : undefined,
                theme: letterDuplicate?.theme,
                body: letterDuplicate?.body,
                serviceCode: optionServices.find(item => item.value === letterDuplicate?.letterService?.code),
                originalLetterId: repliedLetter?.id,
            }, replied: {
                letterRepliedCreateDate: repliedLetter?.createDate,
                letterRepliedTheme: repliedLetter?.theme,
            }, staticProps: pseudoStaticProps, api: newLetterAPI, rightsAccess: rightsAccess })),
        isCreatePath && !duplicateOrReplied && (React.createElement(LetterLayout, { isDraft: true, certificatePredicate: certificatePredicate, isExistLetter: false, staticProps: pseudoStaticProps, api: newLetterAPI, rightsAccess: rightsAccess }))));
};
