import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import hideMenuIcon from '../../static/images/icons/hide-menu-dark.svg';
import iconLogo from '../../static/images/mini-logo-inkh.png';
import imageLogo from '../../static/images/logo-inkh.png';
import openMenuIcon from '../../static/images/icons/open-menu-dark.svg';
import searchDefault from '../../static/images/icons/search-default.svg';
import styles from './navigation.module.scss';
import { Tooltip } from '../tooltip';
import { CustomLink } from '../link';
import { useCustomNavigate } from '../../utils/hooks/custom-navigate';
/** Боковая панель-меню */
export const Navigation = ({ basePath, options }) => {
    const navigate = useCustomNavigate();
    const { pathname } = useLocation();
    /** Ссылка на поле поиска */
    const searchRef = useRef(null);
    /** Отображаемые секции */
    const [sections, setSections] = useState(options);
    const [searchValue, setSearchValue] = useState('');
    /** Состояние меню */
    const [opened, setOpened] = useState(true);
    /** Откртытие меню при поиске */
    const openMenuWhenSearching = useCallback(() => {
        setOpened(true);
        searchRef.current?.focus();
    }, []);
    /** открыть/закрыть меню */
    const toggleMenu = useCallback(() => setOpened(prev => {
        if (prev) {
            setSearchValue('');
            setSections(options);
        }
        return !prev;
    }), [options]);
    /** Переход на главную страницу */
    const onGoMain = useCallback(() => navigate(basePath), [navigate, basePath]);
    /** Поиск в меню */
    const onSearch = useCallback(({ target: { value } }) => {
        setSections(options.filter(item => item.label.toLowerCase().includes(value.toLowerCase())));
        setSearchValue(value);
    }, [options]);
    return (React.createElement("div", { className: cn(styles.navigation, {
            [styles.openMenu]: opened,
        }) },
        React.createElement("button", { className: cn(styles.hideMenuButton, {
                [styles.openMenu]: opened,
            }), type: 'button', onClick: toggleMenu },
            React.createElement("img", { src: opened ? hideMenuIcon : openMenuIcon, alt: '\u0441\u043A\u0440\u044B\u0442\u0438\u0435 \u043C\u0435\u043D\u044E' })),
        React.createElement("img", { onClick: onGoMain, className: cn(styles.logo, {
                [styles.logoIcon]: !opened,
            }), src: opened ? imageLogo : iconLogo, alt: '\u041B\u043E\u0433\u043E\u0442\u0438\u043F' }),
        React.createElement("nav", null,
            React.createElement("div", { className: styles.sections },
                React.createElement("div", { onClick: openMenuWhenSearching, className: opened ? styles.search : styles.searchIconMenu },
                    React.createElement("img", { className: styles.searchIcon, src: searchDefault, alt: '\u0438\u043A\u043E\u043D\u043A\u0430' }),
                    React.createElement("input", { ref: searchRef, value: searchValue, onChange: onSearch, placeholder: '\u041F\u043E\u0438\u0441\u043A \u0432 \u043C\u0435\u043D\u044E', className: styles.searchInput })),
                sections.length > 0 ? (sections.map(section => (React.createElement(Tooltip, { isVisible: !opened, key: section.label, tooltipText: section.label, right: true },
                    React.createElement(CustomLink, { to: section.route, className: cn(styles.link, {
                            [styles.linkActive]: (pathname.startsWith(`/${section.route}`) && section.route !== basePath) ||
                                (section.route === basePath && pathname === basePath),
                            [styles.openMenu]: opened,
                        }) },
                        React.createElement("img", { src: section.icon, alt: '\u0438\u043A\u043E\u043D\u043A\u0430' }),
                        opened && React.createElement("p", { className: styles.linkText }, section.label)))))) : (React.createElement("span", { className: styles.nonSearch }, "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E"))))));
};
