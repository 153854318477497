import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import cn from 'classnames';
import styles from './link.module.scss';
import { pathNameReplacer } from '../../routes';
/**
 * Ссылка, добавляюшая в state к location текуще местоположение
 * */
export const CustomLink = ({ to, className, ...other }) => {
    const location = useLocation();
    return (React.createElement(Link, { ...other, to: to, className: cn(className, { [styles.disable]: pathNameReplacer(location.pathname) === to }), state: location.pathname }));
};
