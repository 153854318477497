import React from 'react';
import cn from 'classnames';
import styles from './rect-button-ui.module.scss';
/**
 * Компонент кнопки
 * */
export const RectButtonUI = ({ styleType = 'green', children, type = 'button', className, ...rest }) => (React.createElement("button", { type: type, className: cn(styles.rectButton, className, {
        [styles.rectButtonGreen]: styleType === 'green',
        [styles.rectButtonLine]: styleType === 'line',
    }), ...rest }, children));
