import React, { Component } from 'react';
import { ErrorComponent } from '../error-component';
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        return hasError ? React.createElement(ErrorComponent, null) : children;
    }
}
