import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
/**
 * Используется для навигации также как и useNavigate,
 * при этом добавляет текущий pathname в state, для хранения в стеке истории о посещенных роутах
 * */
export const useCustomNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return useCallback((to) => {
        if (typeof to === 'number') {
            navigate(to);
        }
        else {
            navigate(to, { state: location.pathname });
        }
    }, [location, navigate]);
};
