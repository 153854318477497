import React, { useCallback, useState } from 'react';
import { InputUI, Popup, RectButtonUI } from '../../utils/general-code-import/components-import';
import { CheckboxUI } from '../../../submodules/inkh-core-frontend/src';
import styles from './styles.module.scss';
/** Диалог создания шаблона платежа */
export const CreateTemplatePopup = ({ open, onCancel: onCancelExternal, onOk: onOkExternal, }) => {
    const [name, setName] = useState('');
    const [isCheck, setCheck] = useState(false);
    const onChange = useCallback(({ target: { value } }) => setName(value), []);
    /** Отмена */
    const onCancel = useCallback(() => {
        onCancelExternal();
        setName('');
    }, [onCancelExternal]);
    /** Выбор общего или приватного шаблона */
    const onChecked = useCallback(() => {
        setCheck(prevState => !prevState);
    }, []);
    /** Подтверждение */
    const onOk = useCallback(() => {
        onOkExternal(name, isCheck);
        setName('');
        setCheck(false);
    }, [isCheck, name, onOkExternal]);
    return (React.createElement(Popup, { header: '\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u0448\u0430\u0431\u043B\u043E\u043D\u0430 \u043F\u043B\u0430\u0442\u0435\u0436\u0430', footer: React.createElement(React.Fragment, null,
            React.createElement(RectButtonUI, { type: 'button', onClick: onOk }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
            React.createElement(RectButtonUI, { type: 'button', styleType: 'line', onClick: onCancel }, "\u041E\u0442\u043C\u0435\u043D\u0430")), open: open, onClose: onCancel },
        React.createElement("div", null,
            React.createElement(InputUI, { isRequired: true, title: '\u043D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435', name: 'name', value: name, onChange: onChange }),
            React.createElement("div", { className: styles.checkContainer },
                React.createElement("span", null, "\u041E\u0431\u0449\u0438\u0439:"),
                React.createElement(CheckboxUI, { checked: isCheck, onChange: onChecked })))));
};
