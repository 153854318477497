import React, { createContext, useContext, useState } from 'react';
import { Alert } from '../alert';
/** Ненастроенный контекст уведомлений */
const AlertContextRaw = createContext({});
/**
 * Контекст для показа уведомлений
 * */
export const AlertContext = ({ children }) => {
    /** Триггеры для сообщений, устанавливаются из дочернего компонента Alert */
    const [contextValue, setContextValue] = useState();
    return (React.createElement(React.Fragment, null,
        contextValue && (React.createElement(AlertContextRaw.Provider, { value: contextValue }, children)),
        React.createElement(Alert, { setContextValue: setContextValue })));
};
/** Использование контеста уведомлений */
export const useAlert = () => useContext(AlertContextRaw);
