import React from 'react';
import cn from 'classnames';
import styles from './table-styles.module.scss';
import stylesMain from '../../utils/styles/main.module.scss';
/** Контейнер под таблицу */
export const TableContainer = ({ children, className, ...other }) => (React.createElement("div", { className: cn(styles.tableContainer, className), ...other }, children));
/** Таблица */
export const Table = ({ children, className, ...other }) => (React.createElement("table", { className: cn(styles.table, className), ...other }, children));
/** Тело таблицы */
export const TableBody = ({ children, className, ...other }) => (React.createElement("tbody", { className: cn(styles.tableBody, className), ...other }, children));
/** Строка таблицы */
export const TableRow = ({ children, onClick, tableRowTransition = false, className, ...other }) => (React.createElement("tr", { onClick: onClick, className: cn(styles.tableRow, className, {
        [styles.tableRowTransition]: tableRowTransition,
    }), ...other }, children));
/** Ячейка таблицы */
export const TableCell = ({ children, className, ...other }) => (React.createElement("td", { className: cn(styles.tableCell, className), ...other }, children));
/** Шапка таблицы */
export const TableHead = ({ children, className, ...other }) => (React.createElement("thead", { className: cn(styles.tableHead, className), ...other }, children));
/** Текстовая ячейка таблицы */
export function ParagraphCell(item) {
    return function Cell(getContent) {
        return React.createElement("p", { className: stylesMain.tableTextHead }, getContent(item));
    };
}
/** Ячейка с кнопкой */
export function ButtonCell(props) {
    return React.createElement("button", { type: 'button', ...props });
}
