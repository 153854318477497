import { apm } from '@elastic/apm-rum';
/** Имя сервиса будет подставлено после сборки */
const SERVICE_NAME = '___APM_SERVICE_NAME_RENAME_PLEASE___';
/** URL по которому обращаться в режиме дебага */
const DEBUG_SERVER_URL = 'http://inkh-lk-tst-web.mcb.ru:18200';
/** Инициализация apm */
export const iniAPM = (debugServiceName) => {
    apm.init({
        active: IS_APM_ENABLED,
        serverUrl: IS_DEBUG ? DEBUG_SERVER_URL : '/apm',
        serviceName: IS_DEBUG ? debugServiceName : SERVICE_NAME,
    });
};
