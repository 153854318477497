import React, { useCallback } from 'react';
import styles from './confirm-delete-popup.module.scss';
import { RectButtonUI } from '../rect-button-ui';
import { Popup } from '../popup';
/** Диалог подтверждения удаления */
export const ConfirmDeletePopup = ({ onClose, onSubmit, title, isUnlink, ...rest }) => {
    const handleDelete = useCallback(() => {
        onSubmit();
        if (onClose)
            onClose();
    }, [onClose, onSubmit]);
    return (React.createElement(Popup, { onClose: onClose, header: React.createElement(React.Fragment, null,
            "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 ",
            isUnlink ? 'отвязать' : 'удалить',
            ' ',
            React.createElement("span", { className: styles.titleText }, title),
            "?"), footer: React.createElement(React.Fragment, null,
            React.createElement(RectButtonUI, { "data-testid": 'confirm', styleType: 'green', onClick: handleDelete }, isUnlink ? 'Отвязать' : 'Удалить'),
            React.createElement(RectButtonUI, { "data-testid": 'cancel', styleType: 'line', onClick: onClose }, "\u041E\u0442\u043C\u0435\u043D\u0430")), ...rest }));
};
