import React, { useCallback, useState } from 'react';
import styles from './input-ui.module.scss';
import { WrapContainerUI } from '../wrap-container-ui';
import eyeOffIcon from '../../static/images/icons/EyeOff-dark.svg';
import eyeOnIcon from '../../static/images/icons/EyeOn-dark.svg';
export const InputUI = React.forwardRef(({ title, type = 'text', disabled, autoComplete = 'off', isRequired, hasError, errorText, isPasswordInput, ...rest }, ref) => {
    const [inputType, setInputType] = useState(type);
    const handleVisiblePassword = useCallback(() => {
        setInputType(prev => {
            if (prev === 'text')
                return 'password';
            return 'text';
        });
    }, []);
    return (React.createElement(WrapContainerUI, { hasError: hasError, errorText: errorText, isRequired: isRequired, title: title, disabled: disabled },
        React.createElement("div", { className: styles.inputContainer },
            React.createElement("input", { autoComplete: autoComplete, ref: ref, disabled: disabled, className: styles.inputTheme, type: inputType, ...rest }),
            isPasswordInput && (React.createElement("div", { className: styles.iconContainer },
                React.createElement("img", { onClick: handleVisiblePassword, className: styles.iconImage, src: inputType === 'password' ? eyeOnIcon : eyeOffIcon, alt: '\u043F\u043E\u043A\u0430\u0437 \u043F\u0430\u0440\u043E\u043B\u044F' }))))));
});
InputUI.displayName = 'InputUI';
