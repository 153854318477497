import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { ConfirmDeletePopup, Preloader, useReload, } from '../../../submodules/inkh-core-frontend/src';
import { paymentTemplateApi } from '../../utils/api/generalApi';
import { Popup, RectButtonUI } from '../../utils/general-code-import/components-import';
import { useAlert } from '../../utils/general-code-import/config-import';
import { stylesMain } from '../../utils/general-code-import/styles-import';
import styles from './styles.module.scss';
/** Если поле не указано */
const NOT_PROVIDED = 'не указано';
/** Карточка элемента */
export const ItemCard = ({ item: { recipientName, amount, templateName, recipientAccountNumber, shared }, }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: styles.name }, templateName ?? NOT_PROVIDED),
    React.createElement("div", { className: styles.field },
        React.createElement("div", null, "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C"),
        React.createElement("div", { title: recipientName }, recipientName ?? NOT_PROVIDED)),
    React.createElement("div", { className: styles.field },
        React.createElement("div", null, "\u0421\u0447\u0435\u0442 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F"),
        React.createElement("div", { title: recipientName }, recipientAccountNumber ?? NOT_PROVIDED)),
    React.createElement("div", { className: styles.field },
        React.createElement("div", null, "\u0421\u0443\u043C\u043C\u0430"),
        React.createElement("div", { title: amount.toString() }, amount ?? NOT_PROVIDED))));
/** Диалог выбора шаблона */
export const SelectTemplatePopup = ({ onCancel: onCancelExternal, open, onSelectTemplate: onSelectTemplateExternal, }) => {
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    const { errorAlert, successAlert } = useAlert();
    /** Загрузка шаблонов */
    const [loadItems, reload] = useReload(() => paymentTemplateApi.getTemplates(), []);
    /** Элементы */
    const [items, setItems] = useState();
    /** Отфильтрованная выборка */
    const [filteredItems, setFilteredItems] = useState();
    /** Выбранный шаблон */
    const [template, setTemplate] = useState(null);
    /** Шаблон на удаление */
    const [deletable, setDeletable] = useState(null);
    /** отмена */
    const onCancel = useCallback(() => {
        setTemplate(null);
        onCancelExternal();
    }, [onCancelExternal]);
    /** Загрузка элементов */
    useEffect(() => {
        if (open) {
            loadItems()
                .then(items => {
                setItems(items);
                setFilteredItems(items);
            })
                .catch(() => {
                errorAlert('Ошибка во время загрузки');
                onCancel();
            });
        }
    }, [errorAlert, loadItems, onCancel, open]);
    /** Подтверждение выбора шблона */
    const onSelectTemplate = useCallback(() => {
        setTemplate(null);
        onCancel();
        if (template)
            onSelectTemplateExternal(template);
    }, [onCancel, onSelectTemplateExternal, template]);
    /** Выбор шблона */
    const onItemClick = (item) => () => setTemplate(prev => (prev === item ? null : item));
    /** Текст поиска */
    const [search, setSearch] = useState('');
    /** Поиск */
    const onSearch = useCallback(({ target: { value } }) => {
        setSearch(value);
        setFilteredItems((items ?? []).filter(item => `${item.templateName ?? ''}${item.recipientAccountNumber ?? ''}${item.recipientName ?? ''}`.includes(value)));
    }, [items]);
    const onBeginDelete = useCallback((item) => (e) => {
        e.stopPropagation();
        setDeletable(item);
    }, []);
    /** Удаление шаблона */
    const onConfirmDelete = useCallback(() => {
        if (deletable) {
            setLoading(true);
            paymentTemplateApi
                .delete({
                templateId: deletable.id,
            })
                .then(() => {
                successAlert(`Шаблон ${deletable.templateName ?? ''} успешно удален`);
                reload();
            })
                .catch(() => errorAlert('Ошибка при удалении'))
                .finally(() => setLoading(false));
        }
        setDeletable(null);
    }, [deletable, errorAlert, reload, successAlert]);
    /** Отмена удаления */
    const onCancelDelete = useCallback(() => setDeletable(null), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Popup, { closeOnDocumentClick: !deletable, closeOnEscape: !deletable, header: '\u0412\u044B\u0431\u043E\u0440 \u0448\u0430\u0431\u043B\u043E\u043D\u0430', footer: React.createElement(React.Fragment, null,
                React.createElement(RectButtonUI, { disabled: !template, type: 'button', onClick: onSelectTemplate }, "\u0412\u044B\u0431\u0440\u0430\u0442\u044C"),
                React.createElement(RectButtonUI, { type: 'button', onClick: onCancel, styleType: 'line' }, "\u041E\u0442\u043C\u0435\u043D\u0430")), open: open, onClose: onCancel },
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("input", { placeholder: '\u041F\u043E\u0438\u0441\u043A', className: cn(styles.searchInput, { [styles.searchInputNonEmpty]: !!search }), onChange: onSearch, type: 'text' })),
            React.createElement("div", { className: styles.container }, !filteredItems || loading ? (React.createElement(Preloader, null)) : (filteredItems.map(item => (React.createElement("div", { onClick: onItemClick(item), className: cn(styles.item, { [styles.selected]: template === item }), key: item.id },
                React.createElement("span", { onClick: onBeginDelete(item), className: cn(styles.delete, stylesMain.deleteButton) }),
                item.shared && React.createElement("span", { className: styles.sharedText }, "\u041E\u0431\u0449\u0438\u0439"),
                React.createElement(ItemCard, { item: item }))))))),
        React.createElement(ConfirmDeletePopup, { onClose: onCancelDelete, open: !!deletable, title: deletable ? deletable.templateName ?? '' : '', onSubmit: onConfirmDelete })));
};
