import { format } from 'date-fns';
export const toFormatMoney = (n) => parseFloat(String(n))
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
    .replace(/\./g, ',');
export const getSum = (numbers) => {
    let sum = 0;
    for (const number of numbers) {
        sum += number;
    }
    return sum;
};
/**
 * Date string interpolation using format string.
 * @param date - date to formatting
 * @param dateFormat - format string
 * */
export const formatDate = (date, dateFormat = 'dd.MM.yyyy') => {
    if (!date)
        return format(new Date(), dateFormat);
    const typedDate = date instanceof Date ? date : new Date(date);
    return format(typedDate, dateFormat);
};
/** Извлечение текста ошибки из массива ErrorImportFile */
export const extractErrorImportFileMessage = (error, defaultMessage = 'Произошла ошибка') => {
    const withResponse = error;
    if (withResponse?.response &&
        Array.isArray(withResponse.response) &&
        withResponse.response.length) {
        return withResponse.response
            .map((item) => `${item.FieldName}: ${item.Errors.length
            ? item.Errors.reduce((accumulator, error) => `${accumulator} ${error.ErrorMessage}. `, 'Ошибки: ')
            : 'Нет информации по ошибке. '}`)
            .join('');
    }
    return defaultMessage;
};
/** Извлечение текста ошибки из объекта Error */
export const extractErrorMessage = (error, defaultMessage = 'Произошла ошибка') => {
    if (error instanceof Error && error.message)
        return error.message;
    return defaultMessage;
};
/** Получение значения value из select */
export const getValueSelect = (value) => value.value;
/** параметры */
const localeOptions = {
    month: 'long',
    day: 'numeric',
};
/** Форматирование диапазона дат */
export const formatRangeDate = (start, end) => {
    let startingDate;
    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    const endMonth = end.getMonth();
    const endYear = end.getFullYear();
    const isRangeInsideMonth = startMonth === endMonth;
    const isRangeInsideYear = startYear === endYear;
    if (isRangeInsideMonth && isRangeInsideYear) {
        startingDate = start.toLocaleString('ru', localeOptions);
    }
    else if (isRangeInsideYear) {
        startingDate = start.toLocaleString('ru', localeOptions);
    }
    else {
        startingDate = `${start.toLocaleString('ru', localeOptions)} ${startYear}`;
    }
    const endingDate = `${end.toLocaleString('ru', localeOptions)} ${endYear}`;
    const proposal = start.getDate() === 2 ? 'Со' : 'С';
    let template = `${proposal} ${startingDate} по ${endingDate}`;
    if (+start === +end) {
        template = start.toLocaleString('ru', localeOptions);
    }
    return template;
};
/** получение значения колличества символов */
const getCount = (str, reg) => (str.match(reg) || []).length;
/** обработчик валидации пароля */
export const validatePassword = (value, params) => {
    /** текст ошибок */
    let uppercaseError = '';
    let lowercaseError = '';
    let specialCharacterError = '';
    let digitError = '';
    let lengthError = '';
    if (params) {
        /** Проверка на наличие заглавных букв */
        const reUppercase = /[A-ZА-ЯЁ]/g;
        /** Проверка на наличие строчных букв */
        const reLowercase = /[a-zа-яё]/g;
        /** экранизация спецсимволов */
        let screenAdaptationSpecialCharacters = '';
        if (params.allowedSpecialCharacters) {
            for (let i = 0; i < params.allowedSpecialCharacters.length; i++) {
                screenAdaptationSpecialCharacters += `\\${params.allowedSpecialCharacters[i]}`;
            }
        }
        /** Проверка на наличие разрешенных спецсимволов */
        const reAllowedSpecialCharacters = new RegExp(`[${screenAdaptationSpecialCharacters}]`, 'g');
        /** Проверка на наличие запрещенных спецсимволов */
        const reForbiddenSpecialCharacters = new RegExp(`[^${screenAdaptationSpecialCharacters}0-9A-ZА-ЯЁa-zа-яё]`, 'g');
        /** Проверка на наличие цифр */
        const reDigit = /[0-9]/g;
        if (getCount(value, reUppercase) < params.uppercaseCount) {
            uppercaseError = ` прописных букв: ${params.uppercaseCount};`;
        }
        if (getCount(value, reLowercase) < params.lowercaseCount) {
            lowercaseError = ` строчных букв: ${params.lowercaseCount};`;
        }
        if (params.allowedSpecialCharacters &&
            (getCount(value, reAllowedSpecialCharacters) < params.specialCharacterCount ||
                getCount(value, reForbiddenSpecialCharacters) > 0)) {
            specialCharacterError = ` спецсимволов: ${params.specialCharacterCount} (допустимый набор спецсимволов: ${params.allowedSpecialCharacters});`;
        }
        if (getCount(value, reDigit) < params.digitCount) {
            digitError = ` цифр: ${params.digitCount};`;
        }
        if (value.length < params.length) {
            lengthError = ` длина пароля не менее ${params.length} символов;`;
        }
    }
    /** флаг наличия ошибок валидации */
    const isNotErrorActive = uppercaseError.length === 0 &&
        lowercaseError.length === 0 &&
        specialCharacterError.length === 0 &&
        digitError.length === 0 &&
        lengthError.length === 0;
    /** проверка на наличие ошибки валидации */
    if (isNotErrorActive)
        return isNotErrorActive;
    const errorText = `Пароль должен содержать как минимум:${uppercaseError}${lowercaseError}${specialCharacterError}${digitError}${lengthError}`;
    return errorText.slice(0, -1);
};
/** Функция по получению и присваиванию нового значения */
export function createNullableStore() {
    let value = null;
    return {
        get: () => value,
        set: (newValue) => {
            value = newValue;
        },
    };
}
