import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { AccountsStatuses } from '../../../submodules/inkh-principal/src/Inkh.Principal.Api.Client.TypeScript/Models/AccountsStatuses.Generated';
import { BASE_PATH, CREATE_PATH, PAYMENTS } from '../../routes';
import { registerCurrentPath } from '../../routes/routes-mapper';
import { getDuplicatePayment, setDuplicatePayment } from '../../store/slice/appSlice';
import { useAppDispatch } from '../../store/store';
import { OrderStatuses, } from '../../utils/api/apiModels';
import { banksApi, orderApi, principalOrganizationClient } from '../../utils/api/generalApi';
import { Preloader } from '../../utils/general-code-import/components-import';
import { extractErrorMessage, formatDate, joinRoutePaths, useAlert, useCustomNavigate, } from '../../utils/general-code-import/config-import';
import { cleanEmpty } from '../../utils/variables';
import { PaymentForm } from '../payment-form';
/**
 * Платеж
 * Просмотр и создание платежки
 * */
export const Payment = () => {
    const navigate = useCustomNavigate();
    const dispatch = useAppDispatch();
    /** Id платежки из url */
    const { id } = useParams();
    /** Дубликат платежки */
    const duplicate = useSelector(getDuplicatePayment);
    const { errorAlert, successAlert } = useAlert();
    /** Режим создания */
    const isCreatePath = useLocation().pathname.endsWith(CREATE_PATH);
    /** Состояние загрузки */
    const [loading, setLoading] = useState(true);
    /** Тело платежки */
    const [payment, setPayment] = useState(null);
    /** Дубликат платежки */
    const [paymentDuplicate, setPaymentDuplicate] = useState(null);
    /** Счета */
    const [accounts, setAccounts] = useState([]);
    /** Банки */
    const [banks, setBanks] = useState([]);
    /** Переход к платежу */
    const goToPayment = useCallback((id) => navigate(BASE_PATH + joinRoutePaths(PAYMENTS, id)), [navigate]);
    /** Перейти к платежам */
    const goToPayments = useCallback(() => navigate(BASE_PATH + PAYMENTS), [navigate]);
    /** Загрузка  платежа */
    useEffect(() => {
        const paymentPromise = id
            ? orderApi.getOrder(id)
            : Promise.resolve(null);
        Promise.all([
            paymentPromise,
            banksApi.getAllBanks(),
            principalOrganizationClient.getAccounts(AccountsStatuses.OnlyAntive),
        ])
            .then(([paymentItem, banks, accounts]) => {
            setAccounts(accounts);
            setBanks(banks);
            if (paymentItem) {
                registerCurrentPath(`Платеж № ${paymentItem.number} от ${formatDate(paymentItem.date)}`);
                setPayment(paymentItem);
            }
        })
            .catch(() => {
            errorAlert('Произошла ошибка');
            goToPayments();
        })
            .finally(() => setLoading(false));
    }, [errorAlert, goToPayments, id]);
    /** Добавление дубликата платежки в стейт */
    useEffect(() => {
        if (duplicate) {
            setPaymentDuplicate(duplicate);
            setPayment(null);
            dispatch(setDuplicatePayment(null));
        }
    }, [dispatch, duplicate]);
    /** Обработчик создания дубликата платежки */
    const createDuplicatePayment = useCallback((payment) => () => {
        if (payment) {
            dispatch(setDuplicatePayment(payment));
            navigate(BASE_PATH + PAYMENTS + BASE_PATH + CREATE_PATH);
            successAlert('Дубликат платежа успешно создан');
        }
    }, [dispatch, navigate, successAlert]);
    /** Обработчик удаления платежки */
    const onDeletePayment = useCallback(() => {
        if (payment) {
            setLoading(true);
            orderApi
                .delete({ orderId: payment.id })
                .then(() => {
                successAlert('Платеж успешно удален');
                goToPayments();
            })
                .catch(() => errorAlert('Произошла ошибка'))
                .finally(() => setLoading(false));
        }
    }, [errorAlert, goToPayments, payment, successAlert]);
    /** Обработчик отправления подписанного платежа */
    const onSendPayment = useCallback(() => {
        if (payment && payment.id) {
            setLoading(true);
            orderApi
                .sendSignedOrder({
                orderId: payment.id,
            })
                .then(() => {
                successAlert('Платеж успешно отправлен');
                goToPayments();
            })
                .catch((err) => errorAlert(extractErrorMessage(err)))
                .finally(() => setLoading(false));
        }
    }, [errorAlert, goToPayments, payment, successAlert]);
    /** Метод подписания существующего платежа */
    const signPayment = useCallback((withCertificateInfo, base64, signature, paymentId) => orderApi
        .saveSignedOrder({
        orderId: paymentId,
        signedOrderData: {
            body: base64,
            signature,
            rawBody: withCertificateInfo,
        },
    })
        .then(({ id }) => {
        setPayment(null);
        goToPayment(id);
        successAlert('Платеж успешно подписан');
    })
        .catch((err) => errorAlert(extractErrorMessage(err))), [errorAlert, goToPayment, successAlert]);
    /** Метод подписания и отправки существующего платежа */
    const signAndSendPayment = useCallback((withCertificateInfo, base64, signature, paymentId) => orderApi
        .saveAndSendSignedOrder({
        orderId: paymentId,
        signedOrderData: {
            body: base64,
            signature,
            rawBody: withCertificateInfo,
        },
    })
        .then(({ id }) => {
        setPayment(null);
        goToPayment(id);
        successAlert('Платеж успешно подписан');
    })
        .catch((err) => errorAlert(extractErrorMessage(err))), [errorAlert, goToPayment, successAlert]);
    /** Метод подписания нового платежа */
    const creatSignPayment = useCallback((withCertificateInfo, base64, signature, formPayment) => {
        const newPayment = { ...formPayment };
        return orderApi
            .createSignedOrder({
            paymentOrderProps: newPayment,
            signedOrderData: {
                body: base64,
                signature,
                rawBody: withCertificateInfo,
            },
        })
            .then(({ id }) => {
            setPayment(null);
            goToPayment(id);
        })
            .catch((err) => errorAlert(extractErrorMessage(err)));
    }, [errorAlert, goToPayment]);
    /** Метод подписания и отправки нового платежа */
    const createSignAndSendPayment = useCallback((withCertificateInfo, base64, signature, formPayment) => {
        const newPayment = { ...formPayment };
        return orderApi
            .createAndSendSignedOrder({
            paymentOrderProps: newPayment,
            signedOrderData: {
                body: base64,
                signature,
                rawBody: withCertificateInfo,
            },
        })
            .then(({ id }) => {
            setPayment(null);
            goToPayment(id);
        })
            .catch((err) => errorAlert(extractErrorMessage(err)));
    }, [errorAlert, goToPayment]);
    /** Обработчик события формы */
    const onSubmit = useCallback((formPayment) => {
        delete formPayment.payerClient;
        const newPayment = {
            ...formPayment,
        };
        cleanEmpty(newPayment);
        const promise = payment
            ? orderApi
                .update({
                orderId: payment.id,
                paymentOrderProps: newPayment,
            })
                .then(() => {
                setPayment(null);
                navigate(BASE_PATH + PAYMENTS + BASE_PATH + payment.id);
                successAlert('Платеж успешно обновлен');
            })
            : orderApi.createOrder({ paymentOrderProps: newPayment }).then(({ id }) => {
                navigate(BASE_PATH + PAYMENTS + BASE_PATH + id);
                successAlert('Платеж успешно создан');
            });
        return promise.catch(() => errorAlert('Произошла ошибка'));
    }, [errorAlert, navigate, payment, successAlert]);
    /** Мемоизированные методы API */
    const commonAPI = useMemo(() => ({
        onSubmit,
        createDuplicatePayment,
        onDeletePayment,
        onSendPayment,
        signPayment,
        signAndSendPayment,
        creatSignPayment,
        createSignAndSendPayment,
    }), [
        onSubmit,
        createDuplicatePayment,
        onDeletePayment,
        onSendPayment,
        signPayment,
        signAndSendPayment,
        creatSignPayment,
        createSignAndSendPayment,
    ]);
    return loading ? (React.createElement(Preloader, null)) : (React.createElement(React.Fragment, null,
        id && payment && !isCreatePath && (React.createElement(PaymentForm, { accounts: accounts, banks: banks, payment: payment, isDraft: payment?.status === OrderStatuses.New, initialValues: payment, api: commonAPI })),
        paymentDuplicate && isCreatePath && (React.createElement(PaymentForm, { isDuplicate: true, accounts: accounts, banks: banks, isDraft: true, initialValues: paymentDuplicate, api: commonAPI })),
        isCreatePath && !paymentDuplicate && (React.createElement(PaymentForm, { accounts: accounts, banks: banks, api: commonAPI, isDraft: true }))));
};
