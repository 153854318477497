import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { BASE_PATH, CREATE_PATH, ID_TEMPLATE_PATH, LETTERS, PAYMENTS, PROFILE, STATEMENTS, } from '../../routes';
import { Profile } from '../../utils/general-code-import/components-import';
import { useAuthContext } from '../../utils/general-code-import/config-import';
import { Accounts } from '../accounts';
import { LetterForm } from '../letter-form';
import { Layout } from '../layout';
import { LettersList } from '../letters-list';
import { PaymentsList } from '../payments-list';
import { Statements } from '../statements';
import { Payment } from '../payment';
import { PStatementsMapper, POrganizationsMapper, PPayDocumentsMapper, PLettersMapper, } from '../../utils/general-code-import/types-import';
export const App = () => {
    const { haveAccess } = useAuthContext();
    /** Вычисление доступа к счетам */
    const organisationAccess = haveAccess(POrganizationsMapper.ViewAll);
    /** Вычисление доступа к выпискам */
    const statementAccess = haveAccess(PStatementsMapper.RequestStatement);
    /** Вычисление доступа к платежам */
    const paymentsAccess = haveAccess(PPayDocumentsMapper.ViewList);
    /** Вычисление доступа к письмам */
    const lettersAccess = haveAccess(PLettersMapper.Viewlist);
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: BASE_PATH, element: React.createElement(Layout, null) },
            organisationAccess && React.createElement(Route, { index: true, element: React.createElement(Accounts, null) }),
            lettersAccess && (React.createElement(Route, { path: LETTERS, element: React.createElement(Outlet, null) },
                React.createElement(Route, { index: true, element: React.createElement(LettersList, null) }),
                React.createElement(Route, { path: CREATE_PATH, element: React.createElement(LetterForm, null) }),
                React.createElement(Route, { path: ID_TEMPLATE_PATH, element: React.createElement(LetterForm, null) }))),
            statementAccess && React.createElement(Route, { path: STATEMENTS, element: React.createElement(Statements, null) }),
            paymentsAccess && (React.createElement(Route, { path: PAYMENTS, element: React.createElement(Outlet, null) },
                React.createElement(Route, { index: true, element: React.createElement(PaymentsList, null) }),
                React.createElement(Route, { path: CREATE_PATH, element: React.createElement(Payment, null) }),
                React.createElement(Route, { path: ID_TEMPLATE_PATH, element: React.createElement(Payment, null) }))),
            React.createElement(Route, { path: PROFILE, element: React.createElement(Profile, null) }),
            !organisationAccess && React.createElement(Route, { path: BASE_PATH, element: React.createElement(Navigate, { to: LETTERS }) }),
            React.createElement(Route, { path: '*', element: React.createElement(Navigate, { to: organisationAccess ? BASE_PATH : LETTERS, replace: true }) }))));
};
