export var PLetters;
(function (PLetters) {
    PLetters["CreateAndSendSigned"] = "CreateAndSendSigned";
    PLetters["Create"] = "Create";
    PLetters["CreateAndSign"] = "CreateAndSign";
    PLetters["Delete"] = "Delete";
    PLetters["SrvDictionaryLettersTypes"] = "SrvDictionaryLettersTypes";
    PLetters["View"] = "View";
    PLetters["Viewlist"] = "Viewlist";
    PLetters["ViewStatusHistory"] = "ViewStatusHistory";
    PLetters["SrvLinkAttachment"] = "SrvLinkAttachment";
    PLetters["SaveAndSendSigned"] = "SaveAndSendSigned";
    PLetters["Update"] = "Update";
    PLetters["SaveSigned"] = "SaveSigned";
    PLetters["Send"] = "Send";
})(PLetters || (PLetters = {}));
/** Словарь, описывающий все значния из енума с правами */
export const PLettersMapper = {
    [PLetters.CreateAndSendSigned]: { name: 'letters_pletters_createandsendsigned', description: 'Создать, отправить' },
    [PLetters.Create]: { name: 'letters_pletters_create', description: 'Создать' },
    [PLetters.CreateAndSign]: { name: 'letters_pletters_createandsign', description: 'Создать, подписать' },
    [PLetters.Delete]: { name: 'letters_pletters_delete', description: 'Удалить' },
    [PLetters.SrvDictionaryLettersTypes]: { name: 'letters_pletters_srvdictionaryletterstypes', description: 'SRV типы писем' },
    [PLetters.View]: { name: 'letters_pletters_view', description: 'Просмотр' },
    [PLetters.Viewlist]: { name: 'letters_pletters_viewlist', description: 'Просмотр списка' },
    [PLetters.ViewStatusHistory]: { name: 'letters_pletters_viewstatushistory', description: 'История статусов' },
    [PLetters.SrvLinkAttachment]: { name: 'letters_pletters_srvlinkattachment', description: 'SRV связать с вложением' },
    [PLetters.SaveAndSendSigned]: { name: 'letters_pletters_saveandsendsigned', description: 'Сохранить, отправить' },
    [PLetters.Update]: { name: 'letters_pletters_update', description: 'Обновить' },
    [PLetters.SaveSigned]: { name: 'letters_pletters_savesigned', description: 'Подписать' },
    [PLetters.Send]: { name: 'letters_pletters_send', description: 'Отправить' }
};
