import React from 'react';
import ReactPopup from 'reactjs-popup';
import styles from './popup.module.scss';
/** Обобщенная форма диалога */
export const Popup = ({ onClose, children, footer, header, renderCloseIcon, ...rest }) => (React.createElement(ReactPopup, { lockScroll: true, ...rest, onClose: onClose },
    renderCloseIcon && (React.createElement("button", { "data-testid": 'close-icon', onClick: onClose, className: styles.buttonClose, type: 'button' }, "\u00D7")),
    React.createElement("div", { className: styles.container },
        header && React.createElement("h2", { className: styles.header }, header),
        React.createElement("div", { className: styles.content }, children),
        footer && React.createElement("div", { className: styles.footer }, footer))));
