import cn from 'classnames';
import * as fns from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { usePaginationRef } from '../../../submodules/inkh-core-frontend/src';
import { AccountsStatuses } from '../../../submodules/inkh-principal/src/Inkh.Principal.Api.Client.TypeScript/Models/AccountsStatuses.Generated';
import { principalOrganizationClient, statementApi } from '../../utils/api/generalApi';
import { DateInputRange, DropdownButton, GenericTable, PageTitle, RectButtonUI, SelectUI, SmallPreloader, Tooltip, } from '../../utils/general-code-import/components-import';
import { formatDate, toFormatMoney, useAlert, useAuthContext, useDownload, useReload, } from '../../utils/general-code-import/config-import';
import { cIcon, documentsIcon, pdfIcon, xlsIcon, } from '../../utils/general-code-import/images-import';
import { stylesMain, tableStyles } from '../../utils/general-code-import/styles-import';
import { PStatementsMapper, } from '../../utils/general-code-import/types-import';
import { ExportPdfCell } from '../payments-list';
import styles from './statements.module.scss';
import { DowmloadFormat } from './types';
const DocumentCell = ({ documentNumber, documentDate }) => (React.createElement("div", { className: styles.headContainer },
    React.createElement("p", { className: cn(stylesMain.tableTextHead, styles.marginHead) }, formatDate(documentDate)),
    `№ ${documentNumber || ''}`));
const BikCell = ({ payeeBankBic, payerBankBic }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextBody }, payerBankBic),
    React.createElement("div", { className: stylesMain.tableTextBody },
        React.createElement("div", { className: styles.sendIcon }),
        payeeBankBic)));
const PayerCell = ({ payerName, payerTin, payerBankAccountNumber }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, payerName ?? ''),
    React.createElement("p", { className: stylesMain.tableTextBody }, payerBankAccountNumber),
    React.createElement("p", { className: stylesMain.tableTextBody }, `ИНН ${payerTin ?? ''}`)));
const PayeeCell = ({ payeeName, payeeTin, payeeBankAccountNumber }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, payeeName),
    React.createElement("p", { className: stylesMain.tableTextBody }, payeeBankAccountNumber),
    React.createElement("p", { className: stylesMain.tableTextBody }, `ИНН ${payeeTin ?? ''}`)));
const CreditCell = ({ creditSum }) => (React.createElement("p", { className: cn(styles.statementBody, styles.statementBodyCredit) },
    toFormatMoney(creditSum),
    React.createElement("span", { className: stylesMain.tableTextBody }, " \u20BD")));
const DebitCell = ({ debitSum }) => (React.createElement("p", { className: cn(styles.statementBody, styles.statementBodyDebit) },
    toFormatMoney(debitSum),
    React.createElement("span", { className: stylesMain.tableTextBody }, " \u20BD")));
const CommentCell = ({ comments }) => (React.createElement("p", { className: stylesMain.tableTextBodyWrap }, comments));
const getItemKey = (item) => item.id;
const pagination = {
    nameElement: {
        one: 'записи',
        few: 'записи',
        many: 'записей',
    },
};
/** Начальная дата */
const InitialDateRange = [
    fns.add(fns.set(new Date(), { hours: 0, minutes: 0, seconds: 0 }), { days: -1 }),
    fns.set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
];
export const Statements = () => {
    const { errorAlert } = useAlert();
    const [statement, setStatement] = useState(null);
    /** обновленный uuid */
    const [uuidV4, setUuidV4] = useState(v4());
    const [selectedCheck, setSelectedCheck] = useState(null);
    /** Состояние скачивания */
    const [downloadLoading, setDownloadLoading] = useState(false);
    /** Формат скачивания */
    const [downloadFormat, setDownloadFormat] = useState(DowmloadFormat.DRAFT);
    /** Состояние загрузки */
    const [isLoading, setIsLoading] = useState(true);
    const [initial, setInitial] = useState(true);
    const [accounts, setAccounts] = useState([]);
    /** Выбранные элементы */
    const [checked, setChecked] = useState([]);
    /** Выбранный диапазон дат */
    const [dateValue, setDateValue] = useState(InitialDateRange);
    /** ссылка на функцию управления пагинацией */
    const paginationRef = usePaginationRef();
    /** Функция загрузки */
    const download = useDownload();
    /** Функция обработчик доступа */
    const { haveAccess } = useAuthContext();
    const [loadItems, reload] = useReload(({ limitRows, currentPage }) => {
        if (!selectedCheck)
            return Promise.resolve([]);
        setIsLoading(true);
        setInitial(false);
        return statementApi
            .get(selectedCheck.value.bankAccountCftId, dateValue ? dateValue[0] : undefined, dateValue ? dateValue[1] : undefined, uuidV4, currentPage, limitRows)
            .then(res => {
            setStatement(res);
            if (res.records) {
                const { records: { count, data = [] }, } = res;
                return Promise.resolve({
                    items: data,
                    total: count,
                });
            }
            return Promise.resolve({
                items: [],
                total: 0,
            });
        })
            .finally(() => setIsLoading(false));
    }, []);
    /** Загрузка выписок  */
    useEffect(() => {
        principalOrganizationClient
            .getAccounts(AccountsStatuses.All)
            .then(accounts => {
            const options = accounts.map(i => ({
                value: i,
                label: i.accountNumber,
            }));
            setSelectedCheck(options[0] ?? null);
            setAccounts(options);
            reload();
        })
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => setIsLoading(false));
    }, [errorAlert, reload]);
    /** Функция обновления uuid */
    const uuidUpdate = useCallback(() => {
        setUuidV4(v4());
        reload();
    }, [reload]);
    /** Функция получения всех выписок */
    const getAllStatements = useCallback(() => {
        if (paginationRef.current)
            paginationRef.current({ currentPage: 1, limitRows: 10000 });
        reload();
    }, [paginationRef, reload]);
    /** Функция скачивания выписки в PDF формате */
    const downloadPDF = useCallback(() => {
        const [start, end] = dateValue ?? InitialDateRange;
        setDownloadLoading(true);
        setDownloadFormat(DowmloadFormat.PDF);
        statementApi
            .getLinkExportToPdf(selectedCheck?.value.bankAccountCftId, start, end, uuidV4)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadFormat(DowmloadFormat.DRAFT);
        });
    }, [dateValue, download, errorAlert, selectedCheck, uuidV4]);
    /** Функция скачивания выписки в XLS формате */
    const downloadXLS = useCallback(() => {
        const [start, end] = dateValue ?? InitialDateRange;
        setDownloadLoading(true);
        setDownloadFormat(DowmloadFormat.EXCEL);
        statementApi
            .getLinkExportToXlsx(selectedCheck?.value.bankAccountCftId, start, end, uuidV4)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadFormat(DowmloadFormat.DRAFT);
        });
    }, [dateValue, download, errorAlert, selectedCheck, uuidV4]);
    /** Функция скачивания выписки в 1c формате */
    const download1cv101 = useCallback(() => {
        const [start, end] = dateValue ?? InitialDateRange;
        setDownloadLoading(true);
        setDownloadFormat(DowmloadFormat.TO1CV101);
        statementApi
            .getLinkExportTo1cv101(selectedCheck?.value.bankAccountCftId, start, end, uuidV4)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadFormat(DowmloadFormat.DRAFT);
        });
    }, [dateValue, download, errorAlert, selectedCheck, uuidV4]);
    /** Функция скачивания выписки в 1cv102 формате */
    const download1cv102 = useCallback(() => {
        const [start, end] = dateValue ?? InitialDateRange;
        setDownloadLoading(true);
        setDownloadFormat(DowmloadFormat.TO1CV102);
        statementApi
            .getLinkExportTo1cv102(selectedCheck?.value.bankAccountCftId, start, end, uuidV4)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadFormat(DowmloadFormat.DRAFT);
        });
    }, [dateValue, download, errorAlert, selectedCheck, uuidV4]);
    /** Функция скачивания выписки в 1cvMT940 формате */
    const download1cvMT940 = useCallback(() => {
        const [start, end] = dateValue ?? InitialDateRange;
        setDownloadLoading(true);
        setDownloadFormat(DowmloadFormat.TO1CVMT940);
        statementApi
            .getLinkExportTo1cvMT940(57841433, start, end, statement?.id)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadFormat(DowmloadFormat.DRAFT);
        });
    }, [dateValue, download, errorAlert, statement]);
    const onLoadError = useCallback(() => errorAlert('Ошибка во время загрузки'), [errorAlert]);
    /** id загружаемой операции */
    const [downloadStatementId, setDownloadStatementId] = useState('');
    /** Загрузить pdf операции */
    const onPdfDownload = useCallback((id) => () => {
        setDownloadStatementId(id);
        statementApi
            .getLinkExportOrderToPdf(uuidV4, id)
            .then(download)
            .finally(() => {
            setDownloadStatementId('');
        });
    }, [download, uuidV4]);
    /** Пропсы для колонки с чекбоксами */
    const rowCheckProps = useMemo(() => ({
        selectAllCheck: true,
        checked,
        setChecked,
    }), [checked]);
    /** Кнопки массовых действий */
    const dropDownItems = useMemo(() => [
        {
            title: 'Выгрузка PDF',
            onClick: () => {
                setIsLoading(true);
                statementApi
                    .getLinkExportOrdersToPdf(uuidV4, checked.map(i => i.id))
                    .then(download)
                    .catch(() => errorAlert('Ошибка во время загрузки'))
                    .finally(() => setIsLoading(false));
            },
        },
    ], [checked, download, errorAlert, uuidV4]);
    const columns = useMemo(() => [
        {
            key: 1,
        },
        {
            key: 2,
            title: 'документ',
            cellContent: DocumentCell,
        },
        {
            key: 3,
            title: 'БИК',
            cellContent: BikCell,
        },
        {
            key: 4,
            title: 'плательщик',
            cellContent: PayerCell,
        },
        {
            key: 5,
            title: 'получатель',
            cellContent: PayeeCell,
        },
        {
            key: 6,
            title: 'дебет',
            cellContent: DebitCell,
        },
        {
            key: 7,
            title: 'кредит',
            cellContent: CreditCell,
        },
        {
            key: 8,
            title: 'назначение платежа',
            cellContent: CommentCell,
            cellProps: () => ({ className: tableStyles.medium }),
            headCellProps: { className: tableStyles.medium },
        },
        {
            key: 9,
            cellContent: item => haveAccess(PStatementsMapper.ExportToPDF) &&
                ExportPdfCell(item.id, downloadStatementId, onPdfDownload, downloadStatementId !== ''),
        },
    ], [downloadStatementId, haveAccess, onPdfDownload]);
    /** Тулбар с фильтрами */
    const toolbar = useMemo(() => ({
        right: (React.createElement(Tooltip, { tooltipText: '\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u0432\u0441\u0435 \u0432\u044B\u043F\u0438\u0441\u043A\u0438' },
            React.createElement("button", { type: 'button', onClick: getAllStatements },
                React.createElement("img", { className: styles.actionButton, src: documentsIcon, alt: '\u0412\u0441\u0435 \u0432\u044B\u043F\u0438\u0441\u043A\u0438' })))),
    }), [getAllStatements]);
    return (React.createElement("div", { className: stylesMain.contentWrapper },
        React.createElement(PageTitle, { title: '\u0412\u044B\u043F\u0438\u0441\u043A\u0430' }),
        React.createElement("div", { className: styles.requestContainer },
            React.createElement("div", { className: styles.selectStatementContainer },
                React.createElement("div", { className: styles.selectContainer },
                    React.createElement(SelectUI, { disabled: isLoading, getValue: i => i.label, isClearable: true, options: accounts, onChange: (value) => {
                            setSelectedCheck(value);
                        }, value: selectedCheck, title: '\u0421\u0447\u0435\u0442' }),
                    React.createElement(DateInputRange, { disabled: isLoading, isClearable: true, value: dateValue, onChange: setDateValue, title: '\u0414\u0430\u0442\u0430 \u0438\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u0430' }))),
            React.createElement("div", { className: styles.flexGap },
                React.createElement(DropdownButton, { position: 'bottom', disabled: !checked.length || isLoading, dropDownItems: dropDownItems }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0441 \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u044F\u043C\u0438"),
                React.createElement(RectButtonUI, { disabled: selectedCheck === null || !dateValue || isLoading, onClick: uuidUpdate }, "\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u0432\u044B\u043F\u0438\u0441\u043A\u0443"))),
        (statement || initial) && (React.createElement("div", { className: styles.responseContainer }, statement !== null ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.loadContainer },
                haveAccess(PStatementsMapper.ExportToPDF) && (React.createElement("button", { disabled: downloadLoading, onClick: downloadPDF, type: 'button', className: styles.loadButton },
                    downloadLoading && downloadFormat === DowmloadFormat.PDF ? (React.createElement("div", { className: styles.preloaderContainer },
                        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: pdfIcon, alt: 'pdf \u0444\u043E\u0440\u043C\u0430\u0442' })),
                    React.createElement("span", { className: styles.textButton }, "PDF"))),
                haveAccess(PStatementsMapper.ExportToXlsx) && (React.createElement("button", { disabled: downloadLoading, onClick: downloadXLS, type: 'button', className: styles.loadButton },
                    downloadLoading && downloadFormat === DowmloadFormat.EXCEL ? (React.createElement("div", { className: styles.preloaderContainer },
                        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: xlsIcon, alt: 'excel \u0444\u043E\u0440\u043C\u0430\u0442' })),
                    React.createElement("span", { className: styles.textButton }, "Excel"))),
                haveAccess(PStatementsMapper.ExportTo1c101) && (React.createElement("button", { disabled: downloadLoading, onClick: download1cv101, type: 'button', className: styles.loadButton },
                    downloadLoading && downloadFormat === DowmloadFormat.TO1CV101 ? (React.createElement("div", { className: styles.preloaderContainer },
                        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: cIcon, alt: '1C \u0444\u043E\u0440\u043C\u0430\u0442' })),
                    React.createElement("span", { className: styles.textButton }, "1C v101"))),
                haveAccess(PStatementsMapper.ExportTo1c102) && (React.createElement("button", { disabled: downloadLoading, onClick: download1cv102, type: 'button', className: styles.loadButton },
                    downloadLoading && downloadFormat === DowmloadFormat.TO1CV102 ? (React.createElement("div", { className: styles.preloaderContainer },
                        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: cIcon, alt: '1C \u0444\u043E\u0440\u043C\u0430\u0442' })),
                    React.createElement("span", { className: styles.textButton }, "1C v102"))),
                haveAccess(PStatementsMapper.ExportToMT940) && (React.createElement("button", { disabled: downloadLoading, onClick: download1cvMT940, type: 'button', className: styles.loadButton },
                    downloadLoading && downloadFormat === DowmloadFormat.TO1CVMT940 ? (React.createElement("div", { className: styles.preloaderContainer },
                        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: cIcon, alt: '1C \u0444\u043E\u0440\u043C\u0430\u0442' })),
                    React.createElement("span", { className: styles.textButton }, "1C vMT940")))),
            React.createElement("div", { className: styles.info },
                React.createElement("div", null,
                    React.createElement("div", { className: styles.infoContainer },
                        React.createElement("p", { className: styles.titleInfo }, "\u041F\u0435\u0440\u0438\u043E\u0434"),
                        React.createElement("p", { className: styles.textInfo },
                            formatDate(statement.fromDate),
                            " - ",
                            formatDate(statement.toDate))),
                    React.createElement("div", { className: styles.infoContainer },
                        React.createElement("p", { className: styles.titleInfo }, "\u0421\u0447\u0435\u0442"),
                        React.createElement("p", { className: styles.textInfo }, statement.accountNumber))),
                React.createElement("div", null,
                    React.createElement("div", { className: styles.infoContainer },
                        React.createElement("p", { className: styles.titleInfo }, "\u041E\u0441\u0442\u0430\u0442\u043E\u043A \u043D\u0430 \u043D\u0430\u0447\u0430\u043B\u043E"),
                        React.createElement("p", { className: cn(styles.textInfo, styles.textInfoCredit, {
                                [styles.textInfoDebit]: statement.openingBalance < 0,
                            }) },
                            toFormatMoney(statement.openingBalance),
                            " \u20BD")),
                    React.createElement("div", { className: styles.infoContainer },
                        React.createElement("p", { className: styles.titleInfo }, "\u041E\u0441\u0442\u0430\u0442\u043E\u043A \u043D\u0430 \u043A\u043E\u043D\u0435\u0446"),
                        React.createElement("p", { className: cn(styles.textInfo, styles.textInfoCredit, {
                                [styles.textInfoDebit]: statement.endingBalance < 0,
                            }) },
                            toFormatMoney(statement.endingBalance),
                            " \u20BD"))),
                React.createElement("div", null,
                    React.createElement("div", { className: cn(styles.infoContainer, styles.infoContainerMw) },
                        React.createElement("p", { className: styles.titleInfo }, "\u0414\u0435\u0431\u0435\u0442"),
                        React.createElement("p", { className: cn(styles.textInfo, styles.textInfoDebit) },
                            toFormatMoney(statement.turnDT),
                            " \u20BD")),
                    React.createElement("div", { className: cn(styles.infoContainer, styles.infoContainerMw) },
                        React.createElement("p", { className: styles.titleInfo }, "\u041A\u0440\u0435\u0434\u0438\u0442"),
                        React.createElement("p", { className: cn(styles.textInfo, styles.textInfoCredit) },
                            toFormatMoney(statement.turnKT),
                            " \u20BD")))))) : (initial && React.createElement("div", { className: styles.helperText }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0447\u0435\u0442 \u0438 \u0443\u043A\u0430\u0436\u0438\u0442\u0435 \u0438\u043D\u0442\u0435\u0440\u0432\u0430\u043B")))),
        React.createElement(GenericTable, { toolbar: toolbar, rowCheckProps: rowCheckProps, loading: isLoading, onLoadError: onLoadError, getItemKey: getItemKey, columns: columns, pagination: pagination, getItems: loadItems, paginationRef: paginationRef })));
};
