import { BASE_PATH, LETTERS, PAYMENTS, routeMapper, STATEMENTS } from '../routes';
import { cardIcon, lettersIcon, paymentsIcon, statementsIcon, } from './general-code-import/images-import';
export const sectionsInfo = [
    {
        route: BASE_PATH,
        label: routeMapper(BASE_PATH),
        icon: cardIcon,
    },
    {
        route: PAYMENTS,
        label: routeMapper(PAYMENTS),
        icon: paymentsIcon,
    },
    {
        route: STATEMENTS,
        label: routeMapper(STATEMENTS),
        icon: statementsIcon,
    },
    {
        route: LETTERS,
        label: routeMapper(LETTERS),
        icon: lettersIcon,
    },
];
