/** Соединение составного пути */
export const joinRoutePaths = (...parts) => parts.join('/');
/** Убрать первый / */
export const pathNameReplacer = (pathname) => pathname.length === 1 ? pathname : pathname.replace('/', '');
/**
 * Создание регулярного выражения для частичного пути
 *
 * */
export const createPartialRegExp = (partialPath) => new RegExp(`^${partialPath}\\/(\\w|\\d|-)+$`);
/** Создание маппера  */
export const createRouteMapper = (mapping, partialMapping) => {
    /** Соответствие динамических (зарегистрированных не при ининциализации) значений */
    const exoticMap = new Map();
    /** Регистация особого случая */
    const registerExoticPath = (path, title) => exoticMap.set(path.replace('/', ''), title);
    /** Регестрация текущего пути */
    const registerCurrentPath = (title) => registerExoticPath(window.location.pathname, title);
    /** Соответсвие регулярного выражения частичного пути и подписи */
    const partialMatcher = Array.from(partialMapping.entries()).map(([partial, title]) => ({
        regExp: createPartialRegExp(partial),
        title,
    }));
    /** Функция сопоставляющая значения по всем случаям */
    const routeMapper = (path) => {
        /** Удаление перого слэша */
        const replacedPath = pathNameReplacer(path);
        /** Если зарегистрирован особый случай, то вернуть его */
        const maybeExotic = exoticMap.get(replacedPath);
        if (maybeExotic)
            return maybeExotic;
        /** Статичный случай */
        const maybeStatic = mapping.get(replacedPath);
        if (maybeStatic)
            return maybeStatic;
        /** Частичный путь */
        const partialStatic = partialMatcher.find(({ regExp }) => regExp.test(replacedPath))?.title;
        if (partialStatic)
            return partialStatic;
        /** Если в маппиге нет значения, то возвращается путь */
        if (IS_DEV) {
            /** В режиме dev сборки в console будет выведено сообщение об ошибке для её устранения */
            // eslint-disable-next-line no-console
            console.error(`Путь ${path} не зарегистрирован ни как статический ни как динамический (с помощью функции ${registerExoticPath.name})`);
        }
        return path;
    };
    return {
        routeMapper,
        registerExoticPath,
        registerCurrentPath,
    };
};
