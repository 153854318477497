import React, { useState } from 'react';
import styles from './profile.module.scss';
import stylesMain from '../../utils/styles/main.module.scss';
import { PageTitle } from '../page-title';
import { Tabs } from '../tabs';
import { RectButtonUI } from '../rect-button-ui';
import { goToChangePassword } from '../../utils/auth';
const parametersTabsProfile = [{ tabTitle: 'Смена пароля', key: 0 }];
export const Profile = () => {
    const [activeTab, setFiltersLettersTab] = useState(parametersTabsProfile[0]);
    return (React.createElement("div", { className: stylesMain.contentWrapper },
        React.createElement(PageTitle, { title: '\u041C\u043E\u0439 \u043F\u0440\u043E\u0444\u0438\u043B\u044C' }),
        React.createElement(Tabs, { options: parametersTabsProfile, activeTab: activeTab, onChange: setFiltersLettersTab }),
        React.createElement("div", { className: styles.profile }, activeTab.key === 0 && (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: styles.title }, "\u0421\u043C\u0435\u043D\u0430 \u043F\u0430\u0440\u043E\u043B\u044F"),
            React.createElement(RectButtonUI, { onClick: goToChangePassword }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C"))))));
};
