export var PLetterAttachments;
(function (PLetterAttachments) {
    PLetterAttachments["AddAttachment"] = "AddAttachment";
    PLetterAttachments["DeleteAttachmentPermanently"] = "DeleteAttachmentPermanently";
    PLetterAttachments["GetBaseInfo"] = "GetBaseInfo";
    PLetterAttachments["GetNotLinkedAttachments"] = "GetNotLinkedAttachments";
    PLetterAttachments["GetLinkedAttachments"] = "GetLinkedAttachments";
    PLetterAttachments["DeleteAttachment"] = "DeleteAttachment";
    PLetterAttachments["Download"] = "Download";
})(PLetterAttachments || (PLetterAttachments = {}));
/** Словарь, описывающий все значния из енума с правами */
export const PLetterAttachmentsMapper = {
    [PLetterAttachments.AddAttachment]: { name: 'letters_pletterattachments_addattachment', description: 'Добавить' },
    [PLetterAttachments.DeleteAttachmentPermanently]: { name: 'letters_pletterattachments_deleteattachmentpermanently', description: 'УДАЛИТЬ НАВСЕГДА' },
    [PLetterAttachments.GetBaseInfo]: { name: 'letters_pletterattachments_getbaseinfo', description: 'Просмотр информации' },
    [PLetterAttachments.GetNotLinkedAttachments]: { name: 'letters_pletterattachments_getnotlinkedattachments', description: 'Получить список не связанных вложений' },
    [PLetterAttachments.GetLinkedAttachments]: { name: 'letters_pletterattachments_getlinkedattachments', description: 'Получить список связанных вложений' },
    [PLetterAttachments.DeleteAttachment]: { name: 'letters_pletterattachments_deleteattachment', description: 'Удалить' },
    [PLetterAttachments.Download]: { name: 'letters_pletterattachments_download', description: 'Скачать' }
};
