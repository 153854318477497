/**
 * Хук для вызова submit формы
 * */
import { useCallback, useRef } from 'react';
export const useFormSubmit = () => {
    /** Ссылка на форму */
    const ref = useRef(null);
    /** Вызвать submit формы */
    const submit = useCallback(() => ref.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })), []);
    return [ref, submit];
};
