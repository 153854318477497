import React from 'react';
import { ErrorBoundary } from '../error-boundary';
import { Header } from '../header';
import { Navigation } from '../navigation';
import styles from './layout.module.scss';
import { AlertContext } from '../alert-context';
import { BuildInfo } from '../build-info';
/**
 * Страница 403 - отображается если нет прав на пункты меню
 * */
const Forbidden = () => (React.createElement("div", { className: styles.forbidden },
    React.createElement("div", { className: styles.error }, "403"),
    React.createElement("div", { className: styles.text }, "\u0414\u043E\u0441\u0442\u0443\u043F \u0437\u0430\u043F\u0440\u0435\u0449\u0435\u043D!")));
/** Основная разметка приложения */
export const MainLayout = ({ children, navigation: { options, ...restNavigations }, header, }) => (React.createElement(ErrorBoundary, null,
    React.createElement(AlertContext, null,
        React.createElement("div", { className: styles.page },
            React.createElement("div", { className: styles.container },
                options.length > 0 && React.createElement(Navigation, { ...restNavigations, options: options }),
                React.createElement("div", { className: styles.contentWithHeaderContainer },
                    React.createElement(Header, { ...header }),
                    options.length > 0 ? (React.createElement("div", { className: styles.contentContainer },
                        React.createElement("div", { className: styles.content }, children))) : (React.createElement(Forbidden, null)))),
            React.createElement(BuildInfo, null)))));
