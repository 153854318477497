import './cadesplugin_api';
/** Получение сертификатов */
export function getCertificates() {
    return new Promise(function (resolve, reject) {
        window.cadesplugin.async_spawn(function* (args) {
            var oStore = yield window.cadesplugin.CreateObjectAsync("CAPICOM.store");
            if (!oStore) {
                console.error('Не удалось создать объект ActiveX: getCryptoCertificates');
                args[0](false);
            }
            var result = [];
            try {
                yield oStore.Open();
                var Certificates = yield oStore.Certificates;
                var count = yield Certificates.Count;
                if (count == 0) {
                    oStore.Close();
                    args[0](null);
                }
                var now = new Date();
                for (var i = 1; i <= count; i++) {
                    try {
                        var cert = yield Certificates.Item(i);
                        var issuer = yield cert.IssuerName;
                        var serial = yield cert.SerialNumber;
                        var subject = yield cert.SubjectName;
                        var thumbprint = yield cert.Thumbprint;
                        var validFrom = new Date((yield cert.ValidFromDate));
                        var validTo = new Date((yield cert.ValidToDate));
                        var valid = yield cert.IsValid();
                        valid = yield valid.Result;
                        var publicKey = yield cert.PublicKey();
                        var publicKeyValue = yield publicKey.EncodedKey;
                        publicKeyValue = yield publicKeyValue.Value();
                        var algorithm = yield publicKey.Algorithm;
                        algorithm = yield algorithm.FriendlyName;
                        var n = subject.substr(subject.indexOf("CN=") + 3);
                        var name = n.indexOf(",") > 0 ? n.substr(0, n.indexOf(",")) : n;
                        result.push({
                            issuer: issuer,
                            serialNumber: serial,
                            subject: subject,
                            validTo: validTo,
                            name: name,
                            thumbprint: thumbprint,
                            validFrom: validFrom,
                            isValid: valid,
                            signatureAlgorithm: algorithm,
                            publicKey: publicKeyValue
                        });
                    }
                    catch (e) {
                        console.error('Ошибка при перечислении сертификатов: ' + e.description);
                        args[0](null);
                    }
                }
            }
            catch (e) {
                console.error('Ошибка при открытии хранилища: ' + e.description);
                args[0](null);
            }
            oStore.Close();
            args[0](result);
        }, resolve, reject);
    });
}
;
/** Подпись по серийному номеру сертификата */
export function signDataByCertificateSerialNumber(certToSign, dataToSign, isDetached) {
    var CADESCOM_CADES_BES = 1;
    var CADESCOM_BASE64_TO_BINARY = 1;
    //var CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
    return new Promise(function (resolve, reject) {
        window.cadesplugin.async_spawn(function* (args) {
            try {
                //var thumbprint = certToSign.split(" ").reverse().join("").replace(/\s/g, "").toUpperCase();
                try {
                    var oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                    yield oStore.Open();
                }
                catch (err) {
                    console.error('Failed to create CAdESCOM.Store: ' + err.number);
                    return;
                }
                var all_certs = yield oStore.Certificates;
                //ищем сертификат по серийному номеру
                var oCertificate = null;
                var count = yield all_certs.Count;
                for (var i = 1; i <= count; i++) {
                    var certificate = yield all_certs.Item(i);
                    var serialNumber = yield certificate.SerialNumber;
                    if (serialNumber == certToSign) {
                        oCertificate = certificate;
                        break;
                    }
                }
                if (oCertificate == null) {
                    console.error("Certificate not found");
                    return;
                }
                var oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                yield oSigner.propset_Certificate(oCertificate);
                var oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                if (isDetached) {
                    yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                }
                yield oSignedData.propset_Content(dataToSign);
                var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, isDetached);
                yield oStore.Close();
                args[2](sSignedMessage);
            }
            catch (e) {
                args[3]("Failed to create signature. Error: " + e);
            }
        }, certToSign, dataToSign, resolve, reject);
    });
}
