import React, { useCallback, useMemo, useState } from 'react';
import { GenericTable, Popup, RectButtonUI, SmallPreloader, useAlert, useReload, } from '../../index';
import attachmentIcon from '../../static/images/icons/attachment-dark.svg';
import deleteIconDark from '../../static/images/icons/close-dark.svg';
import downloadIcon from '../../static/images/icons/download-dark.svg';
import { formatDate } from '../../utils/smallFunctions';
import stylesMain from '../../utils/styles/main.module.scss';
import { restrictLetterAttachmentSize } from '../letter-layout';
import tableStyles from '../table/table-styles.module.scss';
import styles from './files-letters-popup.module.scss';
const DownloadButtonCell = (itemId, currentDownloading, downloadAttachment, downloadLoading) => (React.createElement("button", { "data-testid": 'download', disabled: downloadLoading, onClick: downloadAttachment, type: 'button', className: styles.downloadButton }, downloadLoading && currentDownloading === itemId ? (React.createElement("div", { className: styles.preloaderContainer },
    React.createElement(SmallPreloader, null))) : (React.createElement("img", { src: downloadIcon, alt: '\u0441\u043A\u0430\u0447\u0430\u0442\u044C' }))));
const DateCell = (createDate) => (React.createElement("p", { className: stylesMain.tableTextBody }, formatDate(createDate, 'dd.MM.yyyy HH:mm:ss')));
const FileNameCell = (realName) => React.createElement("p", { className: stylesMain.tableTextHead }, realName);
const DeleteCell = (deleteAttachment, isDraft) => isDraft && (React.createElement("button", { "data-testid": 'delete', onClick: deleteAttachment, type: 'button', className: styles.deleteButton },
    React.createElement("img", { src: deleteIconDark, alt: '\u0443\u0434\u0430\u043B\u0438\u0442\u044C' })));
/** Диалог с вложениями письма */
export function FilesLetterPopup({ isDraft, loadAttachments, getAttachment, deleteAttachment: deleteAttachmentExternal, addAttachment: addAttachmentExternal, downloadAttachment: downloadAttachmentExternal, rightsAttachmentAccess: { downloadAttachmentAccess, addAttachmentAccess, deleteAttachmentAccess }, ...rest }) {
    const { errorAlert, warningAlert } = useAlert();
    /** Загрузка файлов */
    const [loadItems, reload] = useReload(() => loadAttachments(), []);
    /** Файл */
    const [file, setFile] = useState(null);
    /** Состояние загрузки */
    const [loading, setIsLoading] = useState(false);
    /** Состояние скачивания */
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadAttachmentId, setDownloadAttachmentId] = useState(null);
    /** Выбор файла */
    const onFileChoose = useCallback((evt) => {
        if (evt.target.files && evt.target.files.length) {
            const file = evt.target.files[0];
            restrictLetterAttachmentSize(file, warningAlert, setFile);
        }
    }, [warningAlert]);
    /** Загрузка файла */
    const downloadAttachment = useCallback((attachmentId) => {
        setDownloadLoading(true);
        setDownloadAttachmentId(attachmentId);
        downloadAttachmentExternal(attachmentId)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => setDownloadLoading(false));
    }, [downloadAttachmentExternal, errorAlert]);
    /** Добавление файла */
    const addAttachment = useCallback(() => {
        if (file) {
            setFile(null);
            setIsLoading(true);
            restrictLetterAttachmentSize(file, warningAlert, file => {
                addAttachmentExternal(file)
                    .catch(() => errorAlert('Произошла ошибка'))
                    .finally(() => {
                    setIsLoading(false);
                    reload();
                });
            });
        }
    }, [addAttachmentExternal, errorAlert, file, reload, warningAlert]);
    /** Удаление файла */
    const deleteAttachment = useCallback((attachmentId) => {
        setIsLoading(true);
        deleteAttachmentExternal(attachmentId)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setIsLoading(false);
            reload();
        });
    }, [deleteAttachmentExternal, errorAlert, reload]);
    /** Обработка ошибки при загрузке */
    const onLoadError = useCallback(() => errorAlert('Ошибка во время загрузки'), [errorAlert]);
    const columns = useMemo(() => [
        {
            key: 1,
            cellContent: item => downloadAttachmentAccess &&
                DownloadButtonCell(getAttachment(item).id, downloadAttachmentId, () => downloadAttachment(getAttachment(item).id), downloadLoading),
            headCellProps: {
                className: tableStyles.extraSmall,
            },
            cellProps: () => ({
                className: tableStyles.extraSmall,
            }),
        },
        {
            key: 2,
            title: '↓ дата создания',
            cellContent: item => DateCell(getAttachment(item).createDate),
        },
        {
            key: 3,
            title: 'наименнование файла',
            cellContent: item => FileNameCell(getAttachment(item).realName),
        },
        {
            key: 4,
            cellContent: item => deleteAttachmentAccess &&
                DeleteCell(() => deleteAttachment(getAttachment(item).id), isDraft),
        },
        {
            key: 5,
        },
    ], [
        deleteAttachment,
        deleteAttachmentAccess,
        downloadAttachment,
        downloadAttachmentAccess,
        downloadLoading,
        getAttachment,
        isDraft,
    ]);
    /** Функционал загрузки файлов */
    const footer = isDraft && addAttachmentAccess && (React.createElement("div", { className: styles.loadFileContainer },
        React.createElement("label", { htmlFor: 'fileLoad' },
            React.createElement("input", { disabled: loading, id: 'fileLoad', onChange: onFileChoose, className: styles.hideInput, type: 'file', accept: '.pdf,.doc,.docx,.xls,.xlsx' }),
            React.createElement("div", { className: styles.loadFile },
                React.createElement("img", { className: styles.loadFileIcon, src: attachmentIcon, alt: '\u0437\u0430\u0440\u0433\u0443\u0437\u0438\u0442\u044C' }),
                file?.name ?? 'Добавить файл')),
        React.createElement(RectButtonUI, { disabled: file === null || loading, onClick: addAttachment }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")));
    const getItemKey = useCallback((item) => getAttachment(item).id, [getAttachment]);
    return (React.createElement(Popup, { header: '\u0412\u043B\u043E\u0436\u0435\u043D\u043D\u044B\u0435 \u0444\u0430\u0439\u043B\u044B', ...rest, footer: footer },
        React.createElement(GenericTable, { loading: loading, getItems: loadItems, columns: columns, getItemKey: getItemKey, onLoadError: onLoadError, rowHoverTransition: false })));
}
