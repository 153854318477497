import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { RectButtonUI } from '../rect-button-ui';
import styles from './dropdown.module.scss';
/**
 * Кнопка с выпадающим списком
 * */
export const DropdownButton = ({ disabled, dropDownItems, position, ...buttonProps }) => {
    /** Состояния открытия списка */
    const [open, setOpen] = useState(false);
    /** Изменение состояния списка */
    const toggle = useCallback((e) => {
        e.stopPropagation();
        setOpen(prev => !prev);
    }, []);
    /** Закрытие при блокировке */
    useEffect(() => {
        if (disabled)
            setOpen(false);
    }, [disabled]);
    /** Закрытие списка при клике в любое место */
    useEffect(() => {
        const listener = () => setOpen(false);
        document.addEventListener('click', listener);
        return () => document.removeEventListener('click', listener);
    }, []);
    return (React.createElement("div", { className: styles.container },
        React.createElement(RectButtonUI, { disabled: disabled, ...buttonProps, onClick: toggle }),
        open && (React.createElement("ul", { className: cn(styles.card, styles[position]) }, dropDownItems.map(({ disabled, onClick, className, title }) => (React.createElement("li", { className: cn(className, { [styles.disabled]: disabled }), onClick: !disabled ? onClick : undefined, key: title }, title)))))));
};
