import { Base64 } from 'js-base64';
import { getCertificates, signDataByCertificateSerialNumber, } from '../../vendor/cades/crypto_async';
/**
 * Получение валидных сертификатов
 * */
export const getValidCertificates = () => new Promise((resolve, reject) => {
    if (window.cadesplugin) {
        resolve(window.cadesplugin.then(() => getCertificates()
            /** Фильтрация, остаются только валиденые */
            .then(certs => {
            if (IS_DEV) {
                certs.forEach(cert => {
                    // eslint-disable-next-line no-console
                    console.log(`THUMBPRINT_CERT: ${cert.thumbprint}; IS_VALID: ${String(cert.isValid)}; VALID_FROM: ${String(cert.validFrom)}; VALID_TO: ${String(cert.validTo)};`);
                });
            }
            return certs.filter(item => new Date() <= item.validTo && new Date() >= item.validFrom);
        })));
    }
    else {
        reject(new Error('Плагин не инициализирован'));
    }
});
/**
 * Создать подпись сообщения
 * */
export const createSignature = (certificate, encodedMessage) => new Promise((resolve, reject) => {
    if (window.cadesplugin) {
        resolve(window.cadesplugin.then(() => signDataByCertificateSerialNumber(certificate.serialNumber, encodedMessage, true)));
    }
    else {
        reject(new Error('Плагин не инициализирован'));
    }
});
/**
 * Кодирование сообщения в base64
 * */
export const encodeBase64 = (message) => {
    const stringifyMessage = typeof message === 'string' ? message : JSON.stringify(message);
    return Base64.encode(stringifyMessage, false);
};
