import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { appReducer } from './slice/appSlice';
import { tabReducer } from './slice/tabSlice';
const store = configureStore({
    reducer: {
        app: appReducer,
        tabs: tabReducer,
    },
});
export const useAppDispatch = () => useDispatch();
export default store;
