import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import attachmentIcon from '../../static/images/icons/attachment-dark.svg';
import deleteIcon from '../../static/images/icons/close-light.svg';
import stylesMain from '../../utils/styles/main.module.scss';
import { useAlert } from '../alert-context';
import { ConfirmDeletePopup } from '../confirm-delete-popup';
import styles from './letter-layout.module.scss';
import { MultiSignPopup } from '../multi-sign-popup';
import { PageTitle } from '../page-title';
import { Preloader } from '../preloader';
import { InputUI } from '../input-ui';
import { TextareaUI } from '../textarea-ui';
import { SmallPreloader } from '../small-preloader';
import { Tooltip } from '../tooltip';
import { formatDate, getValueSelect } from '../../utils/smallFunctions';
import { RectButtonUI } from '../rect-button-ui';
import { SelectUI } from '../select-ui';
import { useFormSubmit } from '../../utils/hooks/use-form-submit';
/** Максимальный размер вложений к письмам */
export const LETTER_ATTACHMENT_MAX_SIZE = 50;
/** Разрешенные расширения вложений к письмам */
export const LETTER_EXTENSIONS = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
/** Текст предупреждения при превышении максимального размера файла */
export const LETTER_MAX_SIZE_WARNING_MESSAGE = `Вложение письма не может превышать размер ${LETTER_ATTACHMENT_MAX_SIZE} Мб`;
/** Текст предупреждения при не корректном расширении файла */
export const LETTER_EXTENSIONS_WARNING_MESSAGE = `Не допустимое расширение вложения письма (разрешенные: ${LETTER_EXTENSIONS.join(', ')}`;
/** Текст предупреждения при не распознаном расширении файла */
export const LETTER_NOT_RECOGNIZED_EXTENSIONS_WARNING_MESSAGE = `Не распознано расширение вложения письма (разрешенные: ${LETTER_EXTENSIONS.join(', ')}`;
/** Текст предупреждения при повторной загрузке файла */
export const LETTER_DUPLICATE_FILE = 'Данный файл уже был загружен';
/** Ограничение на размер загружаемого и доступные расширения файла для писем */
export const restrictLetterAttachmentSize = (file, warning, cb) => {
    const ext = file.name.split('.').pop();
    if (ext) {
        if (!LETTER_EXTENSIONS.includes(ext)) {
            warning(LETTER_EXTENSIONS_WARNING_MESSAGE);
            return;
        }
        if (file.size > LETTER_ATTACHMENT_MAX_SIZE * 1024 * 1024) {
            warning(LETTER_MAX_SIZE_WARNING_MESSAGE);
            return;
        }
    }
    else {
        warning(LETTER_NOT_RECOGNIZED_EXTENSIONS_WARNING_MESSAGE);
        return;
    }
    cb(file);
};
/**
 * Макет письма
 * Макет для основной страницы "Письма"
 * */
export function LetterLayout({ initialValues = {}, date = new Date(), isExistLetter, isDraft, organizationsSelect, certificatePredicate, api: { onSubmit: onSubmitExternal, onDownloadAttachment, onUploadFile, onSignComplete, onCreateDuplicate, onSendLetter, onReply, onDeleteLetter, createSignData, }, staticProps: { services, optionsSelect, isService, isBase }, replied = {}, statusText, rightsAccess: { createAccess, deleteAccess, saveAndSendSignedAccess, saveSignedAccess, sendAccess, addAttachmentAccess, deleteAttachmentAccess, }, }) {
    /** Опции для работы с react-hook-form */
    const { setValue, watch, getValues, handleSubmit, register, control, formState: { errors, isValid, isDirty }, } = useForm({
        mode: 'onChange',
        defaultValues: initialValues?.attachments
            ? initialValues
            : { ...initialValues, attachments: [] },
    });
    /** Данные по сообщению, на которе отвечаем */
    const [{ letterRepliedCreateDate, letterRepliedTheme }] = useState(replied);
    /** Выбранная категория */
    const category = watch('category');
    const isBaseLetter = category ? isBase(category.value) : false;
    const isServiceLetter = category ? isService(category.value) : false;
    const [attachments, setAttachmentsToState] = useState(getValues('attachments'));
    /** Сохранение вложений в стейт и форму */
    const setAttachments = useCallback((f) => {
        setAttachmentsToState(prev => {
            const newValue = f(prev);
            setValue('attachments', newValue, { shouldDirty: true });
            return newValue;
        });
    }, [setValue]);
    const { errorAlert, warningAlert } = useAlert();
    /** Выбранное вложение */
    const [selectedAttachment, setSelectedAttachment] = useState();
    /** Состояние загрузки */
    const [open, setOpen] = useState(false);
    /** Состояние загрузки */
    const [isLoading, setIsLoading] = useState(false);
    /** Состояние отправки письма после подписи */
    const [isSend, setIsSend] = useState(false);
    /** Ключ инпута для обновления состояния */
    const [keyInputFileLoad, setKeyInputFileLoad] = useState('input');
    /** Состояние загрузки вложений */
    const isAttachmentLoading = attachments.some(item => item.isLoading);
    /** Обработчик закрытия попап */
    const onClosePopup = useCallback(() => {
        setOpen(false);
        setSelectedAttachment(undefined);
    }, []);
    /** Управление submit`ом формы */
    const [formRef, submit] = useFormSubmit();
    /** Данные для подписи */
    const [dataToSign, setDataToSign] = useState(null);
    /** Загрузка файла */
    const onFileChoose = useCallback((e) => {
        if (e.target.files && e.target.files[0]) {
            setKeyInputFileLoad(e.target.value);
            const file = e.target.files[0];
            restrictLetterAttachmentSize(file, warningAlert, file => {
                const attachment = {
                    isError: false,
                    isLoading: true,
                    name: file.name,
                };
                const isDuplicateAttach = attachments.filter(attach => attach.name === file.name);
                if (isDuplicateAttach.length) {
                    warningAlert(LETTER_DUPLICATE_FILE);
                    return;
                }
                setAttachments(prev => [...prev, attachment]);
                onUploadFile(file)
                    .then(item => {
                    attachment.id = item.id;
                    attachment.attachment = item.files;
                    attachment.isLoading = false;
                })
                    .catch(() => {
                    attachment.isError = true;
                    errorAlert('Ошибка загрузки файла');
                })
                    .finally(() => {
                    attachment.isLoading = false;
                    setAttachments(prev => [...prev]);
                });
            });
        }
    }, [attachments, errorAlert, onUploadFile, setAttachments, warningAlert]);
    /** Начало удаления */
    const deleteAttachment = useCallback((attachment) => (e) => {
        e.stopPropagation();
        setSelectedAttachment(attachment);
        setOpen(true);
    }, []);
    /** Подтверждение удаления вложения/письма */
    const onConfirmDelete = useCallback(() => {
        if (selectedAttachment && selectedAttachment.id) {
            setAttachments(prev => prev.filter(item => item.id !== selectedAttachment.id));
        }
        else if (selectedAttachment && selectedAttachment.isError) {
            setAttachments(prev => prev.filter(item => item !== selectedAttachment));
        }
        else if (!selectedAttachment) {
            onDeleteLetter();
        }
        onClosePopup();
    }, [onClosePopup, onDeleteLetter, selectedAttachment, setAttachments]);
    /** Ошибка во время подписания */
    const signErrorMessage = useCallback(() => {
        setDataToSign(null);
        return 'Ошибка при подписании письма';
    }, []);
    const getDataToSign = useCallback(() => Promise.resolve(dataToSign ? [dataToSign] : []), [dataToSign]);
    /** Закрытие диалога подписания */
    const onCloseSignPopup = useCallback(() => {
        setDataToSign(null);
    }, []);
    /** Обработчик события формы */
    const onSubmit = data => {
        setIsLoading(true);
        data.attachments = data.attachments.filter(i => i.id);
        onSubmitExternal(data)
            .then(() => setIsLoading(false))
            .catch(() => errorAlert('Произошла ошибка'));
    };
    /** Создать обработчик на скачивание */
    const createDownloadHandler = ({ id }) => () => id ? onDownloadAttachment(id) : undefined;
    /** Удалить письмо */
    const deleteLetter = useCallback(() => setOpen(true), []);
    /** Начало подписания + отправка */
    const onSignAndSendBegin = useCallback(() => {
        setIsSend(true);
        setDataToSign(createSignData(getValues()));
    }, [createSignData, getValues]);
    /** Начало подписания */
    const onSignBegin = useCallback(() => {
        setIsSend(false);
        setDataToSign(createSignData(getValues()));
    }, [createSignData, getValues]);
    /** Завершение подписания */
    const onSignCompleteInternal = useCallback(([result]) => {
        switch (result.status) {
            case 'fulfilled':
                const { value: { withCertInfo, base64, signature }, } = result;
                onSignComplete(withCertInfo, base64, signature, isSend, getValues());
                setDataToSign(null);
                break;
            default:
                break;
        }
    }, [getValues, isSend, onSignComplete]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MultiSignPopup, { certificatePredicate: certificatePredicate, onCancel: onCloseSignPopup, getDataToSign: getDataToSign, getErrorMessage: signErrorMessage, onSignComplete: onSignCompleteInternal, open: !!dataToSign }),
        React.createElement("div", { className: stylesMain.contentWrapper },
            React.createElement(PageTitle, { title: letterRepliedTheme && letterRepliedCreateDate
                    ? `Ответ на письмо ${letterRepliedTheme} ${formatDate(letterRepliedCreateDate)}`
                    : 'Письмо в банк' },
                React.createElement("div", { className: stylesMain.statusContainer },
                    React.createElement("span", { className: styles.statusText }, statusText ?? 'Черновик'))),
            isLoading ? (React.createElement("div", { className: styles.loaderContainer },
                React.createElement(Preloader, null))) : (
            // todo разобраться позже
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            React.createElement("form", { ref: formRef, onSubmit: handleSubmit(onSubmit), className: styles.letter },
                React.createElement("div", { className: stylesMain.unionContainer },
                    React.createElement("div", { className: styles.selectContainer },
                        React.createElement(InputUI, { value: formatDate(date), disabled: true, title: '\u0414\u0430\u0442\u0430' }),
                        organizationsSelect,
                        React.createElement(Controller, { control: control, name: 'category', rules: {
                                required: 'Поле обязательно к заполнению',
                            }, render: ({ field: { onChange, value }, fieldState: { error } }) => (React.createElement(SelectUI, { disabled: isExistLetter, isRequired: true, isClearable: true, options: optionsSelect, onChange: newValue => onChange(newValue), value: value, title: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', getValue: getValueSelect, hasError: !!error, errorText: error?.message })) }))),
                isServiceLetter && (React.createElement("div", { className: styles.serviceContainer },
                    React.createElement(Controller, { control: control, name: 'serviceCode', rules: {
                            required: 'Поле обязательно к заполнению',
                        }, render: ({ field: { onChange, value }, fieldState: { error } }) => (React.createElement(SelectUI, { disabled: !isDraft, isRequired: true, isClearable: true, options: services, onChange: newValue => onChange(newValue), title: '\u0423\u0441\u043B\u0443\u0433\u0430', value: value, getValue: getValueSelect, hasError: !!error, errorText: error?.message })) }))),
                React.createElement("div", { className: styles.themeContainer },
                    React.createElement(InputUI, { disabled: !isDraft, isRequired: true, title: '\u0422\u0435\u043C\u0430', type: 'text', ...register('theme', {
                            required: 'Поле обязательно к заполнению',
                        }), errorText: errors.theme?.message, hasError: !!errors?.theme })),
                React.createElement(TextareaUI, { disabled: !isDraft, isRequired: true, title: '\u041F\u0438\u0441\u044C\u043C\u043E', ...register('body', {
                        required: 'Поле обязательно к заполнению',
                    }), errorText: errors.body?.message, hasError: !!errors?.body }),
                React.createElement("ul", { className: styles.attachmentList }, attachments.map((attachment, i) => (React.createElement("li", { key: attachment.id || i, onClick: createDownloadHandler(attachment), className: styles.attachment },
                    attachment.name,
                    isDraft && deleteAttachmentAccess && (React.createElement("button", { onClick: deleteAttachment(attachment), type: 'button', className: styles.deleteButton },
                        React.createElement("img", { className: styles.deleteButtonIcon, src: deleteIcon, alt: '\u0443\u0434\u0430\u043B\u0435\u043D\u0438\u0435' }))),
                    attachment.isLoading && (React.createElement("div", { className: styles.smallContainer },
                        React.createElement(SmallPreloader, null))),
                    isDraft && !attachment.isLoading && !attachment.isError && (React.createElement("div", { className: styles.smallContainer },
                        React.createElement("span", { className: cn(styles.icon, styles.iconSuccess) }, "\u2713"))),
                    isDraft && attachment.isError && (React.createElement("div", { className: styles.smallContainer },
                        React.createElement("span", { className: cn(styles.icon, styles.iconError) }, "!"))))))),
                isDraft && addAttachmentAccess && (React.createElement("label", { htmlFor: 'fileLoad' },
                    React.createElement("input", { key: keyInputFileLoad || '', onChange: onFileChoose, className: styles.hideInput, type: 'file', id: 'fileLoad', accept: '.pdf,.doc,.docx,.xls,.xlsx' }),
                    React.createElement("div", { className: styles.loadFile },
                        React.createElement("img", { className: styles.loadFileIcon, src: attachmentIcon, alt: '\u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0430' }),
                        "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0444\u0430\u0439\u043B")))))),
        React.createElement("div", { className: stylesMain.submitContainer },
            !isBaseLetter && isDraft && (React.createElement(React.Fragment, null,
                saveAndSendSignedAccess && (React.createElement(Tooltip, { isVisible: isAttachmentLoading || !isValid, tooltipText: isAttachmentLoading
                        ? 'Дождитесь загрузки файлов'
                        : 'Заполните все обязательные поля' },
                    React.createElement(RectButtonUI, { disabled: isLoading || isAttachmentLoading || !isValid, onClick: onSignAndSendBegin }, "\u041F\u043E\u0434\u043F\u0438\u0441\u0430\u0442\u044C \u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C"))),
                saveSignedAccess && (React.createElement(Tooltip, { isVisible: isAttachmentLoading || !isValid, tooltipText: isAttachmentLoading
                        ? 'Дождитесь загрузки файлов'
                        : 'Заполните все обязательные поля' },
                    React.createElement(RectButtonUI, { disabled: isLoading || isAttachmentLoading || !isValid, onClick: onSignBegin }, "\u041F\u043E\u0434\u043F\u0438\u0441\u0430\u0442\u044C"))))),
            isDraft && createAccess && (React.createElement(Tooltip, { isVisible: isAttachmentLoading || !isValid, tooltipText: isAttachmentLoading ? 'Дождитесь загрузки файлов' : 'Заполните все обязательные поля' },
                React.createElement(RectButtonUI, { disabled: isAttachmentLoading || !isValid || isLoading || !isDirty, onClick: submit }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))),
            isExistLetter && (React.createElement(React.Fragment, null,
                sendAccess && (React.createElement(RectButtonUI, { disabled: isLoading || isDirty, onClick: onSendLetter }, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C")),
                createAccess && (React.createElement(RectButtonUI, { disabled: isLoading, styleType: 'line', onClick: onCreateDuplicate }, "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0434\u0443\u0431\u043B\u0438\u043A\u0430\u0442")),
                onReply && (React.createElement(RectButtonUI, { styleType: 'line', onClick: onReply }, "\u041E\u0442\u0432\u0435\u0442\u0438\u0442\u044C")),
                deleteAccess && (React.createElement(RectButtonUI, { disabled: isLoading, styleType: 'line', onClick: deleteLetter }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))))),
        React.createElement(ConfirmDeletePopup, { open: open, onClose: onClosePopup, onSubmit: onConfirmDelete, title: selectedAttachment?.name ?? 'письмо' })));
}
