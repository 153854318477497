import React, { useCallback, useMemo } from 'react';
import { formatDate } from '../../utils/smallFunctions';
import { useAlert } from '../alert-context';
import { Popup } from '../popup';
import { GenericTable } from '../table';
import stylesMain from '../../utils/styles/main.module.scss';
const DateCell = (dateFrom) => formatDate(dateFrom, 'dd.MM.yyyy HH:mm:ss');
function EventCell(letterStatus, historyStatus, name) {
    const isEqual = historyStatus === letterStatus;
    return (React.createElement("div", { className: stylesMain.flexContainer },
        isEqual && React.createElement("span", { className: stylesMain.indicator }),
        React.createElement("p", { className: isEqual ? stylesMain.tableTextHeadSmall : stylesMain.tableTextBody }, name)));
}
const UserCell = (username) => React.createElement("p", { className: stylesMain.tableTextHeadSmall }, username);
/** Диалог историистатусов письма */
export function HistoryStatusPopup({ status, getHistoryItem, loadItems, ...rest }) {
    const { errorAlert } = useAlert();
    const getItemKey = useCallback((item) => getHistoryItem(item).dateFrom.valueOf(), [getHistoryItem]);
    const columns = useMemo(() => [
        {
            key: 1,
        },
        {
            key: 2,
            title: '↓ действовал с',
            cellContent: item => DateCell(getHistoryItem(item).dateFrom),
        },
        {
            key: 3,
            title: '↓ событие',
            cellContent: item => {
                const { status: historyStatus, name } = getHistoryItem(item);
                return EventCell(status, historyStatus, name);
            },
        },
        {
            key: 4,
            title: 'пользователь',
            cellContent: item => UserCell(getHistoryItem(item).username),
        },
        {
            key: 6,
        },
    ], [getHistoryItem, status]);
    const onLoadError = useCallback(() => errorAlert('Произошла ошибка'), [errorAlert]);
    return (React.createElement(Popup, { header: '\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u0441\u0442\u0430\u0442\u0443\u0441\u043E\u0432', ...rest },
        React.createElement(GenericTable, { getItemKey: getItemKey, columns: columns, onLoadError: onLoadError, getItems: loadItems, rowHoverTransition: false })));
}
