import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { BASE_PATH, LETTERS, PAYMENTS, PROFILE, routeMapper, STATEMENTS } from '../../routes';
import { fetchDictionaryServices } from '../../store/slice/appSlice';
import { useAppDispatch } from '../../store/store';
import { MainLayout } from '../../utils/general-code-import/components-import';
import { useAuthContext } from '../../utils/general-code-import/config-import';
import { PStatementsMapper, POrganizationsMapper, PPayDocumentsMapper, PLettersMapper, } from '../../utils/general-code-import/types-import';
import { sectionsInfo } from '../../utils/navigationOptions';
export const Layout = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        void dispatch(fetchDictionaryServices());
    }, [dispatch]);
    const { haveAccess, fullName, organizationName } = useAuthContext();
    return (React.createElement(MainLayout, { header: { profilePath: PROFILE, routeMapper, fullName, organizationName }, navigation: {
            basePath: BASE_PATH,
            /** Проверка прав доступа в счета */
            options: sectionsInfo.filter(({ route }) => (route !== BASE_PATH || haveAccess(POrganizationsMapper.ViewAll)) &&
                (route !== STATEMENTS || haveAccess(PStatementsMapper.RequestStatement)) &&
                (route !== PAYMENTS || haveAccess(PPayDocumentsMapper.ViewList)) &&
                (route !== LETTERS || haveAccess(PLettersMapper.Viewlist))),
        } },
        React.createElement(Outlet, null)));
};
