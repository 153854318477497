import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-main.css';
import cn from 'classnames';
import * as fns from 'date-fns';
import ru from 'date-fns/locale/ru';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import DatePicker, { registerLocale, } from 'react-datepicker';
import { useIMask } from 'react-imask';
import { RectButtonUI } from '../rect-button-ui';
import { formatRangeDate } from '../../utils/smallFunctions';
import { convertToEndOfDay, PeriodPresets } from './presets';
import styles from './styles.module.scss';
registerLocale('ru', ru);
/** список месяцев */
export const month = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];
const CustomHeader = ({ setPeriod, period, selected, }) => function CustomHeader({ decreaseMonth, increaseMonth, decreaseYear, increaseYear, monthDate }) {
    const handleMonth = () => {
        if (period !== 'month') {
            setPeriod('month');
        }
        else {
            setPeriod('day');
        }
    };
    const handleYear = () => {
        if (period !== 'year') {
            setPeriod('year');
        }
        else {
            setPeriod('day');
        }
    };
    return (React.createElement("div", { className: styles.customHeader },
        React.createElement("div", { className: cn(styles.arrow, styles.arrowLeft), onClick: period === 'year' ? decreaseYear : decreaseMonth }),
        period === 'day' && (React.createElement("span", { className: styles.title },
            React.createElement("span", { onClick: handleMonth },
                month[monthDate.getMonth()],
                " "),
            React.createElement("span", { className: styles.year, onClick: handleYear }, (selected ?? monthDate).getFullYear()))),
        React.createElement("div", { className: cn(styles.arrow, styles.arrowRight), onClick: period === 'year' ? increaseYear : increaseMonth })));
};
/**
 * Текстовый инпут для ввода даты
 * */
const DateInput = ({ max, min, selected, onChange, end = false }) => {
    const { ref, setTypedValue, setValue, typedValue } = useIMask({
        max: max ?? undefined,
        min: min ?? undefined,
        mask: Date,
        lazy: false,
    });
    /** Ручсное редактирование */
    const [editing, setEditing] = useState(false);
    /** Потеря фокуса */
    const onInputBlur = useCallback(() => setEditing(false), []);
    /** Установка фокуса */
    const onInputFocus = useCallback(() => setEditing(true), []);
    /** Синхронизация store и input */
    useLayoutEffect(() => {
        /** store -> input */
        if (!editing) {
            if (selected) {
                const diff = fns.differenceInSeconds(selected, typedValue);
                if (Number.isNaN(diff) || diff) {
                    setTypedValue(selected);
                }
            }
            else {
                setValue('');
            }
        }
        /** input -> store */
        if (typedValue && editing) {
            const diff = fns.differenceInSeconds(selected ?? new Date(), end ? convertToEndOfDay(typedValue) : typedValue);
            if (Number.isNaN(diff) || diff) {
                onChange(typedValue);
            }
        }
    }, [editing, end, onChange, selected, setTypedValue, setValue, typedValue]);
    return React.createElement("input", { onBlur: onInputBlur, onFocus: onInputFocus, ref: ref, type: 'text' });
};
/**
 * Обертка над react-datepicker
 * */
const InlineDatePickerWrapper = ({ selected, onChange, minDate, maxDate, end = false, ...rest }) => {
    /** Выбор периода (день, месяц, год) */
    const [period, setPeriod] = useState('day');
    /** Обработка изменения */
    const handleChange = useCallback((date) => {
        if (period !== 'day') {
            setPeriod('day');
        }
        onChange(date);
    }, [onChange, period]);
    /** Очистка */
    const onClear = useCallback(() => {
        handleChange(null);
    }, [handleChange]);
    /** При изменении ключа react перестроит компонент, воючая используемые хуки */
    const invalidateKey = (minDate?.getDate() ?? 0) + (maxDate?.getDate() ?? 0);
    return (React.createElement("div", { className: styles.datePickerContainer },
        React.createElement("div", { className: styles.inputWrapper },
            React.createElement(DateInput, { key: invalidateKey, onChange: handleChange, min: minDate, max: maxDate, selected: selected, end: end }),
            React.createElement("span", { onClick: onClear })),
        React.createElement(DatePicker, { ...rest, minDate: minDate, maxDate: maxDate, disabledKeyboardNavigation: true, selected: selected, openToDate: selected ?? new Date(), onChange: handleChange, renderCustomHeader: CustomHeader({ period, setPeriod, selected }), locale: 'ru', fixedHeight: true, calendarClassName: 'dp', inline: true, showYearPicker: period === 'year', showMonthYearPicker: period === 'month', showFullMonthYearPicker: true })));
};
/**
 * Выбор пресетов
 * */
const PresetOptionsSelect = ({ onSelect }) => {
    const [current, setCurrent] = useState(null);
    return (React.createElement("ul", null,
        !!current && (React.createElement("li", { className: styles.backItem, onClick: () => setCurrent(null) },
            React.createElement("span", { className: styles.backArrow }))),
        !current
            ? PeriodPresets.map(({ label, items }) => (React.createElement("li", { onClick: () => setCurrent(items), key: label }, label)))
            : current.map(({ label, period }) => (React.createElement("li", { onClick: () => onSelect(period), key: label }, label)))));
};
/**
 * Выбор диапазона дат
 * */
export const RangePicker = ({ onChange, value }) => {
    /** Диапазон */
    const [range, setRange] = useState(value ?? [null, null]);
    const [start, end] = range;
    /** Установка начала диапазона */
    const setStart = useCallback((start) => {
        setRange(([, end]) => [start, end]);
    }, []);
    /** Установка конца диапазона */
    const setEnd = useCallback((end) => {
        setRange(([start]) => [start, convertToEndOfDay(end)]);
    }, []);
    const onConfirm = useCallback(() => {
        const [start, end] = range;
        if (start && end) {
            onChange([start, end]);
        }
    }, [onChange, range]);
    return (React.createElement("div", { className: styles.rangeContainer },
        React.createElement("div", { className: styles.options },
            React.createElement(PresetOptionsSelect, { onSelect: setRange })),
        React.createElement(InlineDatePickerWrapper, { maxDate: range.at(1), selected: range.at(0), onChange: setStart }),
        React.createElement(InlineDatePickerWrapper, { end: true, minDate: range.at(0), selected: range.at(1), onChange: setEnd }),
        React.createElement(RectButtonUI, { onClick: onConfirm, disabled: !range.every(date => !!date), className: styles.confirm },
            "\u0412\u044B\u0431\u0440\u0430\u0442\u044C ",
            start && end ? formatRangeDate(start, end) : '')));
};
