import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ConfirmDeletePopup, DropdownButton, Tooltip, useFormSubmit, } from '../../../submodules/inkh-core-frontend/src';
import { getValueSelect } from '../../../submodules/inkh-core-frontend/src/utils/smallFunctions';
import { OrderStatuses } from '../../utils/api/apiModels';
import { paymentTemplateApi } from '../../utils/api/generalApi';
import { StatusSelectOptionPaymentMapper } from '../../utils/enum-mappers';
import { InputUI, MultiSignPopup, PageTitle, Preloader, RectButtonUI, SelectUI, TextareaUI, } from '../../utils/general-code-import/components-import';
import { formatDate, useAlert, useAuthContext, } from '../../utils/general-code-import/config-import';
import { stylesMain } from '../../utils/general-code-import/styles-import';
import { PayDocNames, PPayDocumentsMapper, } from '../../utils/general-code-import/types-import';
import { CreateTemplatePopup } from '../payment-template/create-template-popup';
import { SelectTemplatePopup } from '../payment-template/select-template-popup';
import { OptionsValuePayment } from '../payment/types';
import styles from './payment-form.module.scss';
/** обработчик валидации поля даты налогового документа */
export const validateTaxDocumentDate = (value) => {
    /** Регулярное выражение на формат даты дд.мм.гггг */
    const reDate = /^([0]?[1-9]|[1-2][0-9]|[3][0-1]).([0]?[1-9]|1[0-2]).([1-2])[0-9]{3}$/;
    /** Регулярное выражение на значение 0 */
    const reZero = /^0$/g;
    const isValid = String(value).match(reDate) ||
        (String(value).match(reZero) || []).length === 1 ||
        String(value).length === 0 ||
        value === null;
    if (isValid)
        return isValid;
    return 'введите текст в формате дд.мм.гггг или цифру 0';
};
/** обработчик валидации поля УИН */
export const validateUIN = (value) => {
    /** Регулярное выражение на формат цифрового кода */
    const reCode = /^\d+$/g;
    /** Регулярное выражение на значение 0 */
    const reZero = /^0$/g;
    const isValid = (String(value).match(reCode) || []).length >= 1 ||
        (String(value).match(reZero) || []).length === 1;
    if (isValid)
        return isValid;
    return 'введите цифровой код или цифру 0';
};
/** Варианты очередности платежа */
const PaymentOrders = [1, 2, 3, 4, 5].map(value => ({
    value,
    label: value.toString(),
}));
/** Возможные статусы плательщика */
const PayerStatuses = ['01', '02', '08', '24'].map(value => ({
    value,
    label: value,
}));
/** Основания платежа */
const TaxReasons = ['ТП', 'ЗД'].map(value => ({
    value,
    label: value,
}));
/** Очистка объекта от вспомогательных полей */
const clearPaymentFromHelpers = ({ payerBikHelper, payerBranchNameHelper, paymentOrderHelper, dateHelper, payerAccountHelper, payerStatusHelper, payeeBankHelper, taxReasonHelper, codeHelper, payerBranchCorrespondingAccountHelper, ...rest }) => ({
    ...rest,
    recipientClient: {},
    recipientClientAccount: {},
});
/**
 * Форма платежа
 * */
export const PaymentForm = ({ initialValues = {}, payment, banks, accounts, isDraft = false, isDuplicate, api: { onSubmit, createDuplicatePayment, onDeletePayment, onSendPayment, signPayment, signAndSendPayment, creatSignPayment, createSignAndSendPayment, }, }) => {
    /** Опции для работы с react-hook-form */
    const { getValues, handleSubmit, register, control, setValue, resetField, formState: { errors, isValid }, } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...initialValues,
            dateHelper: formatDate(initialValues?.date && !isDuplicate ? initialValues?.date : new Date()),
            payDocName: PayDocNames.PaymentOrder,
            uinCode: String(0),
        },
    });
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** Управление диалогом создания шаблона платежа */
    const [createTemplatePopupOpen, setCreateTemplatePopupOpen] = useState(false);
    const closeCreateTemplatePopup = useCallback(() => setCreateTemplatePopupOpen(false), []);
    const openCreateTemplatePopup = useCallback(() => setCreateTemplatePopupOpen(true), []);
    /** Управление выбором шаблона платежа */
    const [selectTemplatePopupOpen, setSelectTemplatePopupOpen] = useState(false);
    const closeSelectTemplatePopup = useCallback(() => setSelectTemplatePopupOpen(false), []);
    const openSelectTemplatePopup = useCallback(() => setSelectTemplatePopupOpen(true), []);
    /** Вложенные кнопки "Шаблоны" */
    const templateDropDowns = useMemo(() => [
        { title: 'Создать шаблон', onClick: openCreateTemplatePopup, disabled: !isValid },
        { title: 'Выбрать шаблон', onClick: openSelectTemplatePopup, disabled: !isDraft },
    ], [isDraft, isValid, openCreateTemplatePopup, openSelectTemplatePopup]);
    /** Обработчик изменения счета */
    const onChangeAccount = useCallback((account) => {
        if (account) {
            const { branchCorrespondingAccount, branchName, branchBic, bankAccountCftId, accountNumber, } = account;
            // todo paste account name
            setValue('payerAccount.name', 'test account name');
            setValue('payerAccount.id', bankAccountCftId);
            setValue('payerAccount.number', accountNumber);
            setValue('payerBikHelper', branchBic);
            setValue('payerBranchCorrespondingAccountHelper', branchCorrespondingAccount);
            setValue('payerBranchNameHelper', branchName);
        }
        else {
            resetField('payerAccount.id');
            resetField('payerAccount.number');
            resetField('payerBikHelper');
            resetField('payerBranchCorrespondingAccountHelper');
            resetField('payerBranchNameHelper');
        }
    }, [resetField, setValue]);
    /** Опции выбора счетов */
    const accountsOptions = useMemo(() => accounts.map(item => ({ value: item, label: item.accountNumber })), [accounts]);
    /** Опции выбора банков */
    const banksOptions = useMemo(() => banks.map(item => ({ value: item, label: item.combinedName })), [banks]);
    const { errorAlert, successAlert } = useAlert();
    const { haveAccess, userCertificateThumbprints = [] } = useAuthContext();
    /** Данные из пользователя */
    const { organizationInn, organizationKpp, organizationName, id, organizationCftId } = useAuthContext();
    /** Смена банка */
    const onChangePayeeBank = useCallback((bank) => {
        if (bank) {
            const { bic, correspondingAccount, cftId, name } = bank;
            setValue('recipientStranger.bankBic', bic);
            setValue('recipientStranger.bankCorrespondingAccount', correspondingAccount);
            setValue('recipientStranger.bankCftId', cftId);
            setValue('recipientStranger.bankName', name);
        }
        else {
            resetField('recipientStranger.bankBic');
            resetField('recipientStranger.bankCorrespondingAccount');
            resetField('recipientStranger.bankCftId');
            resetField('recipientStranger.bankName');
        }
    }, [resetField, setValue]);
    /** Установка организации клиента и id */
    useEffect(() => {
        /** При создании устанавливаются данные из UserInfo */
        if (!payment && !isDuplicate) {
            setValue('payerClient.name', organizationName);
            setValue('payerClient.id', organizationCftId);
            setValue('payerClient.inn', organizationInn);
            setValue('payerClient.kpp', organizationKpp);
        }
        else {
            /** При существующем платеже, вся информация берется из него */
            const { payerAccount, priority, budgetRequisites, recipientStranger } = initialValues;
            /** Информация  о счете */
            if (payerAccount) {
                const { id } = payerAccount;
                const accountOption = accountsOptions.find(({ value: { bankAccountCftId } }) => bankAccountCftId === id);
                if (accountOption) {
                    setValue('payerAccountHelper', accountOption);
                    onChangeAccount(accountOption.value);
                }
            }
            /** Очередность платежа */
            if (priority !== undefined) {
                const orderOption = PaymentOrders.find(({ value }) => value === priority);
                if (orderOption)
                    setValue('paymentOrderHelper', orderOption);
            }
            /** Налоговые данные */
            if (budgetRequisites) {
                const { taxPayerStatus, taxBasis } = budgetRequisites;
                const statusOption = PayerStatuses.find(({ value }) => value === taxPayerStatus);
                if (statusOption) {
                    setValue('payerStatusHelper', statusOption);
                }
                const reasonOption = TaxReasons.find(({ value }) => value === taxBasis);
                if (reasonOption) {
                    setValue('taxReasonHelper', reasonOption);
                }
            }
            /** Данные получателя */
            if (recipientStranger) {
                const { bankBic } = recipientStranger;
                const bankOption = banksOptions.find(({ value: { bic } }) => bic === bankBic);
                if (bankOption) {
                    onChangePayeeBank(bankOption.value);
                    setValue('payeeBankHelper', bankOption);
                }
            }
        }
    }, [
        accountsOptions,
        banksOptions,
        id,
        initialValues,
        isDuplicate,
        onChangeAccount,
        onChangePayeeBank,
        organizationCftId,
        organizationInn,
        organizationKpp,
        organizationName,
        payment,
        setValue,
    ]);
    /** Данные для подписи */
    const [dataToSign, setDataToSign] = useState(null);
    /** Состояние открытия диалога удаления */
    const [deletePopupOpened, setDeletePopupOpened] = useState(false);
    /** Закрытие диалога */
    const closeDeletePopup = useCallback(() => setDeletePopupOpened(false), []);
    /** Открытие диалога */
    const openDeletePopup = useCallback(() => setDeletePopupOpened(true), []);
    /** Обработчик подписания существующей платежки */
    const onSignPayment = useCallback(() => {
        if (payment) {
            setDataToSign({
                payment,
                options: OptionsValuePayment.SIGN,
            });
        }
    }, [payment]);
    /** Обработчик подписания и отправки существующей платежки */
    const onSignAndSendPayment = useCallback(() => {
        if (payment) {
            setDataToSign({
                payment,
                options: OptionsValuePayment.SIGN_AND_SEND,
            });
        }
    }, [payment]);
    /** Обработчик подписания новой платежки */
    const onCreateSignPayment = useCallback(() => {
        setDataToSign({
            payment: { ...clearPaymentFromHelpers(getValues()) },
            options: OptionsValuePayment.CREATE_SIGN,
        });
    }, [getValues]);
    /** Обработчик подписания и отправки новой платежки */
    const onCreateSignAndSendPayment = useCallback(() => {
        setDataToSign({
            payment: { ...clearPaymentFromHelpers(getValues()) },
            options: OptionsValuePayment.CREATE_SIGN_AND_SEND,
        });
    }, [getValues]);
    /** Ошибка во время подписания */
    const signErrorMessage = useCallback(() => {
        setDataToSign(null);
        return 'Ошибка при подписании';
    }, []);
    /** Закрытие диалога подписания */
    const onCloseSignPopup = useCallback(() => {
        setDataToSign(null);
    }, []);
    /** Управление формой */
    const [formRef, submit] = useFormSubmit();
    /** Подписание завершилась успешно */
    const onSignComplete = useCallback(([result]) => {
        switch (result.status) {
            case 'fulfilled':
                const { value: { withCertInfo, base64, signature }, } = result;
                switch (dataToSign?.options) {
                    case OptionsValuePayment.SIGN:
                        setLoading(true);
                        signPayment(withCertInfo, base64, signature, dataToSign.payment.id).finally(() => setLoading(false));
                        onCloseSignPopup();
                        break;
                    case OptionsValuePayment.SIGN_AND_SEND:
                        setLoading(true);
                        signAndSendPayment(withCertInfo, base64, signature, dataToSign.payment.id).finally(() => setLoading(false));
                        onCloseSignPopup();
                        break;
                    case OptionsValuePayment.CREATE_SIGN:
                        setLoading(true);
                        creatSignPayment(withCertInfo, base64, signature, dataToSign.payment).finally(() => setLoading(false));
                        onCloseSignPopup();
                        break;
                    case OptionsValuePayment.CREATE_SIGN_AND_SEND:
                        setLoading(true);
                        createSignAndSendPayment(withCertInfo, base64, signature, dataToSign.payment).finally(() => setLoading(false));
                        onCloseSignPopup();
                        break;
                    default:
                        errorAlert('Нет таких значений');
                }
                break;
            default:
                break;
        }
    }, [
        creatSignPayment,
        createSignAndSendPayment,
        dataToSign?.options,
        dataToSign?.payment,
        errorAlert,
        onCloseSignPopup,
        signAndSendPayment,
        signPayment,
    ]);
    /** Создание шаблона из платежа */
    const onCreateTemplate = useCallback((templateName, shared) => {
        setLoading(true);
        setCreateTemplatePopupOpen(false);
        paymentTemplateApi
            .createTemplate({
            paymentTemplate: {
                ...clearPaymentFromHelpers(getValues()),
                templateName,
                shared,
            },
        })
            .then(() => successAlert(`Шаблон платежа "${templateName}" успешно создан`))
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => setLoading(false));
    }, [errorAlert, getValues, successAlert]);
    /** Выбор шаблона */
    const onSelectTemplate = useCallback(({ id }) => {
        setLoading(true);
        paymentTemplateApi
            .getTemplate(id)
            .then(({ amount, purpose, recipientStranger, budgetRequisites, payerAccount }) => {
            /** Сумма платежа */
            if (amount !== undefined)
                setValue('amount', amount);
            /** Назначение платежа */
            if (purpose)
                setValue('purpose', purpose);
            /** Данные получателя */
            if (recipientStranger) {
                const { inn, bankCftId, account, organization, kpp } = recipientStranger;
                /** Поиск среди доступных банков */
                const bankOption = banksOptions.find(({ value: { cftId } }) => bankCftId === cftId);
                if (bankOption) {
                    onChangePayeeBank(bankOption.value);
                    setValue('payeeBankHelper', bankOption);
                }
                /** ИНН */
                if (inn)
                    setValue('recipientStranger.inn', inn);
                /** Счет */
                if (account)
                    setValue('recipientStranger.account', account);
                /** Наименование */
                if (organization)
                    setValue('recipientStranger.organization', organization);
                /** КПП */
                if (kpp)
                    setValue('recipientStranger.kpp', kpp);
            }
            /** Данные отправителя */
            if (payerAccount) {
                const { id } = payerAccount;
                /** Поиск среди доступных счетов */
                const accountOption = accountsOptions.find(({ value: { bankAccountCftId } }) => bankAccountCftId === id);
                if (accountOption) {
                    onChangeAccount(accountOption.value);
                    setValue('payerAccountHelper', accountOption);
                }
            }
            /** Налоговые данные */
            if (budgetRequisites) {
                const { taxPaymentType, taxBasis, taxPayerStatus, taxPeriod, taxDocumentDate, taxDocumentNumber, okato, kbk, } = budgetRequisites;
                if (taxPaymentType)
                    setValue('budgetRequisites.taxPaymentType', taxPaymentType);
                /** Основание налога  */
                if (taxBasis) {
                    const taxOption = TaxReasons.find(({ value }) => value === taxBasis);
                    if (taxOption) {
                        setValue('taxReasonHelper', taxOption);
                        setValue('budgetRequisites.taxBasis', taxBasis);
                    }
                }
                /** Статус плательщика */
                if (taxPayerStatus) {
                    const statusOption = PayerStatuses.find(({ value }) => value === taxPayerStatus);
                    if (statusOption) {
                        setValue('payerStatusHelper', statusOption);
                        setValue('budgetRequisites.taxPayerStatus', taxPayerStatus);
                    }
                }
                if (taxPeriod)
                    setValue('budgetRequisites.taxPeriod', taxPeriod);
                if (taxDocumentDate)
                    setValue('budgetRequisites.taxDocumentDate', taxDocumentDate);
                if (taxDocumentNumber)
                    setValue('budgetRequisites.taxDocumentNumber', taxDocumentNumber);
                if (okato)
                    setValue('budgetRequisites.okato', okato);
                if (kbk)
                    setValue('budgetRequisites.kbk', kbk);
            }
        })
            .catch(() => errorAlert('Ошибка при загрузке шаблона'))
            .finally(() => setLoading(false));
    }, [accountsOptions, banksOptions, errorAlert, onChangeAccount, onChangePayeeBank, setValue]);
    /** Действия для существующего платежа */
    const existPaymentActions = useMemo(() => {
        const result = [];
        if (payment) {
            if (haveAccess(PPayDocumentsMapper.Edit) && isDraft) {
                result.push({
                    onClick: submit,
                    title: 'Сохранить',
                });
            }
            if (payment.status === OrderStatuses.Sent || payment.status === OrderStatuses.New) {
                if (haveAccess(PPayDocumentsMapper.SaveSendSigned)) {
                    result.push({
                        onClick: onSignAndSendPayment,
                        title: 'Подписать и отправить',
                    });
                }
                if (haveAccess(PPayDocumentsMapper.SaveSignedOrder)) {
                    result.push({
                        onClick: onSignPayment,
                        title: 'Подписать',
                    });
                }
            }
            if (haveAccess(PPayDocumentsMapper.SendSigned) && payment.status === OrderStatuses.Signed) {
                result.push({
                    onClick: onSendPayment,
                    title: 'Отправить',
                });
            }
        }
        return result;
    }, [haveAccess, isDraft, onSendPayment, onSignAndSendPayment, onSignPayment, payment, submit]);
    /** Действия для несозданных платежей */
    const paymentActions = useMemo(() => {
        const result = [];
        if (haveAccess(PPayDocumentsMapper.Create)) {
            result.push({
                onClick: submit,
                title: 'Создать',
            });
            if (haveAccess(PPayDocumentsMapper.CreateAndSign)) {
                result.push({
                    onClick: onCreateSignPayment,
                    title: 'Создать и подписать',
                });
            }
            if (haveAccess(PPayDocumentsMapper.CreateSend)) {
                result.push({
                    onClick: onCreateSignAndSendPayment,
                    title: 'Создать подписать и отправить',
                });
            }
        }
        return result;
    }, [haveAccess, onCreateSignAndSendPayment, onCreateSignPayment, submit]);
    /** Фильтрация доступных сертификатов */
    const certificatePredicate = useCallback((cert) => userCertificateThumbprints.includes(cert.thumbprint), [userCertificateThumbprints]);
    /** Подтверждение формы */
    const onSubmitInternal = useCallback((item) => {
        setLoading(true);
        onSubmit(clearPaymentFromHelpers(item)).finally(() => setLoading(false));
    }, [onSubmit]);
    const getDataToSign = useCallback(() => Promise.resolve(dataToSign ? [dataToSign.payment] : []), [dataToSign]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MultiSignPopup, { onSignComplete: onSignComplete, getDataToSign: getDataToSign, getErrorMessage: signErrorMessage, onCancel: onCloseSignPopup, certificatePredicate: certificatePredicate, open: !!dataToSign }),
        React.createElement("div", { className: stylesMain.contentWrapper },
            React.createElement(PageTitle, { title: isDuplicate ? 'Дубликат платежного поручения' : 'Платежное поручение' }, payment && (React.createElement("div", { className: stylesMain.statusContainer },
                React.createElement("span", { className: styles.statusText }, StatusSelectOptionPaymentMapper[payment.status].label)))),
            loading ? (React.createElement(Preloader, null)) : (
            // todo разобраться позже
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            React.createElement("form", { ref: formRef, onSubmit: handleSubmit(onSubmitInternal), className: styles.payment },
                React.createElement("div", { className: styles.section },
                    React.createElement("p", { className: styles.sectionText }, "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442"),
                    React.createElement("div", { className: styles.sectionInputs },
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { isRequired: true, disabled: !isDraft, title: '\u041D\u043E\u043C\u0435\u0440 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430', ...register('number', { required: 'Поле обязательно к заполнению' }), errorText: errors.number?.message, hasError: !!errors?.number }),
                            React.createElement(InputUI, { disabled: true, title: '\u0414\u0430\u0442\u0430 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430', ...register('dateHelper') })))),
                React.createElement("div", { className: styles.section },
                    React.createElement("p", { className: styles.sectionText }, "\u041F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A"),
                    React.createElement("div", { className: styles.sectionInputs },
                        React.createElement(InputUI, { disabled: true, title: '\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430', ...register('payerClient.name') }),
                        React.createElement(Controller, { control: control, name: 'payerAccountHelper', render: ({ field: { value, onChange } }) => (React.createElement(SelectUI, { getValue: i => i.value.accountNumber, title: '\u041D\u043E\u043C\u0435\u0440 \u0441\u0447\u0435\u0442\u0430 \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430', isClearable: true, disabled: !isDraft, value: value, onChange: option => {
                                    onChange(option);
                                    onChangeAccount(option?.value ?? null);
                                }, options: accountsOptions })) }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: true, ...register('payerClient.kpp'), title: '\u041A\u041F\u041F \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430' }),
                            React.createElement(InputUI, { disabled: true, ...register('payerClient.inn'), title: '\u0418\u041D\u041D \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430' })),
                        React.createElement(InputUI, { disabled: true, title: '\u0411\u0430\u043D\u043A \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430', ...register('payerBranchNameHelper') }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: true, ...register('payerBranchCorrespondingAccountHelper'), title: '\u041A\u043E\u0440\u0440\u0435\u0441\u043F\u043E\u043D\u0434\u0435\u043D\u0442\u0441\u043A\u0438\u0439 \u0441\u0447\u0435\u0442 \u0431\u0430\u043D\u043A\u0430-\u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430' })))),
                React.createElement("div", { className: styles.section },
                    React.createElement("p", { className: styles.sectionText }, "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C"),
                    React.createElement("div", { className: styles.sectionInputs },
                        React.createElement(InputUI, { disabled: !isDraft, title: '\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', ...register('recipientStranger.organization') }),
                        React.createElement(InputUI, { disabled: !isDraft, title: '\u041D\u043E\u043C\u0435\u0440 \u0441\u0447\u0435\u0442\u0430 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', ...register('recipientStranger.account') }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u041A\u041F\u041F \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', ...register('recipientStranger.kpp') }),
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u0418\u041D\u041D \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', ...register('recipientStranger.inn') })),
                        React.createElement(Controller, { control: control, name: 'payeeBankHelper', render: ({ field: { value, onChange } }) => (React.createElement(SelectUI, { title: '\u0411\u0430\u043D\u043A \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', disabled: !isDraft, onChange: option => {
                                    onChange(option);
                                    onChangePayeeBank(option?.value ?? null);
                                }, options: banksOptions, isClearable: true, value: value, getValue: i => i.value.id })) }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: true, title: '\u041A\u043E\u0440\u0440\u0435\u0441\u043F\u043E\u043D\u0434\u0435\u043D\u0442\u0441\u043A\u0438\u0439 \u0441\u0447\u0435\u0442 \u0431\u0430\u043D\u043A\u0430-\u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F', ...register('recipientStranger.bankCorrespondingAccount') })))),
                React.createElement("div", { className: styles.section },
                    React.createElement("p", { className: styles.sectionText }, "\u041F\u043B\u0430\u0442\u0435\u0436"),
                    React.createElement("div", { className: styles.sectionInputs },
                        React.createElement(InputUI, { isRequired: true, disabled: !isDraft, title: '\u0421\u0443\u043C\u043C\u0430 \u043F\u043B\u0430\u0442\u0435\u0436\u0430', ...register('amount', { required: 'Поле обязательно к заполнению' }) }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(Controller, { control: control, name: 'paymentOrderHelper', render: ({ field: { value, onChange } }) => (React.createElement(SelectUI, { options: PaymentOrders, getValue: getValueSelect, disabled: !isDraft, isClearable: true, value: value, onChange: value => {
                                        onChange(value);
                                        if (value) {
                                            setValue('priority', value.value);
                                        }
                                        else {
                                            resetField('priority');
                                        }
                                    }, title: '\u041E\u0447\u0435\u0440\u0435\u0434\u043D\u043E\u0441\u0442\u044C \u043F\u043B\u0430\u0442\u0435\u0436\u0430' })) }),
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u041A\u043E\u0434 \u043F\u043B\u0430\u0442\u0435\u0436\u0430', ...register('codeHelper') })),
                        React.createElement(TextareaUI, { disabled: !isDraft, title: '\u041D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043B\u0430\u0442\u0435\u0436\u0430', ...register('purpose') }))),
                React.createElement("div", { className: styles.section },
                    React.createElement("p", { className: styles.sectionText }, "\u041D\u0430\u043B\u043E\u0433\u043E\u0432\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435"),
                    React.createElement("div", { className: styles.sectionInputs },
                        React.createElement(Controller, { control: control, name: 'payerStatusHelper', render: ({ field: { onChange, value } }) => (React.createElement(SelectUI, { options: PayerStatuses, isClearable: true, disabled: !isDraft, value: value, getValue: i => i.value, onChange: value => {
                                    onChange(value);
                                    if (value) {
                                        setValue('budgetRequisites.taxPayerStatus', value.value);
                                    }
                                    else {
                                        resetField('budgetRequisites.taxPayerStatus');
                                    }
                                }, title: '\u0421\u0442\u0430\u0442\u0443\u0441 \u043F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A\u0430' })) }),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u0411\u044E\u0434\u0436\u0435\u0442\u043D\u044B\u0435 \u0440\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B \u041A\u0411\u041A', ...register('budgetRequisites.kbk') }),
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u0411\u044E\u0434\u0436\u0435\u0442\u043D\u044B\u0435 \u0440\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B \u041E\u041A\u0422\u041C\u041E', ...register('budgetRequisites.okato') })),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(Controller, { control: control, name: 'taxReasonHelper', render: ({ field: { value, onChange } }) => (React.createElement(SelectUI, { disabled: !isDraft, isClearable: true, title: '\u041E\u0441\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u0434\u043B\u044F \u0443\u043F\u043B\u0430\u0442\u044B \u043D\u0430\u043B\u043E\u0433\u0430', value: value, onChange: value => {
                                        onChange(value);
                                        if (value) {
                                            setValue('budgetRequisites.taxBasis', value.value);
                                        }
                                        else {
                                            resetField('budgetRequisites.taxBasis');
                                        }
                                    }, options: TaxReasons, getValue: i => i.value })) }),
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u041D\u0430\u043B\u043E\u0433\u043E\u0432\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434', ...register('budgetRequisites.taxPeriod') })),
                        React.createElement("div", { className: stylesMain.unionContainer },
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u041D\u043E\u043C\u0435\u0440 \u043D\u0430\u043B\u043E\u0433\u043E\u0432\u043E\u0433\u043E \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430', ...register('budgetRequisites.taxDocumentNumber') }),
                            React.createElement(InputUI, { disabled: !isDraft, title: '\u0414\u0430\u0442\u0430 \u043D\u0430\u043B\u043E\u0433\u043E\u0432\u043E\u0433\u043E \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430', ...register('budgetRequisites.taxDocumentDate', {
                                    validate: value => validateTaxDocumentDate(value),
                                }), hasError: !!errors.budgetRequisites?.taxDocumentDate, errorText: errors.budgetRequisites?.taxDocumentDate?.message })),
                        React.createElement(InputUI, { disabled: !isDraft, title: '\u0422\u0438\u043F \u043D\u0430\u043B\u043E\u0433\u043E\u0432\u043E\u0433\u043E \u043F\u043B\u0430\u0442\u0435\u0436\u0430', ...register('budgetRequisites.taxPaymentType') }),
                        React.createElement(InputUI, { disabled: !isDraft, title: '\u0423\u0418\u041D', ...register('uinCode', {
                                validate: value => validateUIN(value),
                            }), hasError: !!errors.uinCode, errorText: errors.uinCode?.message })))))),
        !loading && (React.createElement("div", { className: stylesMain.submitContainer },
            React.createElement(DropdownButton, { position: 'top', dropDownItems: templateDropDowns }, "\u0428\u0430\u0431\u043B\u043E\u043D\u044B"),
            React.createElement(CreateTemplatePopup, { open: createTemplatePopupOpen, onCancel: closeCreateTemplatePopup, onOk: onCreateTemplate }),
            React.createElement(SelectTemplatePopup, { onSelectTemplate: onSelectTemplate, onCancel: closeSelectTemplatePopup, open: selectTemplatePopupOpen }),
            (existPaymentActions.length > 0 || paymentActions.length > 0) && (React.createElement(Tooltip, { center: true, isVisible: !isValid, tooltipText: '\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u0432\u0441\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u043F\u043E\u043B\u044F \u0438 \u0443\u0441\u0442\u0440\u0430\u043D\u0438\u0442\u0435 \u043E\u0448\u0438\u0431\u043A\u0438' },
                React.createElement(DropdownButton, { position: 'top', dropDownItems: payment ? existPaymentActions : paymentActions, disabled: !isValid }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F"))),
            payment && (React.createElement(React.Fragment, null,
                React.createElement(RectButtonUI, { styleType: 'line', onClick: createDuplicatePayment(payment) }, "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0434\u0443\u0431\u043B\u0438\u043A\u0430\u0442"),
                haveAccess(PPayDocumentsMapper.Delete) && (React.createElement(RectButtonUI, { styleType: 'line', onClick: openDeletePopup }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")))))),
        React.createElement(ConfirmDeletePopup, { open: deletePopupOpened, onClose: closeDeletePopup, onSubmit: onDeletePayment, title: '\u043F\u043B\u0430\u0442\u0435\u0436' })));
};
