import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableToolbar } from '../../../submodules/inkh-core-frontend/src';
import { AccountsStatuses } from '../../../submodules/inkh-principal/src/Inkh.Principal.Api.Client.TypeScript/Models/AccountsStatuses.Generated';
import { organizationApi } from '../../utils/api/generalApi';
import { GenericTable, PageTitle, Preloader, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, } from '../../utils/general-code-import/components-import';
import { getSum, toFormatMoney, useAlert } from '../../utils/general-code-import/config-import';
import { arrowDownIcon, arrowUpIcon } from '../../utils/general-code-import/images-import';
import { stylesMain, tableStyles } from '../../utils/general-code-import/styles-import';
import { parametersTabsAccounts } from '../../utils/tabOptions';
import styles from './accounts.module.scss';
/** Группировка. todo вынести в core */
function groupBy(items, getValue) {
    const resultMap = new Map();
    items.forEach(item => {
        const value = getValue(item);
        const array = [...(resultMap.get(value) ?? []), item];
        resultMap.set(value, array);
    });
    return resultMap;
}
/**
 * Фильтрация коллекции по тексту
 * */
function filterItemsByText(items, keys, searchValue) {
    return items.filter(item => {
        const commonString = keys
            .map(key => String(item[key]))
            .join()
            .toLowerCase();
        return commonString.includes(searchValue.toLowerCase());
    });
}
const SubCell = ({ branchName }) => (React.createElement("p", { className: stylesMain.tableTextBody }, branchName));
const NameNumberCell = ({ bankAccountNumber, bankAccountName }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, bankAccountName),
    React.createElement("p", { className: stylesMain.tableTextBody }, bankAccountNumber)));
const BalanceCell = ({ balance, currencySymbol }) => (React.createElement("p", { className: cn(styles.accountRemainder, {
        [styles.positive]: balance > 0,
        [styles.negative]: balance < 0,
    }) },
    toFormatMoney(balance),
    " ",
    React.createElement("span", { className: stylesMain.tableTextBody }, currencySymbol)));
const getItemKey = (item) => item.bankAccountNumber;
/**
 * Список счетов
 * Основная страница "Счета"
 * */
export const Accounts = () => {
    const { errorAlert } = useAlert();
    /** Общий список счетов */
    const [accounts, setAccounts] = useState([]);
    /** Отфильтрованный список счетов */
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    /** Текущее значение раскрытых групп счетов */
    const [rowOpen, setRowOpen] = useState({});
    /** Текущее значение активного таба */
    const [activeTab, setActiveTab] = useState(parametersTabsAccounts[0]);
    /** Счета с группировкой */
    const [groupedAccounts, setGroupedAccounts] = useState({});
    /** Состояние группировки */
    const [isGroup, setIsGroup] = useState(true);
    /** Состояние загрузки */
    const [isLoading, setIsLoading] = useState(false);
    /** Значение поиска по таблице */
    const [searchValue, setSearchValue] = useState('');
    /** Загрузка счетов */
    const loadAccounts = useCallback((tab) => {
        setIsLoading(true);
        organizationApi
            .getUserBankAccounts(tab.key === 0 ? AccountsStatuses.OnlyAntive : AccountsStatuses.OnlyClosed)
            .then(setAccounts)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => setIsLoading(false));
    }, [errorAlert]);
    /** Начальная загрузка */
    useEffect(() => {
        loadAccounts(activeTab);
    }, [activeTab, loadAccounts]);
    /** Обработка поиска */
    useEffect(() => {
        setFilteredAccounts(filterItemsByText(accounts, ['bankAccountName', 'bankAccountNumber', 'branchName'], searchValue));
    }, [accounts, searchValue]);
    /** Установка группировки */
    useEffect(() => {
        if (isGroup) {
            const grouped = Object.fromEntries(groupBy(filteredAccounts, account => account.currencyRuName));
            const opened = Object.fromEntries(Object.keys(grouped).map(key => [key, true]));
            setRowOpen(opened);
            setGroupedAccounts(grouped);
        }
    }, [filteredAccounts, isGroup]);
    /** Раскрытие/скрытие сгруппированных счетов */
    const appropriationIndex = (ind) => () => {
        setRowOpen(previous => ({
            ...previous,
            [ind]: !previous[ind],
        }));
    };
    const columns = useMemo(() => [
        {
            key: 1,
        },
        {
            key: 2,
            title: 'филиал',
            cellContent: SubCell,
        },
        {
            key: 3,
            title: 'название | номер',
            cellContent: NameNumberCell,
        },
        {
            key: 4,
            title: 'баланс',
            cellContent: BalanceCell,
            cellProps: () => ({ align: 'right' }),
            headCellProps: { align: 'right' },
        },
        {
            key: 5,
        },
    ], []);
    /** Кнопка группировки */
    const groupSwitch = (React.createElement("button", { type: 'button', className: styles.sortButton, onClick: () => setIsGroup(prev => !prev) }, isGroup ? 'Без группировки' : 'С группировкой'));
    return (React.createElement("div", { className: stylesMain.contentWrapper },
        React.createElement(PageTitle, { title: '\u0421\u0447\u0435\u0442\u0430' }),
        React.createElement(Tabs, { options: parametersTabsAccounts, activeTab: activeTab, onChange: setActiveTab }),
        React.createElement(TableToolbar, { right: groupSwitch, search: { onSearch: setSearchValue, searchOnBlur: true } }),
        isLoading && (React.createElement("div", { className: styles.loaderContainer },
            React.createElement(Preloader, null))),
        React.createElement(TableContainer, { className: tableStyles.tableContainer },
            React.createElement(Table, null,
                React.createElement(TableBody, null, !isLoading && isGroup && groupedAccounts && filteredAccounts.length > 0
                    ? Object.entries(groupedAccounts).map(([group, sortedAccount]) => (React.createElement(React.Fragment, { key: group },
                        React.createElement(TableRow, { className: tableStyles.tableRowGroup, onClick: appropriationIndex(group) },
                            React.createElement(TableCell, { className: tableStyles.tableCellBody }),
                            React.createElement(TableCell, { className: tableStyles.tableCellBody },
                                React.createElement("div", { className: stylesMain.flexContainer },
                                    React.createElement("p", { className: styles.accountHeadTextSorted }, group),
                                    React.createElement("p", { className: styles.accountBodyTextSorted },
                                        sortedAccount.length,
                                        " \u0448\u0442."))),
                            React.createElement(TableCell, { className: tableStyles.tableCellBody, align: 'right' },
                                React.createElement("p", { className: styles.accountBodyTextSorted },
                                    toFormatMoney(getSum(sortedAccount.map(({ balance }) => balance))),
                                    ` ${sortedAccount.length ? sortedAccount[0].currencySymbol : group}`)),
                            React.createElement(TableCell, { className: cn(tableStyles.tableCellBody, tableStyles.extraEmptiness) },
                                React.createElement("img", { src: rowOpen[group] ? arrowDownIcon : arrowUpIcon, alt: '\u0441\u0442\u0440\u0435\u043B\u043A\u0430' }))),
                        rowOpen[group] && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: cn(tableStyles.tableCellHead, tableStyles.zero), colSpan: 15 },
                                React.createElement(GenericTable, { getItems: sortedAccount, columns: columns, getItemKey: getItemKey })))))))
                    : isGroup &&
                        !isLoading && (React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement("div", { className: styles.contentText }, "\u0421\u0447\u0435\u0442\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B")))))),
            !isGroup && !isLoading && filteredAccounts.length > 0 ? (React.createElement(GenericTable, { getItems: filteredAccounts, columns: columns, getItemKey: getItemKey })) : (!isGroup && !isLoading && React.createElement("div", { className: styles.contentText }, "\u0421\u0447\u0435\u0442\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B")))));
};
