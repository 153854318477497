import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import styles from './search.module.scss';
import searchIcon from '../../static/images/icons/search-default-dark.svg';
import refreshIcon from '../../static/images/icons/refresh_111075.svg';
import clearIcon from '../../static/images/icons/close-dark.svg';
/** Задержка поиска в мс */
const SEARCH_DELAY = 500;
/** Поле поиска с параметрами задержки */
export const Search = ({ onSearch: onSearchExternal, searchOnTyping = false, searchOnBlur = false, searchOnEnter = true, className, isRefresh = false, }) => {
    /** Текущее значение поиска */
    const [searchValue, setSearchValue] = useState('');
    /** Поиск с задержкой */
    const debounceSearch = useMemo(() => debounce(SEARCH_DELAY, (value) => onSearchExternal(value)), [onSearchExternal]);
    /** Потеря фокуса */
    const onBlur = () => {
        if (searchOnBlur) {
            onSearchExternal(searchValue);
        }
    };
    /** Нажатие клавиш. Перехват поиска на Enter */
    const onKeyDown = (event) => {
        if (searchOnEnter && event.key === 'Enter') {
            event.preventDefault();
            onSearchExternal(searchValue);
        }
    };
    /** Обработчик нажатия на кнопку Refresh */
    const onHandleSearch = useCallback((e) => {
        e.stopPropagation();
        onSearchExternal(searchValue);
    }, [onSearchExternal, searchValue]);
    /** Обработчик очистки поля ввода */
    const onHandleClear = useCallback((e) => {
        e.stopPropagation();
        if (searchValue.length) {
            setSearchValue('');
        }
    }, [searchValue.length]);
    /** Обработчик ввода */
    const onSearch = useCallback(event => {
        const value = event.target.value || '';
        setSearchValue(value);
        if (searchOnTyping && value) {
            debounceSearch(value);
        }
    }, [debounceSearch, searchOnTyping]);
    return (React.createElement("button", { type: 'button', className: styles.searchButton },
        React.createElement("img", { className: styles.searchIcon, src: searchIcon, alt: '\u043F\u043E\u0438\u0441\u043A' }),
        React.createElement("input", { onBlur: onBlur, onKeyDown: onKeyDown, value: searchValue, onChange: onSearch, placeholder: '\u041F\u043E\u0438\u0441\u043A', className: cn(styles.searchInput, className) }),
        React.createElement("img", { onClick: onHandleClear, className: cn(styles.searchIcons, {
                [styles.searchIconsVisible]: searchValue.length > 0,
            }), src: clearIcon, alt: '\u0441\u0442\u0435\u0440\u0435\u0442\u044C' }),
        isRefresh && (React.createElement("img", { onClick: onHandleSearch, className: cn(styles.searchIcons, styles.searchIconsVisible), src: refreshIcon, alt: '\u043E\u0431\u043D\u043E\u0432\u0438\u0442\u044C' }))));
};
