import { createRouteMapper, joinRoutePaths } from '../utils/general-code-import/config-import';
import { BASE_PATH, CREATE_PATH, LETTERS, PAYMENTS, PROFILE, STATEMENTS } from './routes';
/** Сопоставление пути и названия страницы */
export const { routeMapper, registerCurrentPath } = createRouteMapper(new Map([
    [BASE_PATH, 'Счета'],
    [LETTERS, 'Письма'],
    [joinRoutePaths(LETTERS, CREATE_PATH), 'Создание письма'],
    [STATEMENTS, 'Выписки и отчеты'],
    [PAYMENTS, 'Платежи'],
    [joinRoutePaths(PAYMENTS, CREATE_PATH), 'Создание платежа'],
    [PROFILE, 'Профиль'],
]), new Map([
    [PAYMENTS, 'Платеж'],
    [LETTERS, 'Письмо'],
]));
