import React, { useCallback, useEffect, useState } from 'react';
import { createSignature, encodeBase64, getValidCertificates } from '../../utils/eds';
import { useAlert } from '../alert-context';
import { CertificateItem } from '../certificate-item';
import { Popup } from '../popup';
import { Preloader } from '../preloader';
import { RectButtonUI } from '../rect-button-ui';
import styles from './sign-popup.module.scss';
/**
 * Диалог для подписи массива данных
 * */
export function MultiSignPopup({ getDataToSign, open, onCancel: onCancelExternal, certificatePredicate, onSignComplete, getErrorMessage, }) {
    /** Состояние загрузки */
    const [loading, setLoading] = useState(true);
    /** Сертификаты для выбора */
    const [certificates, setCertificates] = useState([]);
    /** Выбранный сертификат */
    const [certificate, setCertificate] = useState(null);
    const { errorAlert } = useAlert();
    /** Отмена */
    const onCancel = useCallback(() => {
        onCancelExternal();
        setCertificate(null);
    }, [onCancelExternal]);
    /** Загрузка сертификатов */
    useEffect(() => {
        if (open) {
            getValidCertificates()
                .then(certs => setCertificates(certs.filter(certificatePredicate)))
                .catch(() => {
                errorAlert('Ошибка при загрузке сертификатов');
                onCancel();
            })
                .finally(() => setLoading(false));
        }
    }, [certificatePredicate, errorAlert, onCancel, open]);
    /** Подписать */
    const onSign = useCallback(() => {
        if (certificate) {
            setLoading(true);
            getDataToSign()
                .then(result => {
                const promises = result.map(dataToSign => {
                    /** Добавление информации о сертификате */
                    const withCertInfo = {
                        bodyData: dataToSign,
                        certificateInformation: {
                            thumbprint: certificate.thumbprint,
                            issuer: certificate.issuer,
                            validFrom: certificate.validFrom,
                            validTo: certificate.validTo,
                            subject: certificate.subject,
                            publicKey: certificate.publicKey,
                            serialNumber: certificate.publicKey,
                            signatureAlgorithm: certificate.signatureAlgorithm,
                        },
                    };
                    /** Упаковвка в строку base64 */
                    const base64 = encodeBase64(JSON.stringify(withCertInfo));
                    const promise = createSignature(certificate, base64);
                    promise.catch(() => errorAlert(getErrorMessage(dataToSign)));
                    return promise.then(signature => ({
                        signature,
                        certificate,
                        base64,
                        withCertInfo,
                    }));
                });
                Promise.allSettled(promises)
                    .then(onSignComplete)
                    .finally(() => {
                    setLoading(false);
                    setCertificate(null);
                });
            })
                .catch(() => {
                errorAlert('Ошибка при загрузке данных для подписи');
                onCancel();
                setLoading(false);
            });
        }
    }, [certificate, errorAlert, getDataToSign, getErrorMessage, onCancel, onSignComplete]);
    /** Выбор сертификата для подписи */
    const onSelectCertificate = (cert) => () => {
        if (cert.thumbprint === certificate?.thumbprint)
            setCertificate(null);
        else
            setCertificate(cert);
    };
    return (React.createElement(Popup, { footer: React.createElement(React.Fragment, null,
            React.createElement(RectButtonUI, { disabled: !certificate || loading, onClick: onSign }, "\u041F\u043E\u0434\u043F\u0438\u0441\u0430\u0442\u044C"),
            React.createElement(RectButtonUI, { disabled: loading, styleType: 'line', onClick: onCancel }, "\u041E\u0442\u043C\u0435\u043D\u0430")), onClose: onCancel, header: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442', open: open }, loading ? (React.createElement(Preloader, null)) : (React.createElement("div", null,
        !certificates.length && (React.createElement("div", { className: styles.empty }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435, \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u044B \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B")),
        certificates.length > 0 &&
            certificates.map(item => (React.createElement(CertificateItem, { certificate: item, selected: item.thumbprint === certificate?.thumbprint, key: item.thumbprint, onSelect: onSelectCertificate(item) })))))));
}
