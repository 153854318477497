import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import styles from './alert.module.scss';
/** Таймаут закрытия уведомления */
const ALERT_TIMEOUT = 5000;
/** Контенер для отображения уведомлений */
export const Alert = ({ setContextValue }) => {
    /** Уведомления */
    const [alerts, setAlerts] = useState([]);
    /** Добавление нового уведомления */
    const addAlert = useCallback((text, type, timout = true) => {
        /** Создание */
        const alert = {
            text,
            type,
            /** Генерация уникального идентификатора */
            id: v4(),
            /** Сохранение идентификатора интервала для возможности очистить его */
            intervalId: timout
                ? window.setInterval(() => {
                    setAlerts(prev => prev.filter(item => item !== alert));
                }, ALERT_TIMEOUT)
                : undefined,
        };
        /** Добавление */
        setAlerts(prev => [alert, ...prev]);
    }, []);
    /** Создать обработчик удаления уведомления */
    const createRemoveAlertHandler = (alertId) => () => {
        setAlerts(prev => {
            const alert = prev.find(item => item.id === alertId);
            if (alert) {
                window.clearInterval(alert.intervalId);
                return prev.filter(item => item !== alert);
            }
            return prev;
        });
    };
    /** Установка триггеров уведомлений при рендере */
    useEffect(() => {
        setContextValue({
            errorAlert: (message, timeout) => addAlert(message, 'error', timeout),
            warningAlert: (message, timeout) => addAlert(message, 'warning', timeout),
            successAlert: (message, timeout) => addAlert(message, 'success', timeout),
        });
    }, [addAlert, setContextValue]);
    return (React.createElement("div", { "data-testid": 'alert-container', className: styles.container }, alerts.map(({ id, text, type }) => (React.createElement("div", { key: id, className: styles[type] },
        React.createElement("span", null, text),
        React.createElement("span", { "data-testid": 'close', className: styles.closeBtn, onClick: createRemoveAlertHandler(id) }, "\u00D7"))))));
};
