import React, { useCallback, useMemo } from 'react';
import { StatusSelectOptionPaymentMapper } from '../../../../utils/enum-mappers';
import { GenericTable, Popup, } from '../../../../utils/general-code-import/components-import';
import { stylesMain } from '../../../../utils/general-code-import/styles-import';
import { formatDate, useAlert } from '../../../../utils/general-code-import/config-import';
import { orderApi } from '../../../../utils/api/generalApi';
const DateCell = ({ date }) => formatDate(date, 'dd.MM.yyyy HH:mm:ss');
const EventCell = ({ status }, statusOrder) => (React.createElement("div", { className: stylesMain.flexContainer },
    statusOrder === status && React.createElement("span", { className: stylesMain.indicator }),
    React.createElement("p", { className: statusOrder === status ? stylesMain.tableTextHeadSmall : stylesMain.tableTextBody }, StatusSelectOptionPaymentMapper[status].label)));
const UserCell = ({ userFullName }) => (React.createElement("p", { className: stylesMain.tableTextHeadSmall }, userFullName));
const CommentCell = ({ comment }) => (React.createElement("p", { className: stylesMain.tableTextBody }, comment));
const getItemKey = (item) => item.status;
export const HistoryStatusPaymentPopup = ({ id, statusOrder, ...rest }) => {
    const { errorAlert } = useAlert();
    const columns = useMemo(() => [
        {
            key: 1,
        },
        {
            key: 2,
            title: '↓ действовал с',
            cellContent: DateCell,
        },
        {
            key: 3,
            title: '↓ событие',
            cellContent: item => EventCell(item, statusOrder),
        },
        {
            key: 4,
            title: 'пользователь',
            cellContent: UserCell,
        },
        {
            key: 5,
            title: 'коментарий',
            cellContent: CommentCell,
        },
        {
            key: 6,
        },
    ], [statusOrder]);
    const loadItems = useCallback(() => orderApi.getOrderStatusHistory(id), [id]);
    const onLoadError = useCallback(() => errorAlert('Ошибка во время загрузки'), [errorAlert]);
    return (React.createElement(Popup, { ...rest, header: '\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u0441\u0442\u0430\u0442\u0443\u0441\u043E\u0432' },
        React.createElement(GenericTable, { getItemKey: getItemKey, columns: columns, onLoadError: onLoadError, getItems: loadItems, rowHoverTransition: false })));
};
