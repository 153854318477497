import * as fns from 'date-fns';
/** Устанавливает конец дня */
export const convertToEndOfDay = (date) => {
    if (date) {
        const updated = new Date(date);
        updated.setHours(23);
        updated.setMinutes(59);
        updated.setSeconds(59);
        return updated;
    }
    return date;
};
/** Очистка даты */
const clearDate = (date) => fns.set(date, { milliseconds: 0, seconds: 0, minutes: 0, hours: 0 });
/** Период несколько дней до сегодня */
const getDays = days => {
    const current = clearDate(new Date());
    return [fns.addDays(current, -days), convertToEndOfDay(current)];
};
/** Текущая неделя */
const getCurrentWeek = () => {
    const current = clearDate(new Date());
    const day = current.getDay();
    const start = fns.addDays(current, -day + 1);
    const end = fns.addDays(current, 7 - day);
    return [start, convertToEndOfDay(end)];
};
/** Прошла неделя */
const getLastWeek = () => {
    const [start, end] = getCurrentWeek();
    return [fns.addWeeks(start, -1), convertToEndOfDay(fns.addWeeks(end, -1))];
};
/** Текущий месяц */
const getCurrentMonth = () => {
    const current = clearDate(new Date());
    const start = fns.setDate(current, 1);
    const end = fns.setDate(current, fns.lastDayOfMonth(current).getDate());
    return [start, convertToEndOfDay(end)];
};
/** прошлый месяц */
const getLastMonth = () => {
    const [start] = getCurrentMonth();
    const t0 = fns.addMonths(start, -1);
    const t1 = fns.setDate(t0, fns.lastDayOfMonth(t0).getDate());
    return [t0, convertToEndOfDay(t1)];
};
/** Текущий год */
const getCurrentYear = () => {
    const current = clearDate(new Date());
    const start = fns.set(current, { month: 0, date: 1 });
    const end = fns.set(current, { month: 11, date: 31 });
    return [start, convertToEndOfDay(end)];
};
/** Прошлый год */
const getLastYear = () => {
    const [start, end] = getCurrentYear();
    return [fns.addYears(start, -1), convertToEndOfDay(fns.addYears(end, -1))];
};
/** Квартал 1 */
const getQuarter1 = () => {
    const year = new Date().getFullYear();
    return [new Date(year, 0, 1), convertToEndOfDay(new Date(year, 2, 31))];
};
/** Квартал 2 */
const getQuarter2 = () => {
    const year = new Date().getFullYear();
    return [new Date(year, 3, 1), convertToEndOfDay(new Date(year, 5, 30))];
};
/** Квартал 3 */
const getQuarter3 = () => {
    const year = new Date().getFullYear();
    return [new Date(year, 6, 1), convertToEndOfDay(new Date(year, 8, 30))];
};
/** Квартал 4 */
const getQuarter4 = () => {
    const year = new Date().getFullYear();
    return [new Date(year, 9, 1), convertToEndOfDay(new Date(year, 11, 31))];
};
/** Пресеты периодов */
export const PeriodPresets = [
    {
        label: 'Дни',
        items: [
            {
                label: 'Сегодня',
                period: getDays(0),
            },
            {
                label: 'Вчера',
                period: getDays(1),
            },
            {
                label: '7 дней',
                period: getDays(7),
            },
            {
                label: '30 дней',
                period: getDays(30),
            },
        ],
    },
    {
        label: 'Недели',
        items: [
            {
                label: 'Текущая',
                period: getCurrentWeek(),
            },
            {
                label: 'Прошлая',
                period: getLastWeek(),
            },
        ],
    },
    {
        label: 'Месяцы',
        items: [
            {
                label: 'Текущий',
                period: getCurrentMonth(),
            },
            {
                label: 'Прошлый',
                period: getLastMonth(),
            },
        ],
    },
    {
        label: 'Годы',
        items: [
            {
                label: 'Текущий',
                period: getCurrentYear(),
            },
            {
                label: 'Прошлый',
                period: getLastYear(),
            },
        ],
    },
    {
        label: 'Кварталы',
        items: [
            {
                label: 'Первый',
                period: getQuarter1(),
            },
            {
                label: 'Второй',
                period: getQuarter2(),
            },
            {
                label: 'Третий',
                period: getQuarter3(),
            },
            {
                label: 'Четвертый',
                period: getQuarter4(),
            },
        ],
    },
];
