import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { letterApi } from '../../utils/api/generalApi';
const initialState = {
    letterServices: [],
    duplicateLetter: null,
    duplicatePayment: null,
    repliedLetter: null,
    status: 'idle',
};
export const fetchDictionaryServices = createAsyncThunk('app/fetchDictionaryServices', () => letterApi.getDictionaryServices());
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDuplicateLetter: (state, action) => {
            state.duplicateLetter = action.payload;
        },
        setDuplicatePayment: (state, action) => {
            state.duplicatePayment = action.payload;
        },
        setRepliedLetter: (state, action) => {
            state.repliedLetter = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDictionaryServices.fulfilled, (state, action) => {
            state.letterServices = action.payload;
        });
    },
});
export const getDuplicateLetter = (state) => state.app.duplicateLetter;
export const getDuplicatePayment = (state) => state.app.duplicatePayment;
export const getRepliedLetter = (state) => state.app.repliedLetter;
export const getLetterServices = (state) => state.app.letterServices;
export const { setDuplicateLetter, setRepliedLetter, setDuplicatePayment } = appSlice.actions;
export const appReducer = appSlice.reducer;
