import { useCallback, useState } from 'react';
/** Мемоизация функции с возможностью ее перезагрузки */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useReload(callback, deps) {
    /** Текущая ревизия - для обновления функции */
    const [revision, setRevision] = useState(0);
    /** Мемоизация функции */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoCallback = useCallback(callback, [...deps, revision]);
    /** Перезагрузить меммоизированную функцию */
    const reload = useCallback(() => setRevision(prev => prev + 1), []);
    return [memoCallback, reload];
}
