export const cleanEmpty = (entries) => {
    /** Объект */
    if (entries instanceof Object) {
        /** Перебор всех свойств */
        Object.entries(entries).forEach(([key, value]) => {
            if (!value && value !== false && value !== 0) {
                const typedObject = entries;
                delete typedObject[key];
            }
            else {
                /** Пройтись по вложенным свойствам */
                cleanEmpty(value);
            }
        });
    }
};
