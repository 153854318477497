import { createSlice } from '@reduxjs/toolkit';
import { parametersTabsLetters, parametersTabsPayments } from '../../utils/tabOptions';
const initialState = {
    activeLetterTab: parametersTabsLetters[0],
    activePaymentTab: parametersTabsPayments[0],
};
const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveLetterTab(state, action) {
            state.activeLetterTab = action.payload;
        },
        setActivePaymentTab(state, action) {
            state.activePaymentTab = action.payload;
        },
    },
});
export const getActiveLetterTab = (state) => state.tabs.activeLetterTab;
export const getActivePaymentTab = (state) => state.tabs.activePaymentTab;
export const { setActiveLetterTab, setActivePaymentTab } = tabSlice.actions;
export const tabReducer = tabSlice.reducer;
