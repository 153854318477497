import cn from 'classnames';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './select-ui.module.scss';
import { WrapContainerUI } from '../wrap-container-ui';
import { SelectInputUI } from '../select-input-ui';
/**
 * Компонент селекта
 * */
export function SelectUI({ placeholder = '', onChange, options, value, title, isClearable, getValue, hasError, errorText, disabled = false, isRequired, withErrorText = true, limit, className, }) {
    const [isOpened, setOpened] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    /** Ссылка на input */
    const inputRef = useRef(null);
    /** Ссылка на контейнер со списком */
    const listContainerRef = useRef(null);
    /** Обработка раскрытия списка опций */
    const handleOpened = useCallback(() => setOpened(prev => !prev), []);
    /** Обработка раскрытия списка опций при вводе текста */
    const openedDropdown = useCallback(() => setOpened(true), []);
    /** Обработка выбора опции */
    const handleOptionSelection = (option) => (e) => {
        e.stopPropagation();
        onChange(option);
        setOpened(false);
    };
    /** Обработка закрытия списка при клике по странице */
    useEffect(() => {
        const listener = ({ target }) => {
            const { current } = inputRef;
            if (target !== current)
                setOpened(false);
        };
        document.addEventListener('click', listener);
        return () => document.removeEventListener('click', listener);
    }, []);
    /** Привязка выпадаюцщего списка к элементу input */
    useEffect(() => {
        const { current: input } = inputRef;
        const { current: listContainer } = listContainerRef;
        /** Задание координат */
        if (input && listContainer && isOpened) {
            const { top, left, height, width } = input.getBoundingClientRect();
            listContainer.style.top = `${top + height + 2}px`;
            listContainer.style.left = `${left}px`;
            listContainer.style.width = `${width}px`;
        }
        /** При скроле скрывать список */
        const listener = ({ target }) => {
            if (target !== listContainer && isOpened)
                setOpened(false);
        };
        window.addEventListener('scroll', listener, true);
        return () => window.removeEventListener('scroll', listener, true);
    }, [isOpened]);
    return (React.createElement(WrapContainerUI, { hasError: hasError, errorText: errorText, isRequired: isRequired, disabled: disabled, title: title, className: className, withErrorText: withErrorText },
        React.createElement(SelectInputUI, { disabled: disabled, setFilteredOptions: setFilteredOptions, isOpened: isOpened, handleOpened: handleOpened, openedDropdown: openedDropdown, placeholder: placeholder, isClearable: isClearable, onChange: onChange, options: options, value: value, title: title, limit: limit, inputRef: inputRef }, ReactDOM.createPortal(React.createElement("div", { "data-testid": 'select-dropdown', ref: listContainerRef, className: cn(styles.selectDropdown, { [styles.open]: isOpened }) },
            React.createElement("ul", { className: styles.selectList }, filteredOptions.length ? (filteredOptions.map(option => (React.createElement("li", { key: getValue(option), value: getValue(option), onClick: handleOptionSelection(option), className: styles.selectItem }, option.label)))) : (React.createElement("li", { className: styles.selectItem }, "\u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E")))), document.body))));
}
