import cn from 'classnames';
import React from 'react';
import { formatDate } from '../../utils/smallFunctions';
import styles from './certificate-item.module.scss';
/**
 * Отображение сертификата для подписи
 * */
export const CertificateItem = ({ onSelect, certificate, selected, }) => (React.createElement("div", { className: cn(styles.certificateItem, { [styles.certificateItemSelected]: selected }), onClick: onSelect },
    React.createElement("div", null, certificate.name),
    React.createElement("div", { className: styles.validBlock },
        "\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D \u0441 ",
        formatDate(certificate.validFrom),
        " \u043F\u043E ",
        formatDate(certificate.validTo))));
