import axios from 'axios';
import { goToAuth } from '../../../submodules/inkh-core-frontend/src/utils/auth';
import { InformClient } from '../../../submodules/inkh-principal/src/Inkh.Principal.Api.Client.TypeScript/Clients/InformClient.Generated';
import { addAuthInterceptors } from '../general-code-import/config-import';
import { AttachmentClient, BanksClient, LetterClient, OrganizationsClient, PaymentClient, PaymentTemplateClient, PrincipalOrganizationClient, StatementClient, AuthClient, UsersClient, TempFrontendClient, } from './apiClients';
import { DebugApiConfig } from './debugApiConfig';
/** Общий экземпляр API для приложения */
const axiosInstance = axios.create({
    /** Для того, чтобы данные авторизации цеплялись автоматически */
    withCredentials: true,
});
/** Экзепляр API, не учитывающий авторизацию */
const axiosInstanceWithoutAuthInterceptors = axios.create({
    withCredentials: true,
});
/** Клиент для отправки сообщений об ошибках */
const errorInformClient = new InformClient(DebugApiConfig.principal, axiosInstanceWithoutAuthInterceptors);
const authClient = new AuthClient(DebugApiConfig.keycloak, axiosInstanceWithoutAuthInterceptors);
const debugTokenClient = new TempFrontendClient(DebugApiConfig.keycloak);
// todo сделать хорошо!
if (IS_DEBUG) {
    /** Авторизация при каждом запросе */
    axiosInstance.interceptors.request.use(async (config) => {
        // todo убрать хард код
        const { accessToken = '' } = await debugTokenClient.login({
            login: 'admin',
            password: 'adminadmin',
        });
        const headers = config.headers ?? {};
        config.headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
        return config;
    });
}
/** Обновление токена */
export const refreshToken = () => IS_DEBUG ? Promise.resolve(0) : authClient.refresh();
/** Добавление обработчиков авторизации */
if (!IS_DEBUG) {
    addAuthInterceptors(axiosInstance, {
        refreshToken,
        reportError: info => errorInformClient.addUnknownErrorInfo(info),
    });
}
export const letterApi = new LetterClient(DebugApiConfig.letters, axiosInstance);
export const attachmentApi = new AttachmentClient(DebugApiConfig.letters, axiosInstance);
export const statementApi = new StatementClient(DebugApiConfig.statements, axiosInstance);
export const organizationApi = new OrganizationsClient(DebugApiConfig.principal, axiosInstance);
export const orderApi = new PaymentClient(DebugApiConfig.order, axiosInstance);
export const paymentTemplateApi = new PaymentTemplateClient(DebugApiConfig.order, axiosInstance);
export const userApi = new UsersClient(DebugApiConfig.principal, axiosInstance);
export const banksApi = new BanksClient(DebugApiConfig.principal, axiosInstance);
export const principalOrganizationClient = new PrincipalOrganizationClient(DebugApiConfig.principal, axiosInstance);
/**
 * Получение информации о пользователе
 * */
export const getUserInfo = () => userApi.getUserInfo().then(info => ({
    logout: () => authClient.logout().then(goToAuth),
    ...info,
}));
