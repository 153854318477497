import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import attachmentIcon from '../../static/images/icons/attachment-dark.svg';
import sortIcon from '../../static/images/icons/sort.svg';
import { useSort } from '../../utils/hooks/use-sort';
import { formatDate, getValueSelect } from '../../utils/smallFunctions';
import stylesMain from '../../utils/styles/main.module.scss';
import { useAlert } from '../alert-context';
import { ConfirmDeletePopup } from '../confirm-delete-popup';
import { DateInputRange } from '../date-input-range';
import { FilesLetterPopup } from '../file-letter-popup';
import { HistoryStatusPopup } from '../history-status-popup';
import { PageTitle } from '../page-title';
import { RectButtonUI } from '../rect-button-ui';
import { SelectUI } from '../select-ui';
import { GenericTable, } from '../table';
import tableStyles from '../table/table-styles.module.scss';
import { Tooltip } from '../tooltip';
import styles from './letters-list-layout.module.scss';
import { PopupValues } from './types';
const RecipientsInfoCell = (from, to) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, from),
    React.createElement("div", { className: stylesMain.tableTextBody },
        React.createElement("div", { className: styles.sendIcon }),
        to)));
const AttachmentsCell = (onClick) => (React.createElement("img", { onClick: onClick, className: styles.fileIcon, src: attachmentIcon, alt: '\u0444\u0430\u0439\u043B' }));
const BodyTextCell = (theme, body) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: stylesMain.flexContainer },
        React.createElement("p", { className: stylesMain.tableTextHead }, theme)),
    React.createElement("p", { className: stylesMain.tableTextBody }, body)));
function CategoryCell(category) {
    return React.createElement("p", { className: stylesMain.tableTextBody }, category);
}
const StatusCell = (statusName, onClick) => (React.createElement("span", { onClick: onClick, className: styles.statusContainer }, statusName));
const HandlersCell = (canDelete, onDelete, deleteAccess, reply, onReplyLetter) => (React.createElement("div", { className: stylesMain.flexContainer },
    reply && (React.createElement(Tooltip, { tooltipText: '\u041E\u0442\u0432\u0435\u0442\u0438\u0442\u044C \u043D\u0430 \u043F\u0438\u0441\u044C\u043C\u043E' },
        React.createElement("button", { "aria-label": '\u043E\u0442\u0432\u0435\u0442\u0438\u0442\u044C', onClick: reply, type: 'button', className: styles.replyButton }))),
    onReplyLetter && (React.createElement(Tooltip, { tooltipText: '\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043F\u0438\u0441\u044C\u043C\u0443' },
        React.createElement("button", { "aria-label": '\u043F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043F\u0438\u0441\u044C\u043C\u0443', onClick: onReplyLetter, type: 'button', className: styles.repliedMailButton }))),
    deleteAccess && canDelete && (React.createElement("button", { "aria-label": '\u0443\u0434\u0430\u043B\u0438\u0442\u044C', type: 'button', className: styles.deleteButton, onClick: onDelete }))));
/** Пагинация */
const pagination = {
    nameElement: {
        one: 'письму',
        few: 'письма',
        many: 'писем',
    },
};
/**
 * Макет списка писем
 * Макет основной страницы "Письма и запросы"
 * */
export function LettersListLayout({ createLetter, navigateByLetterId, createReplyHandler, createReplyLetterHandler, getLetter, getLettersAndOrganizationOptions, tabs, isDraft, categoryOptions, canDelete, api: { addAttachment, deleteAttachment, downloadAttachment, loadAttachments, getAttachment, onConfirmDelete, loadHistoryStatuses, getHistory, }, rightsAccess: { createAccess, deleteAccess, viewStatusHistoryAccess, downloadAttachmentAccess, addAttachmentAccess, deleteAttachmentAccess, }, }) {
    /** Выбранное письмо */
    const [letter, setLetter] = useState(null);
    /** Период выборки */
    const [period, setPeriod] = useState(null);
    /** Выбранная категория */
    const [category, setCategory] = useState(null);
    /** Организации для выбора */
    const [organizations, setOrganizations] = useState([]);
    /** Организация для фильтрации */
    const [organization, setOrganization] = useState(null);
    /** Сортировка подате */
    const [dateSort, setDateSort] = useSort('asc');
    /** Состояние открытия диалогов */
    const [lettersPopupOpened, setLettersPopupOpened] = useState('');
    const { errorAlert } = useAlert();
    /** Закрытие диалога */
    const onClosePopup = useCallback(() => {
        setLetter(null);
        setLettersPopupOpened('');
    }, []);
    /** Открыть вложения */
    const createFilesHandler = useCallback((letter) => (e) => {
        e.stopPropagation();
        setLetter(letter);
        setLettersPopupOpened(PopupValues.FILES);
    }, []);
    /** Открыть историю */
    const creteStatusHistoryHandler = useCallback((letter) => (e) => {
        if (viewStatusHistoryAccess) {
            e.stopPropagation();
            setLetter(letter);
            setLettersPopupOpened(PopupValues.STATUS);
        }
    }, [viewStatusHistoryAccess]);
    /** Удалить сообщение */
    const createDeleteHandler = useCallback((letter) => (e) => {
        e.stopPropagation();
        setLetter(letter);
        setLettersPopupOpened(PopupValues.DELETE);
    }, []);
    /** Загрузка с учетом фильтров */
    const loadWithFilters = useCallback((paginationData, search, activeTab) => {
        const filter = {};
        if (period) {
            filter.period = period;
        }
        if (organization) {
            filter.organization = organization.value;
        }
        if (category)
            filter.category = category.value;
        const [getLettersResult, organizations] = getLettersAndOrganizationOptions(paginationData, search || undefined, activeTab, filter, dateSort);
        organizations
            .then(setOrganizations)
            .catch(() => errorAlert('Ошибка при загрузке организаций'));
        return getLettersResult;
    }, [period, organization, category, getLettersAndOrganizationOptions, dateSort, errorAlert]);
    const columns = useMemo(() => [
        {
            key: 1,
            cellContent: () => '',
        },
        {
            key: 2,
            title: 'дата',
            cellContent: item => {
                const { createDate } = getLetter(item);
                return createDate && formatDate(createDate, 'dd.MM.yyyy HH:mm');
            },
        },
        {
            key: 3,
            title: 'отправитель | получатель',
            cellContent: item => {
                const { organizationFrom, organizationTo } = getLetter(item);
                return RecipientsInfoCell(organizationFrom, organizationTo);
            },
        },
        {
            key: 4,
            cellContent: item => {
                const { hasAttachments } = getLetter(item);
                return hasAttachments ? AttachmentsCell(createFilesHandler(item)) : undefined;
            },
            headCellProps: {
                className: tableStyles.extraSmall,
            },
        },
        {
            key: 5,
            title: 'тема | текст',
            cellContent: item => {
                const { body, theme } = getLetter(item);
                return BodyTextCell(theme, body);
            },
            headCellProps: {
                className: tableStyles.extraLarge,
            },
        },
        {
            key: 6,
            title: 'категория',
            cellContent: item => CategoryCell(getLetter(item).category),
        },
        {
            key: 7,
            title: 'статус',
            cellContent: item => StatusCell(getLetter(item).statusName, creteStatusHistoryHandler(item)),
        },
        {
            key: 8,
            cellContent: item => HandlersCell(canDelete(item), createDeleteHandler(item), deleteAccess, createReplyHandler(item), createReplyLetterHandler(item)),
            headCellProps: {
                className: tableStyles.extraSmall,
            },
        },
    ], [
        canDelete,
        createDeleteHandler,
        createFilesHandler,
        createReplyHandler,
        createReplyLetterHandler,
        creteStatusHistoryHandler,
        deleteAccess,
        getLetter,
    ]);
    /** Параметры строк таблицы */
    const rowProps = useCallback((item) => ({
        onClick: navigateByLetterId(getLetter(item).id),
    }), [getLetter, navigateByLetterId]);
    /** Изменение сортировки по дате */
    const onSort = useCallback(() => {
        setDateSort(prev => (prev === 'asc' ? 'desc' : 'asc'));
    }, [setDateSort]);
    /** Информация по табам */
    const { activeTab, options: [incoming], } = tabs;
    /** Параметры тулбара */
    const toolbar = useMemo(() => ({
        search: { searchOnBlur: true },
        leftClassName: styles.toolbarLeft,
        searchClassName: styles.search,
        left: (React.createElement(React.Fragment, null,
            React.createElement(SelectUI, { value: organization, className: styles.fromTo, withErrorText: false, getValue: getValueSelect, options: organizations, onChange: setOrganization, isClearable: true, title: activeTab !== incoming ? 'Получатель' : 'Отправитель' }),
            React.createElement(SelectUI, { getValue: item => item.label, className: styles.category, onChange: setCategory, isClearable: true, withErrorText: false, options: categoryOptions, value: category, title: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F' }),
            React.createElement(DateInputRange, { className: styles.period, isClearable: true, value: period, onChange: setPeriod, title: '\u041F\u0435\u0440\u0438\u043E\u0434' }))),
        right: (React.createElement(Tooltip, { tooltipText: dateSort === 'asc' ? 'От новых к старым' : 'От старых к новым' },
            React.createElement("button", { type: 'button', onClick: onSort, className: cn(styles.favoritesButton, {
                    [styles.favoritesButtonActive]: dateSort,
                    [styles.favoritesButtonActiveDesc]: dateSort === 'desc',
                }) },
                React.createElement("img", { className: styles.favoritesIcon, src: sortIcon, alt: '\u0441\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u043A\u0430' })))),
    }), [
        activeTab,
        category,
        categoryOptions,
        dateSort,
        onSort,
        organization,
        organizations,
        incoming,
        period,
    ]);
    const onLoadError = useCallback(() => errorAlert('Ошибка во время загрузки'), [errorAlert]);
    return (React.createElement("div", { className: stylesMain.contentWrapper },
        React.createElement(PageTitle, { title: '\u041F\u0438\u0441\u044C\u043C\u0430' }, createAccess && (React.createElement(RectButtonUI, { styleType: 'green', onClick: createLetter }, "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u043F\u0438\u0441\u044C\u043C\u043E"))),
        React.createElement(GenericTable, { getItems: loadWithFilters, getItemKey: item => getLetter(item).id, columns: columns, toolbar: toolbar, onLoadError: onLoadError, pagination: pagination, tabs: tabs, rowProps: rowProps }),
        letter && (React.createElement(HistoryStatusPopup, { open: lettersPopupOpened === PopupValues.STATUS, onClose: onClosePopup, getHistoryItem: getHistory, loadItems: () => loadHistoryStatuses(getLetter(letter).id), status: getLetter(letter).status })),
        letter && (React.createElement(FilesLetterPopup, { addAttachment: file => addAttachment(file, getLetter(letter).id), deleteAttachment: deleteAttachment, downloadAttachment: downloadAttachment, loadAttachments: () => loadAttachments(getLetter(letter).id), getAttachment: getAttachment, open: letter && lettersPopupOpened === PopupValues.FILES, onClose: onClosePopup, isDraft: isDraft(letter), rightsAttachmentAccess: {
                downloadAttachmentAccess,
                addAttachmentAccess,
                deleteAttachmentAccess,
            } })),
        letter && (React.createElement(ConfirmDeletePopup, { open: lettersPopupOpened === PopupValues.DELETE, onClose: onClosePopup, onSubmit: () => onConfirmDelete(getLetter(letter).id), title: '\u043F\u0438\u0441\u044C\u043C\u043E' }))));
}
