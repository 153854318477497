import React, { useCallback } from 'react';
import { useCustomNavigate } from '../../utils/hooks/custom-navigate';
import { useAuthContext } from '../auth';
import { HistoryNavigation } from '../history-navigation';
import logoutImg from '../../static/images/icons/logout-light.svg';
import styles from './header.module.scss';
/** Шапка приложения */
export const Header = ({ profilePath, routeMapper, fullName, organizationName, }) => {
    const navigate = useCustomNavigate();
    /** Имя пользователя и функция логаута */
    const { logout } = useAuthContext();
    const profileNavigate = useCallback(() => {
        if (profilePath)
            navigate(profilePath);
    }, [navigate, profilePath]);
    return (React.createElement("header", { className: styles.header },
        React.createElement(HistoryNavigation, { renderMenu: true, locationToName: routeMapper }),
        React.createElement("div", { className: styles.authInfo },
            React.createElement("span", { onClick: profilePath ? profileNavigate : undefined, className: styles.fio },
                fullName,
                " (",
                organizationName,
                ")"),
            React.createElement("button", { onClick: logout, className: styles.logoutButton, type: 'button' },
                React.createElement("img", { src: logoutImg, alt: '\u0432\u044B\u0439\u0442\u0438' })))));
};
