import cn from 'classnames';
import React from 'react';
import styles from './wrap-container-ui.module.scss';
/**
 * Контейнер с названием для компонентов selects, textarea, input
 * */
export const WrapContainerUI = ({ title, isRequired, children, hasError, errorText, withErrorText = true, disabled, className, }) => (React.createElement("div", { className: cn(styles.inputContainer, className, { [styles.disabled]: disabled }) },
    isRequired && !disabled && React.createElement("span", { className: styles.required }, "*"),
    children,
    React.createElement("span", { className: styles.title }, title),
    withErrorText && (React.createElement("span", { className: cn(styles.errorText, { [styles.hasError]: hasError }) }, errorText))));
