import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './pagination.module.scss';
import arrowLeft from '../../static/images/icons/arrowLeft.svg';
import arrowRight from '../../static/images/icons/arrowRight.svg';
/** Возвращение множественного числа в зависимости от кол-ва и дескриптора */
export const fromPluralDescriptor = (count, { one, few, many }) => {
    switch (count) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return few;
        default:
            return many;
    }
};
/** Смещение "по сторонам" от текущей страницы */
const OFFSET = 2;
/** Точки для отображения скрытых страниц */
const PaginationDots = () => (React.createElement("div", { className: styles.dots },
    React.createElement("span", null),
    React.createElement("span", null),
    React.createElement("span", null)));
export const Pagination = ({ totalRows, nameElement, onChange, currentPage, limitRows, className, }) => {
    /** Режим выбора лимита строк */
    const [isInputLimit, setIsInputLimit] = useState(false);
    /** Ограничение по строкам */
    const [{ isDisabledPrev, isDisabledNext }, setPagination] = useState({
        isDisabledPrev: currentPage === 1,
        isDisabledNext: false,
    });
    /** input value во время ввода ограничения */
    const [inputValue, setInputValue] = useState(`${limitRows}`);
    /** Ссылка на поле ввода */
    const inputRef = useRef(null);
    /** Выделение текста при фокусе */
    const onFocus = useCallback(() => {
        const input = inputRef.current;
        if (input) {
            input.setSelectionRange(0, input.value.length);
        }
    }, []);
    /** Всего страниц */
    const totalPage = useMemo(() => Math.ceil(totalRows / limitRows), [limitRows, totalRows]);
    useEffect(() => {
        if (totalPage < 2) {
            setPagination(prev => ({ ...prev, isDisabledNext: true }));
        }
    }, [totalPage]);
    /** Получени имени элемента в зависимости от числа выводимых элементов */
    const getElementNameByLimit = useCallback((count) => {
        /** Задана статическая строка */
        if (typeof nameElement === 'string') {
            return nameElement;
        }
        /** В прикладной логике определена функция */
        if (typeof nameElement === 'function') {
            return nameElement(count);
        }
        /** В прикладной логике определен дескриптор по числам */
        return fromPluralDescriptor(count, nameElement);
    }, [nameElement]);
    const { listPages, rightSkip, leftSkip } = useMemo(() => {
        const listPages = [];
        let leftSkip = false;
        let rightSkip = false;
        /** Если число страниц превышает допустимое количество для отображения */
        if (totalPage > 2 * OFFSET + 1) {
            let skipLeftCount = 0;
            let skipRightCount = 0;
            /** Добавление номеров страниц с учетом смещения влево и право на OFFSET */
            for (let i = -OFFSET; i <= OFFSET; i++) {
                const page = currentPage + i;
                if (page < 1)
                    skipLeftCount++;
                else if (page > totalPage)
                    skipRightCount++;
                else
                    listPages.push(page);
            }
            /** Добавить справа столько, сколько было пропущено слева */
            for (let i = 0; i < skipLeftCount; i++) {
                const page = listPages[listPages.length - 1] + 1;
                if (!Number.isNaN(page) && page > 0)
                    listPages.push(page);
                else
                    break;
            }
            /** Добавить слева столько, сколько было пропущено справа */
            for (let i = 0; i < skipRightCount; i++) {
                const page = listPages[0] - 1;
                if (!Number.isNaN(page) && page <= totalPage)
                    listPages.unshift(page);
                else
                    break;
            }
            leftSkip = listPages[0] !== 1;
            rightSkip = listPages[listPages.length - 1] !== totalPage;
        }
        else {
            /** Если число страниц не превышает максимально допустимое, то выводятся все */
            for (let i = 1; i <= totalPage; i++) {
                listPages.push(i);
            }
        }
        return { listPages, rightSkip, leftSkip };
    }, [totalPage, currentPage]);
    /** Установка лимита строк */
    const setLimit = useCallback((e) => {
        e.preventDefault();
        if (!inputValue) {
            setInputValue(`${limitRows}`);
            setIsInputLimit(false);
            return;
        }
        setIsInputLimit(false);
        onChange({ limitRows: +inputValue, currentPage: 1 });
    }, [inputValue, onChange, limitRows]);
    /** Изменение вводимых значений в поле ввода */
    const onInputChange = useCallback(({ target: { value } }) => {
        if (/^\d*$/.test(value)) {
            setInputValue(value === '0' ? '1' : value);
            setIsInputLimit(true);
        }
    }, []);
    /** Назад */
    const onPreviousClick = useCallback(() => {
        setPagination(() => {
            onChange({ currentPage: currentPage - 1, limitRows });
            return {
                isDisabledNext: false,
                isDisabledPrev: currentPage === 2,
            };
        });
    }, [currentPage, limitRows, onChange]);
    /** Предыдущая страница */
    const createOnSelectPage = useCallback((page) => () => {
        setPagination(() => {
            onChange({ currentPage: page, limitRows });
            return {
                isDisabledPrev: page === 1,
                isDisabledNext: page === Math.ceil(totalRows / limitRows),
            };
        });
    }, [limitRows, onChange, totalRows]);
    /** Следующая страница */
    const onNextClick = useCallback(() => {
        setPagination(() => {
            onChange({ currentPage: currentPage + 1, limitRows });
            return {
                isDisabledPrev: false,
                isDisabledNext: currentPage + 1 === Math.ceil(totalRows / limitRows),
            };
        });
    }, [currentPage, limitRows, onChange, totalRows]);
    return (React.createElement("div", { className: cn(styles.paginationContainer, className, {
            [styles.none]: totalPage < 2,
        }) },
        React.createElement("nav", { "aria-label": '...' },
            React.createElement("ul", { className: styles.pagination },
                React.createElement("li", null,
                    React.createElement("button", { type: 'button', "data-testid": 'previous', disabled: isDisabledPrev || currentPage === 1, className: cn(styles.pageItem, styles.control), tabIndex: -1, onClick: onPreviousClick },
                        React.createElement("img", { src: arrowLeft, alt: '\u0441\u0442\u0440\u0435\u043B\u043A\u0430 \u0432\u043B\u0435\u0432\u043E' }))),
                leftSkip && (React.createElement(React.Fragment, null,
                    React.createElement("li", null,
                        React.createElement("button", { type: 'button', className: styles.pageItem, onClick: createOnSelectPage(1) }, "1")),
                    listPages[0] !== 2 && React.createElement(PaginationDots, null))),
                React.createElement("div", { className: styles.pages }, listPages.map(page => (React.createElement("li", { key: page },
                    React.createElement("button", { type: 'button', disabled: currentPage === page, className: cn(styles.pageItem, {
                            [styles.pageItemActive]: currentPage === page,
                        }), onClick: createOnSelectPage(page) }, page))))),
                rightSkip && (React.createElement(React.Fragment, null,
                    listPages[listPages.length - 1] !== totalPage - 1 && React.createElement(PaginationDots, null),
                    React.createElement("li", null,
                        React.createElement("button", { type: 'button', className: styles.pageItem, onClick: createOnSelectPage(totalPage) }, totalPage)))),
                React.createElement("li", null,
                    React.createElement("button", { type: 'button', "data-testid": 'next', disabled: isDisabledNext || currentPage === totalPage, className: cn(styles.pageItem, styles.control), onClick: onNextClick },
                        React.createElement("img", { src: arrowRight, alt: '\u0441\u0442\u0440\u0435\u043B\u043A\u0430 \u0432\u043F\u0440\u0430\u0432\u043E' }))))),
        React.createElement("form", { className: styles.submit, onSubmit: setLimit },
            React.createElement("div", { className: styles.paginationLimit },
                React.createElement("p", { className: styles.paginationLimitText }, "\u0432\u044B\u0432\u043E\u0434\u0438\u0442\u044C \u043F\u043E"),
                React.createElement("input", { ref: inputRef, onFocus: onFocus, "data-testid": 'limit', value: inputValue, onChange: onInputChange, type: 'text', className: styles.paginationLimitInput }),
                React.createElement("p", { className: styles.paginationLimitText }, getElementNameByLimit(limitRows))),
            isInputLimit && (React.createElement("button", { "data-testid": 'submit', type: 'submit', className: styles.paginationLimitSubmit }, "OK")))));
};
