import { encodeBase64 } from '../eds';
const absoluteURL = (url) => {
    try {
        return new URL(url).toString();
    }
    catch {
        return `${window.origin}${url}`;
    }
};
/**
 * Добавление перехватчиков не сервисных ошибок
 * */
export const handleAPIError = (error, reportError) => {
    /** Метод и URl запроса */
    const { method, url } = error.config;
    /** Статус */
    const statusCode = error.response?.status;
    /** Контент ответа */
    const content = error.response?.data;
    const condition = method &&
        url &&
        statusCode &&
        (!content || typeof content !== 'object' || !('CorrelationId' in content)) &&
        !(statusCode === 401 && !content);
    if (condition) {
        const info = {
            method,
            statusCode,
            responseBase64: encodeBase64(content || 'empty'),
            targetUrlBase64: encodeBase64(absoluteURL(url)),
        };
        reportError(info).catch(() => {
            // eslint-disable-next-line no-console
            console.error(`Не удалось отправить ошибку`);
            // eslint-disable-next-line no-console
            console.error(info);
        });
    }
};
