import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuthContext, useDownload, useReload, } from '../../../submodules/inkh-core-frontend/src';
import { LetterDirections } from '../../../submodules/inkh-letters/src/Inkh.Letters.Api.Client.TypeScript/Models/LetterDirections.Generated';
import { LetterSorting } from '../../../submodules/inkh-letters/src/Inkh.Letters.Api.Client.TypeScript/Models/LetterSorting.Generated';
import { LetterStatuses } from '../../../submodules/inkh-letters/src/Inkh.Letters.Api.Client.TypeScript/Models/LetterStatuses.Generated';
import { CREATE_PATH } from '../../routes';
import { setRepliedLetter } from '../../store/slice/appSlice';
import { getActiveLetterTab, setActiveLetterTab } from '../../store/slice/tabSlice';
import { useAppDispatch } from '../../store/store';
import { attachmentApi, letterApi } from '../../utils/api/generalApi';
import { CategoriesForSelect, CategoryMapper, StatusMapper } from '../../utils/enum-mappers';
import { LettersListLayout } from '../../utils/general-code-import/components-import';
import { useAlert, useCustomNavigate } from '../../utils/general-code-import/config-import';
import { PLetterAttachmentsMapper, PLettersMapper, } from '../../utils/general-code-import/types-import';
import { parametersTabsLetters } from '../../utils/tabOptions';
/** Получение письма */
const getLetter = ({ id, status, category, createDate, theme, body, hasAttachments, organisationFrom, organisationTo, }) => ({
    body,
    hasAttachments,
    category: CategoryMapper[category],
    createDate,
    id,
    status,
    statusName: StatusMapper[status],
    theme,
    organizationTo: organisationTo.organisationName,
    organizationFrom: organisationFrom.organisationName,
});
/** Можно ли удалять письмо */
const canDeleteLetter = ({ status }) => status === LetterStatuses.Signed || status === LetterStatuses.Draft;
/** Получение данных для отображения вложения */
const getAttachment = (item) => ({
    realName: item.realName ?? '',
    id: item.id,
    createDate: item.createDate,
});
/** Загрузк вложения */
const loadAttachments = (letterId) => attachmentApi.getInfoByLetterId(letterId);
/** Получение данных для отображения истории */
const getHistory = (item) => ({
    dateFrom: item.dateFrom,
    username: item.userFullName,
    status: item.status,
    name: StatusMapper[item.status],
});
/** Загрузка истории письма */
const loadHistoryStatuses = (letterId) => letterApi.getStatusHistory(letterId);
/**
 * Список писем
 * Основная страница "Письма и запросы"
 * */
export const LettersList = () => {
    const dispatch = useAppDispatch();
    const navigate = useCustomNavigate();
    const activeTab = useSelector(getActiveLetterTab);
    /** Оповещения */
    const { errorAlert, successAlert } = useAlert();
    const { haveAccess } = useAuthContext();
    /** Скачать файл по ссылке */
    const download = useDownload();
    /** Загрузка вложения */
    const downloadAttachment = useCallback((id) => attachmentApi
        .downloadAttachmentLink(id)
        .then(download)
        .catch(() => errorAlert('Произошла ошибка')), [download, errorAlert]);
    /** Загрузка писем с учетом фильтрации */
    const [getLettersAndOrganizations, reload] = useReload(({ limitRows, currentPage }, search, tab, additionalFilters, dateSort) => {
        const sortingOptions = dateSort === 'asc' ? LetterSorting.CreateDateAsc : LetterSorting.CreateDateDesc;
        /** Объект фильтра */
        const letterFilter = {};
        if (additionalFilters) {
            /** Фильтрация по дате */
            if (additionalFilters.period) {
                const { period: [from, to], } = additionalFilters;
                letterFilter.createDate = {
                    from,
                    to,
                };
            }
            /** Фильтрация по категории */
            if (additionalFilters.category) {
                letterFilter.category = additionalFilters.category;
            }
            /** Фильтрация по организации */
            if (additionalFilters.organization) {
                letterFilter.organisationId = additionalFilters.organization;
            }
        }
        /** direction or draft status */
        if (tab) {
            switch (tab.key) {
                case 0:
                    letterFilter.direction = LetterDirections.Incoming;
                    break;
                case 1:
                    letterFilter.direction = LetterDirections.Outgoing;
                    break;
                default:
                    letterFilter.direction = LetterDirections.Draft;
                    break;
            }
        }
        /** text search */
        letterFilter.searchPattern = search;
        /** Запрос писем */
        const letterPromise = letterApi
            .getLetters({
            letterFilter,
            paginationParams: { pageIndex: currentPage, pageSize: limitRows },
            sortingOptions,
        })
            .then(({ count, data }) => ({ items: data, total: count }));
        /** Запрос организаций */
        const organizationPromise = letterApi
            .getOrganisationInfosByFilter({ letterFilter })
            .then(organizations => organizations.map(({ organisationName, organisationId }) => ({
            value: organisationId,
            label: organisationName,
        })));
        return [letterPromise, organizationPromise];
    }, []);
    /** Добавлние вложения */
    const addAttachment = useCallback((file, letterId) => attachmentApi
        .addAttachment({
        data: file,
        fileName: file.name,
    }, letterId)
        .then(res => {
        reload();
        return res;
    }), [reload]);
    /** Удаление вложения */
    const deleteAttachment = useCallback((attachmentId) => attachmentApi
        .delete({
        attachmentId,
    })
        .then(reload), [reload]);
    /** Удаление сообщения */
    const onConfirmDelete = useCallback((letterId) => {
        letterApi
            .delete({ letterId })
            .then(() => {
            successAlert('Письмо успешно удалено');
            reload();
        })
            .catch(() => errorAlert('Произошла ошибка'));
    }, [errorAlert, reload, successAlert]);
    /** Перейти к сообщению */
    const createNavigateByLetter = useCallback((letterId) => () => {
        if (haveAccess(PLettersMapper.View)) {
            navigate(letterId);
        }
    }, [haveAccess, navigate]);
    /** Перейти к созданию сообщения */
    const createLetter = useCallback(() => navigate(CREATE_PATH), [navigate]);
    /** Ответить на письмо */
    const createReplyHandler = useCallback((letter) => letter.direction === LetterDirections.Incoming
        ? (e) => {
            e.stopPropagation();
            dispatch(setRepliedLetter(letter));
            createLetter();
        }
        : undefined, [createLetter, dispatch]);
    /** Перейти к ссообщению, на которое ответили */
    const createReplyLetterHandler = useCallback((letter) => letter.originalLetterId
        ? (e) => {
            e.stopPropagation();
            if (letter.originalLetterId)
                navigate(letter.originalLetterId);
        }
        : undefined, [navigate]);
    /** Описание табов */
    const tabs = useMemo(() => ({
        options: parametersTabsLetters,
        activeTab,
        onChange: tab => dispatch(setActiveLetterTab(tab)),
    }), [activeTab, dispatch]);
    /** API для взаимодействия с письмами */
    const api = useMemo(() => ({
        downloadAttachment,
        getAttachment,
        loadAttachments,
        addAttachment,
        deleteAttachment,
        loadHistoryStatuses,
        onConfirmDelete,
        getHistory,
    }), [addAttachment, deleteAttachment, downloadAttachment, onConfirmDelete]);
    /** Права доступа */
    const rightsAccess = useMemo(() => ({
        createAccess: haveAccess(PLettersMapper.Create),
        deleteAccess: haveAccess(PLettersMapper.Delete),
        viewStatusHistoryAccess: haveAccess(PLettersMapper.ViewStatusHistory),
        downloadAttachmentAccess: haveAccess(PLetterAttachmentsMapper.GetBaseInfo),
        addAttachmentAccess: haveAccess(PLetterAttachmentsMapper.AddAttachment),
        deleteAttachmentAccess: haveAccess(PLetterAttachmentsMapper.DeleteAttachment),
    }), [haveAccess]);
    return (React.createElement(LettersListLayout, { createLetter: createLetter, createReplyHandler: createReplyHandler, createReplyLetterHandler: createReplyLetterHandler, getLetter: getLetter, navigateByLetterId: createNavigateByLetter, getLettersAndOrganizationOptions: getLettersAndOrganizations, tabs: tabs, categoryOptions: CategoriesForSelect, isDraft: letter => letter.status === LetterStatuses.Draft, api: api, rightsAccess: rightsAccess, canDelete: canDeleteLetter }));
};
