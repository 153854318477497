import React from 'react';
import cn from 'classnames';
import styles from './table-toolbar.module.scss';
import { Search } from '../search';
/** Тулбар таблицы */
export const TableToolbar = ({ search, left, right, leftClassName, rightClassName, searchClassName, }) => (React.createElement("div", { className: styles.configurationsContainer },
    (left || search) && (React.createElement("div", { className: cn(styles.item, leftClassName) },
        left,
        search && React.createElement(Search, { className: searchClassName, ...search }))),
    right && React.createElement("div", { className: cn(styles.item, styles.itemRight, rightClassName) }, right)));
