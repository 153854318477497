import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { SmallPreloader, useAlert } from '../../../../../submodules/inkh-core-frontend/src';
import { orderApi } from '../../../../utils/api/generalApi';
import { Popup, RectButtonUI, } from '../../../../utils/general-code-import/components-import';
import { attachmentIcon, deleteIconDark, } from '../../../../utils/general-code-import/images-import';
import styles from './import-payment-popup.module.scss';
import { extractErrorImportFileMessage } from '../../../../utils/general-code-import/config-import';
/**
 * Диалог импора платежа
 * */
export const ImportPaymentPopup = ({ onClose: onCloseExternal = () => { }, onImportDone, ...rest }) => {
    /** Файлы */
    const [files, setFiles] = useState([]);
    /** Файлы с флагами для отрисовки состояний */
    const [filesWithFlags, setFilesWithFlags] = useState([]);
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** Флаг показания результатов загрузки файлов */
    const [isResults, setResults] = useState(false);
    const [amountSuccess, setAmountSuccess] = useState(0);
    const [amountError, setAmountError] = useState(0);
    const { errorAlert, successAlert } = useAlert();
    /** Выбор файла */
    const onFileChoose = useCallback((event) => {
        if (event.target.files && event.target.files.length) {
            const { target: { files }, } = event;
            setFiles(prev => [...files, ...prev]);
        }
    }, []);
    /** Сброс файла */
    const onClear = useCallback((e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setFiles([]);
        setFilesWithFlags([]);
        setAmountError(0);
        setAmountSuccess(0);
    }, []);
    /** Отмена и закрытие диалогового окна */
    const onClose = useCallback(() => {
        onClear();
        onCloseExternal();
        setResults(false);
    }, [onCloseExternal, onClear]);
    /** Удаление одного файла */
    const onDeleteFile = useCallback((file) => () => setFiles(prev => prev.filter(item => item !== file)), []);
    /** Импорт файлов */
    const onImport = useCallback(() => {
        setLoading(true);
        if (files.length) {
            files.forEach(file => {
                const fileWithFlags = {
                    data: file,
                    isError: false,
                    isLoading: true,
                    isSuccess: false,
                };
                setFilesWithFlags(prev => [...prev, fileWithFlags]);
                setResults(true);
                orderApi
                    .importFrom1C({ data: file, fileName: file.name ?? 'Платеж из 1C' })
                    .then(() => {
                    successAlert('Файл успешно импортирован');
                    fileWithFlags.isSuccess = true;
                    onImportDone();
                    setAmountSuccess(prev => prev + 1);
                })
                    .catch((error) => {
                    fileWithFlags.isError = true;
                    fileWithFlags.isLoading = false;
                    setAmountError(prev => prev + 1);
                    errorAlert(extractErrorImportFileMessage(error), false);
                })
                    .finally(() => {
                    fileWithFlags.isLoading = false;
                    setLoading(false);
                    setFilesWithFlags(prev => [...prev]);
                });
            });
        }
    }, [errorAlert, files, onImportDone, successAlert]);
    const buttons = (React.createElement(React.Fragment, null,
        React.createElement("label", null,
            React.createElement("input", { multiple: true, disabled: loading, onChange: onFileChoose, className: styles.hide, type: 'file' }),
            React.createElement("div", { className: cn(styles.fileUpload, {
                    [styles.noLoading]: !loading && !files.length,
                }) },
                React.createElement("div", null,
                    React.createElement("img", { className: styles.loadFileIcon, src: attachmentIcon, alt: '\u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0430' }),
                    "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0444\u0430\u0439\u043B\u044B"))),
        React.createElement(RectButtonUI, { disabled: loading || !files.length, onClick: onImport }, "\u0418\u043C\u043F\u043E\u0440\u0442"),
        React.createElement(RectButtonUI, { disabled: loading, onClick: onClose, styleType: 'line' }, "\u041E\u0442\u043C\u0435\u043D\u0430")));
    const amount = (React.createElement("div", { className: styles.amountContainer },
        React.createElement("p", { className: styles.amountText }, "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u0439:"),
        amountSuccess > 0 && React.createElement("span", { className: styles.amount }, amountSuccess),
        amountError > 0 && (React.createElement("span", { className: cn(styles.amount, styles.amountError) }, amountError))));
    return (React.createElement(Popup, { ...rest, closeOnDocumentClick: !loading, closeOnEscape: !loading, onClose: onClose, footer: isResults ? amount : buttons, header: isResults ? 'Результаты импорта платежей' : 'Импорт платежей из 1С' },
        files.length && !isResults ? (React.createElement("ul", null, files.map(file => (React.createElement("li", { key: file.lastModified, className: cn(styles.fileItem, {
                [styles.noLoading]: !loading && !files.length,
            }) },
            React.createElement("div", null,
                React.createElement("img", { onClick: onDeleteFile(file), className: styles.deleteFileIcon, src: deleteIconDark, alt: '\u0443\u0434\u0430\u043B\u0435\u043D\u0438\u0435' }),
                file.name),
            loading && React.createElement(SmallPreloader, null)))))) : (!isResults && React.createElement("div", { className: styles.empty }, "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u0432\u044B\u0431\u0440\u0430\u043D\u043E")),
        isResults && (React.createElement("ul", null, filesWithFlags.map(file => (React.createElement("li", { key: file.data.lastModified, className: styles.fileResultsContainer },
            React.createElement("div", { className: styles.fileResults },
                file.data.name,
                file.isSuccess && !file.isLoading && (React.createElement("div", { className: styles.smallContainer },
                    React.createElement("span", { className: cn(styles.icon, styles.iconSuccess) }, "\u2713"))),
                file.isError && !file.isLoading && (React.createElement("div", { className: styles.smallContainer },
                    React.createElement("span", { className: cn(styles.icon, styles.iconError) }, "!"))),
                file.isLoading && (React.createElement("div", { className: styles.smallContainer },
                    React.createElement(SmallPreloader, null)))),
            file.isError && !file.isLoading && React.createElement("span", { className: styles.textError }, "\u041E\u0448\u0438\u0431\u043A\u0430"))))))));
};
