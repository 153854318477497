import React, { useState, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { debounce } from 'throttle-debounce';
import styles from './select-input-ui.module.scss';
import deleteIcon from '../../static/images/icons/close-green.svg';
import arrowUpIcon from '../../static/images/icons/arrowUpSmallGreen.svg';
import { useAlert } from '../alert-context';
import { SmallPreloader } from '../small-preloader';
/** Задержка поиска в мс */
const SEARCH_SELECT_DELAY = 300;
/** Максимальное колличество получаемых значений */
const SELECT_LIST_SIZE = 100;
/**
 * Элемент ввода селекта
 * */
export function SelectInputUI({ placeholder = '', onChange, options, value, isClearable, openedDropdown, handleOpened, isOpened, children, setFilteredOptions, disabled, inputRef, limit = SELECT_LIST_SIZE, }) {
    /** Оповещения */
    const { errorAlert } = useAlert();
    /** Значение поиска */
    const [searchValue, setSearchValue] = useState('');
    /** Индикатор ззагрузки */
    const [isLoading, setLoading] = useState(false);
    /** Обработка поиска по опциям */
    const debounceSearch = useMemo(() => debounce(SEARCH_SELECT_DELAY, (searchValue) => {
        /** Передан массив */
        if (Array.isArray(options)) {
            setFilteredOptions([...options]);
            const filteredArray = options.filter(el => el.label?.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredOptions(filteredArray);
        }
        else if (typeof options === 'function') {
            /** Передана функция */
            const getOptionsRes = options(searchValue, limit);
            /** Асинхронный ответ */
            setLoading(true);
            getOptionsRes
                .then(setFilteredOptions)
                .catch(() => errorAlert('Произошла ошибка'))
                .finally(() => setLoading(false));
        }
    }), [errorAlert, limit, options, setFilteredOptions]);
    /** Установка значений при первом рендере */
    useEffect(() => debounceSearch(''), [debounceSearch]);
    /** Установка значения поиска */
    const onSearch = useMemo(() => ({ target: { value: text } }) => {
        if (value) {
            onChange(null);
        }
        setSearchValue(text);
        debounceSearch(text);
    }, [debounceSearch, onChange, value]);
    /** Обработка очистки поля ввода */
    const onClear = useCallback(() => {
        setSearchValue('');
        onChange(null);
        debounceSearch('');
    }, [debounceSearch, onChange]);
    return (React.createElement("div", { className: cn(styles.select, {
            [styles.selectDisabled]: disabled,
        }) },
        React.createElement("div", { className: styles.selectInputContainer },
            React.createElement("input", { ref: inputRef, value: value ? value.label : searchValue, disabled: disabled, onChange: onSearch, placeholder: placeholder, className: cn(styles.selectInput, {
                    [styles.selectInputDisabled]: disabled,
                    [styles.overflowValue]: value,
                }), onClick: handleOpened, onKeyDown: openedDropdown }),
            React.createElement("div", { className: styles.iconContainer },
                isLoading && React.createElement(SmallPreloader, null),
                !disabled && isClearable && (value || searchValue) && !isLoading && (React.createElement("img", { onClick: onClear, className: cn(styles.iconTableImage, styles.clear), src: deleteIcon, alt: '\u043E\u0447\u0438\u0441\u0442\u043A\u0430' })),
                !disabled && !isLoading && (React.createElement("img", { className: cn(styles.iconTableImage, { [styles.down]: !isOpened }), src: arrowUpIcon, alt: '\u0441\u0442\u0440\u0435\u043B\u043A\u0430' })))),
        children));
}
