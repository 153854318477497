import cn from 'classnames';
import React, { useCallback } from 'react';
import styles from './checkbox-ui.module.scss';
export const CheckboxUI = ({ disabled, checked, className, stopPropagationOnClick, onClick: onClickExternal, ...rest }) => {
    const spanOnClick = useCallback((e) => {
        if (stopPropagationOnClick)
            e.stopPropagation();
    }, [stopPropagationOnClick]);
    const inputOnClick = useCallback(e => {
        if (stopPropagationOnClick)
            e.stopPropagation();
        if (onClickExternal)
            onClickExternal(e);
    }, [onClickExternal, stopPropagationOnClick]);
    return (React.createElement("label", { className: cn(styles.checkboxLabel, className) },
        React.createElement("input", { "data-testid": 'checkbox', onClick: inputOnClick, disabled: disabled, checked: checked, className: styles.checkboxInput, type: 'checkbox', ...rest }),
        React.createElement("span", { "data-testid": 'span', onClick: spanOnClick, className: styles.checkBox })));
};
