import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import comeBack from '../../static/images/icons/come-back.svg';
import styles from './history-navigation.module.scss';
import { useCustomNavigate } from '../../utils/hooks/custom-navigate';
export const HistoryNavigation = ({ renderMenu, locationToName, }) => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const onBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);
    const locationState = useMemo(() => location.state, [location.state]);
    const hidden = !locationState;
    const getTitle = useCallback(() => {
        if (locationState)
            return locationToName(locationState);
        return undefined;
    }, [locationState, locationToName]);
    return (React.createElement("div", { className: cn(styles.historyNavigation, {
            [styles.historyNavigationHidden]: hidden,
        }) }, renderMenu && (React.createElement("div", { className: styles.comeBack, onClick: onBack },
        React.createElement("img", { className: styles.comeBackIcon, src: comeBack, alt: '\u0438\u043A\u043E\u043D\u043A\u0430' }),
        React.createElement("p", { className: styles.comeBackText }, getTitle())))));
};
