export var PopupValues;
(function (PopupValues) {
    PopupValues["STATUS"] = "STATUS";
    PopupValues["IMPORT"] = "IMPORT";
})(PopupValues || (PopupValues = {}));
/** Режим кнопки массовых действий */
export var MassiveButtonMode;
(function (MassiveButtonMode) {
    MassiveButtonMode[MassiveButtonMode["None"] = 0] = "None";
    MassiveButtonMode[MassiveButtonMode["NewOrders"] = 1] = "NewOrders";
    MassiveButtonMode[MassiveButtonMode["SignedOrders"] = 2] = "SignedOrders";
})(MassiveButtonMode || (MassiveButtonMode = {}));
/** Режимы работы диалога подписания */
export var SignPopupMode;
(function (SignPopupMode) {
    SignPopupMode["Sign"] = "Sign";
    SignPopupMode["SignAndSend"] = "SignAndSend";
})(SignPopupMode || (SignPopupMode = {}));
