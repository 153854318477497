export var PPayDocuments;
(function (PPayDocuments) {
    PPayDocuments["CreateSend"] = "CreateSend";
    PPayDocuments["Create"] = "Create";
    PPayDocuments["CreateAndSign"] = "CreateAndSign";
    PPayDocuments["ViewList"] = "ViewList";
    PPayDocuments["StatusHistory"] = "StatusHistory";
    PPayDocuments["SrvDictionaryStatus"] = "SrvDictionaryStatus";
    PPayDocuments["ExportPdf"] = "ExportPdf";
    PPayDocuments["ImportFrom1c"] = "ImportFrom1c";
    PPayDocuments["SaveSendSigned"] = "SaveSendSigned";
    PPayDocuments["View"] = "View";
    PPayDocuments["Delete"] = "Delete";
    PPayDocuments["SaveSignedOrder"] = "SaveSignedOrder";
    PPayDocuments["SendSigned"] = "SendSigned";
    PPayDocuments["Edit"] = "Edit";
    PPayDocuments["RequestPaymentStatus"] = "RequestPaymentStatus";
})(PPayDocuments || (PPayDocuments = {}));
/** Словарь, описывающий все значния из енума с правами */
export const PPayDocumentsMapper = {
    [PPayDocuments.CreateSend]: { name: 'documents_ppaydocuments_createsend', description: 'Создать, отправить' },
    [PPayDocuments.Create]: { name: 'documents_ppaydocuments_create', description: 'Созать' },
    [PPayDocuments.CreateAndSign]: { name: 'documents_ppaydocuments_createandsign', description: 'Создать, подписать' },
    [PPayDocuments.ViewList]: { name: 'documents_ppaydocuments_viewlist', description: 'Список платежей' },
    [PPayDocuments.StatusHistory]: { name: 'documents_ppaydocuments_statushistory', description: 'История статусов' },
    [PPayDocuments.SrvDictionaryStatus]: { name: 'documents_ppaydocuments_srvdictionarystatus', description: 'SRV статусы' },
    [PPayDocuments.ExportPdf]: { name: 'documents_ppaydocuments_exportpdf', description: 'Экспорт в PDF' },
    [PPayDocuments.ImportFrom1c]: { name: 'documents_ppaydocuments_importfrom1c', description: 'Импортировать из 1с' },
    [PPayDocuments.SaveSendSigned]: { name: 'documents_ppaydocuments_savesendsigned', description: 'Соранить, отправить подписанное' },
    [PPayDocuments.View]: { name: 'documents_ppaydocuments_view', description: 'Просмотр' },
    [PPayDocuments.Delete]: { name: 'documents_ppaydocuments_delete', description: 'Удалить' },
    [PPayDocuments.SaveSignedOrder]: { name: 'documents_ppaydocuments_savesignedorder', description: 'Сохранить подписанное сообщение' },
    [PPayDocuments.SendSigned]: { name: 'documents_ppaydocuments_sendsigned', description: 'Отправить' },
    [PPayDocuments.Edit]: { name: 'documents_ppaydocuments_edit', description: 'Редактировать' },
    [PPayDocuments.RequestPaymentStatus]: { name: 'documents_ppaydocuments_requestpaymentstatus', description: 'Запросить статус платежа' }
};
