export var PStatements;
(function (PStatements) {
    PStatements["ExportToPDF"] = "ExportToPDF";
    PStatements["ExportTo1c101"] = "ExportTo1c101";
    PStatements["ExportTo1c102"] = "ExportTo1c102";
    PStatements["ExportToMT940"] = "ExportToMT940";
    PStatements["ExportToXlsx"] = "ExportToXlsx";
    PStatements["RequestStatement"] = "RequestStatement";
})(PStatements || (PStatements = {}));
/** Словарь, описывающий все значния из енума с правами */
export const PStatementsMapper = {
    [PStatements.ExportToPDF]: { name: 'statements_pstatements_exporttopdf', description: 'Отчет в PDF' },
    [PStatements.ExportTo1c101]: { name: 'statements_pstatements_exportto1c101', description: 'Отчет в 1c 1.01' },
    [PStatements.ExportTo1c102]: { name: 'statements_pstatements_exportto1c102', description: 'Отчет в 1c 1.02' },
    [PStatements.ExportToMT940]: { name: 'statements_pstatements_exporttomt940', description: 'Отчет в MT940' },
    [PStatements.ExportToXlsx]: { name: 'statements_pstatements_exporttoxlsx', description: 'Отчет в XLSX' },
    [PStatements.RequestStatement]: { name: 'statements_pstatements_requeststatement', description: 'Запрос выписки' }
};
