import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { CheckboxUI } from '../checkbox-ui';
import { Pagination } from '../pagination';
import { Preloader } from '../preloader';
import { TableToolbar } from '../table-toolbar';
import { Tabs } from '../tabs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from './table-components';
import tableStyles from './table-styles.module.scss';
/** Чекбокс строки выбора */
function CheckboxCell(item, checked, setChecked) {
    const onChange = ({ target: { checked } }) => {
        if (checked) {
            setChecked(prev => [item, ...prev]);
        }
        else {
            setChecked(prev => prev.filter(i => i !== item));
        }
    };
    return (React.createElement(CheckboxUI, { "data-testid": 'check', stopPropagationOnClick: true, checked: checked, onChange: onChange }));
}
/**
 * Обобщенная таблица
 * */
export function GenericTable({ getItems, columns: columnsExternal, onLoadError, getItemKey, rowProps, pagination = false, rowHoverTransition = true, toolbar, loading: loadingExternal = false, tabs, tableContainerProps, rowCheckProps, paginationRef, }) {
    /** Состояние загрузки */
    const [loading, setLoading] = useState(loadingExternal);
    /** Текущая выборка */
    const [currentItems, setCurrentItems] = useState([]);
    /** Текстовый поиск */
    const [search, setSearch] = useState();
    /** Суммарное сосояние загрузки */
    const summaryLoading = loading || loadingExternal;
    /** Активный таб */
    const [activeTab, setActiveTab] = useState(tabs?.activeTab ?? tabs?.options.at(0));
    /** Общее число элементов, удовлетворяющих выборке */
    const [totalRows, setTotalRows] = useState(0);
    /** Состояние началной загрузки */
    const [firstLoad, setFirstLoad] = useState(true);
    /** Колонки */
    const columns = useMemo(() => {
        if (rowCheckProps) {
            const { checked, setChecked, selectAllCheck } = rowCheckProps;
            const checkColumn = {
                key: 'check-column',
                cellProps: () => ({
                    className: tableStyles.extraSmall,
                    align: 'center',
                }),
                headCellProps: {
                    className: cn(tableStyles.extraSmall, { [tableStyles.checkPadding]: selectAllCheck }),
                    align: 'center',
                },
                title: selectAllCheck && (React.createElement(CheckboxUI, { checked: currentItems.length === checked.length && checked.length !== 0, onChange: ({ target: { checked } }) => {
                        if (checked) {
                            setChecked(() => [...currentItems]);
                        }
                        else {
                            setChecked(() => []);
                        }
                    } })),
                cellContent: item => CheckboxCell(item, checked.includes(item), setChecked),
            };
            return [checkColumn, ...columnsExternal];
        }
        return columnsExternal;
    }, [columnsExternal, currentItems, rowCheckProps]);
    /** Мемоизация пропсов поиска */
    const searchProps = useMemo(() => 
    /** Только если поиск определен */
    toolbar?.search
        ? {
            onSearch: setSearch,
            ...toolbar?.search,
        }
        : undefined, [toolbar]);
    /** Данные постраничного вывода */
    const [paginationData, setPaginationData] = useState({
        currentPage: 1,
        limitRows: 7,
    });
    useEffect(() => {
        if (paginationRef) {
            paginationRef.current = setPaginationData;
        }
        return () => {
            if (paginationRef) {
                paginationRef.current = null;
            }
        };
    }, [paginationRef]);
    /**  Ссылка на getItems, activeTab и search, для отслеживания измнений */
    const resetPaginationData = useRef({ getItems, search, activeTab });
    /** Обновление коллеции при изменении как-либо параметров */
    useEffect(() => {
        /** Состояние монтирования комнонента */
        let isMounted = true;
        /** Сброс состояния монтирования */
        const resetMounted = () => {
            isMounted = false;
        };
        /** Сброс на первую страницу при смене пераметров */
        if (getItems !== resetPaginationData.current.getItems ||
            search !== resetPaginationData.current.search ||
            activeTab !== resetPaginationData.current.activeTab) {
            setPaginationData(prev => ({
                ...prev,
                currentPage: 1,
            }));
            return resetMounted;
        }
        /** Передан массив без общего кол-ва элементов */
        if (Array.isArray(getItems)) {
            setCurrentItems(getItems);
            setTotalRows(getItems.length);
            setFirstLoad(false);
        }
        else if (typeof getItems === 'object') {
            /** Передан массив с кол-вом элементов */
            const { total, items } = getItems;
            setTotalRows(total);
            setCurrentItems(items);
            setFirstLoad(false);
        }
        else if (typeof getItems === 'function') {
            /** Передана функция */
            const getItemsRes = getItems(paginationData, search, activeTab);
            /** Асинхронный ответ */
            if (getItemsRes instanceof Promise) {
                setLoading(true);
                getItemsRes
                    .then(res => {
                    /** Выполнить если актуально */
                    if (isMounted) {
                        /** Вернулся массив */
                        if (Array.isArray(res)) {
                            setCurrentItems(res);
                            setTotalRows(res.length);
                        }
                        else {
                            /** Вернулся массив и кол-во элементов */
                            const { total, items } = res;
                            setCurrentItems(items);
                            setTotalRows(total);
                        }
                    }
                })
                    .catch((e) => {
                    /** Выполнить если актуально */
                    if (isMounted && onLoadError)
                        onLoadError(e);
                })
                    .finally(() => {
                    /** Выполнить если актуально */
                    if (isMounted) {
                        setLoading(false);
                        setFirstLoad(false);
                    }
                });
            }
            else if (Array.isArray(getItemsRes)) {
                /** Синхронный ответ - вернулся массив */
                setCurrentItems(getItemsRes);
                setTotalRows(getItemsRes.length);
                setFirstLoad(false);
            }
            else {
                /** Синхронный ответ - вернулся массив и кол-во элементов */
                const { total, items } = getItemsRes;
                setCurrentItems(items);
                setTotalRows(total);
                setFirstLoad(false);
            }
        }
        /** Пометить компонент размонтированным, чтобы не выполнялись неактуальные обработчики */
        return resetMounted;
    }, [activeTab, getItems, paginationData, onLoadError, search]);
    /** Установка ссылки на текущие параметры */
    useEffect(() => {
        resetPaginationData.current = {
            getItems,
            search,
            activeTab,
        };
    });
    /** Ячейки заголовков таблицы */
    const headerCells = useMemo(() => columns.map(({ title, headCellProps, key }) => (React.createElement(TableCell, { key: key, ...headCellProps }, title))), [columns]);
    /** Смена активного таба */
    const onChangeTab = useCallback((tab) => {
        setActiveTab(tab);
        if (tabs)
            tabs.onChange(tab);
    }, [tabs]);
    return (React.createElement(React.Fragment, null,
        tabs && React.createElement(Tabs, { ...tabs, activeTab: activeTab, onChange: onChangeTab }),
        React.createElement(TableContainer, { ...tableContainerProps, className: cn(tableStyles.commonContainer, tableContainerProps?.className) },
            summaryLoading && (React.createElement("div", { "data-testid": 'loading', className: tableStyles.preloader },
                React.createElement(Preloader, null))),
            toolbar && (React.createElement(TableToolbar, { leftClassName: toolbar.leftClassName, rightClassName: toolbar.rightClassName, left: toolbar.left, right: toolbar.right, search: searchProps })),
            React.createElement(Table, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, headerCells)),
                React.createElement(TableBody, null, currentItems.length ? (currentItems.map(item => (React.createElement(TableRow, { tableRowTransition: rowHoverTransition, ...(rowProps && rowProps(item)), key: getItemKey(item) }, columns.map(({ cellContent, key, cellProps }) => {
                    const props = (cellProps && cellProps(item)) || {};
                    return (React.createElement(TableCell, { ...props, className: cn(tableStyles.tableCellBody, props?.className), key: key }, cellContent && cellContent(item)));
                }))))) : (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: columns.length },
                        React.createElement("div", { className: tableStyles.empty }, !firstLoad && 'Пусто :('))))))),
        pagination && (React.createElement(Pagination, { ...pagination, className: cn(pagination.className, summaryLoading ? tableStyles.hidden : undefined), onChange: setPaginationData, totalRows: totalRows, ...paginationData }))));
}
