import React, { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import styles from './auth.module.scss';
import { goToAuth } from '../../utils/auth';
import { Preloader } from '../preloader';
/** Контекст для проверки и прокидывания данных авторизации */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Context = createContext({});
/** Хук по использованию контекта внутри приложения */
export function useAuthContext() {
    return useContext(Context);
}
/** Логирование вызова метода access */
const logAccessStack = (name, access, stack = '') => {
    // eslint-disable-next-line no-console
    console.group(`PERMISSION: ${name}; ACCESS: ${String(access)}`);
    // eslint-disable-next-line no-console
    console.log(stack.split('\n').slice(1, 3).join('\n'));
    // eslint-disable-next-line no-console
    console.groupEnd();
};
/**
 * При инициализации приложения рефрешит токен,
 * тем самым подтверждая авторизацию или отправляет на страницу логина.
 * Помимо этого в контекст устанавливаются доступные права.
 * */
export function AuthContext({ children, getUserInfo, refreshToken, }) {
    /** Данные авторизации, которые доступны через контекст */
    const [authData, setAuthData] = useState();
    /** Кэшированное соответствие енама и его значений */
    const PermissionMapper = useMemo(() => new Map(), []);
    useEffect(() => {
        getUserInfo()
            /** Установка данных авторизации */
            .then(info => {
            const auth = {
                ...info,
                refreshToken,
                haveAccess({ name }) {
                    const { permissions } = info;
                    /** Проверяется в кэше или вычисляется */
                    const access = PermissionMapper.get(name) ?? permissions.includes(name);
                    /** Кэширование результата выисления доступа */
                    if (!PermissionMapper.has(name))
                        PermissionMapper.set(name, access);
                    if (IS_DEV) {
                        logAccessStack(name, access, Error().stack);
                    }
                    return access;
                },
            };
            setAuthData(auth);
        })
            /** Отправка на страницу авторизации */
            .catch(goToAuth);
    }, [refreshToken, getUserInfo, PermissionMapper]);
    return !authData ? (React.createElement("div", { className: styles.container },
        React.createElement(Preloader, null))) : (React.createElement(Context.Provider, { value: authData }, children));
}
