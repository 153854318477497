import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { ConfirmDeletePopup, fromPluralDescriptor, MultiSignPopup, SmallPreloader, Tooltip, usePaginationRef, useReload, } from '../../../submodules/inkh-core-frontend/src';
import { PPayDocumentsMapper } from '../../../submodules/inkh-documents/src/Inkh.Documents.Api.Client.TypeScript/Permissions/PPayDocuments.Generated';
import { CREATE_PATH } from '../../routes';
import { OrderStatuses } from '../../utils/api/apiModels';
import { orderApi } from '../../utils/api/generalApi';
import { PaymentStatusSelectOptions, StatusSelectOptionPaymentMapper, } from '../../utils/enum-mappers';
import { DateInputRange, DropdownButton, GenericTable, PageTitle, RectButtonUI, SelectUI, } from '../../utils/general-code-import/components-import';
import { formatDate, toFormatMoney, useAlert, useAuthContext, useCustomNavigate, useDownload, } from '../../utils/general-code-import/config-import';
import { documentsIcon, pdfIcon } from '../../utils/general-code-import/images-import';
import { stylesMain, tableStyles } from '../../utils/general-code-import/styles-import';
import styles from './payments-list.module.scss';
import { HistoryStatusPaymentPopup } from './popups/history-status-popup-payment';
import { ImportPaymentPopup } from './popups/import-payment-popup';
import { MassiveButtonMode, PopupValues, SignPopupMode } from './types';
const DocumentCell = ({ date, number }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: cn(stylesMain.tableTextHead, styles.marginHead) }, formatDate(date)),
    React.createElement("p", { className: stylesMain.tableTextBody }, `№ ${number}`)));
const PayerCell = ({ payerName, payerAccountNumber }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, payerName),
    React.createElement("p", { className: stylesMain.tableTextBody }, payerAccountNumber)));
const RecipientCell = ({ recipientName, recipientAccountNumber }) => (React.createElement(React.Fragment, null,
    React.createElement("p", { className: stylesMain.tableTextHead }, recipientName),
    React.createElement("p", { className: stylesMain.tableTextBody }, recipientAccountNumber)));
const SumCell = ({ amount }) => (React.createElement("p", { className: stylesMain.tableTextHead },
    toFormatMoney(amount),
    React.createElement("span", { className: stylesMain.tableTextBody }, " \u20BD")));
const StatusCell = (status, onClick) => (React.createElement("span", { onClick: onClick, className: styles.statusContainer }, status ? StatusSelectOptionPaymentMapper[status].label : ''));
const PurposeCell = ({ purpose }) => (React.createElement("p", { className: stylesMain.tableTextBodyWrap }, purpose));
export const ExportPdfCell = (itemId, currentDownloading, onDownload, downloadLoading, render = true) => render && (React.createElement("button", { disabled: downloadLoading, onClick: onDownload(itemId), type: 'button', className: styles.exportButton },
    downloadLoading && currentDownloading === itemId ? (React.createElement("div", { className: styles.preloaderContainer },
        React.createElement(SmallPreloader, null))) : (React.createElement("img", { className: styles.buttonIcon, src: pdfIcon, alt: 'pdf \u0444\u043E\u0440\u043C\u0430\u0442' })),
    React.createElement("span", { className: styles.textButton }, "PDF")));
const getItemKey = (item) => item.id;
const pluralDescriptor = {
    one: 'платеж',
    few: 'платежа',
    many: 'платежей',
};
const pagination = {
    nameElement: pluralDescriptor,
};
/** Сообщение при ошибке подписания */
const getErrorMessage = ({ number }) => `Ошибка при подписании платежа № ${number}`;
/**
 * Список платежей
 * Основная страница "Платежи"
 * */
export const PaymentsList = () => {
    const navigate = useCustomNavigate();
    const { errorAlert, successAlert } = useAlert();
    const { haveAccess, userCertificateThumbprints = [] } = useAuthContext();
    /** Фильтрация доступных сертификатов */
    const certificatePredicate = useCallback((cert) => userCertificateThumbprints.includes(cert.thumbprint), [userCertificateThumbprints]);
    /** id выбранного платежа */
    const [paymentId, setPaymentId] = useState('');
    const [paymentsPopupOpened, setPaymentsPopupOpened] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(OrderStatuses.New);
    /** Выбранные элементы */
    const [checked, setChecked] = useState([]);
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** Состояние скачивания */
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadOrderId, setDownloadOrderId] = useState(null);
    /** Значения фильтров */
    const [range, setRange] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const download = useDownload();
    /** ссылка на функцию управления пагинацией */
    const paginationRef = usePaginationRef();
    /** Скачивание платежки в формате pdf */
    const onDownload = useCallback((id) => (e) => {
        e.stopPropagation();
        setDownloadLoading(true);
        setDownloadOrderId(id);
        orderApi
            .getPdfLink2(id)
            .then(download)
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setDownloadLoading(false);
            setDownloadOrderId(null);
        });
    }, [download, errorAlert]);
    /** Обработчик закрытия попапа */
    const onClosePopup = useCallback(() => setPaymentsPopupOpened(''), []);
    /** Переход на страницу создания платежа */
    const onBeginCreate = useCallback(() => navigate(CREATE_PATH), [navigate]);
    /** Импорт платежа */
    const onImport = useCallback(() => setPaymentsPopupOpened(PopupValues.IMPORT), []);
    /** Открытие попапа по истории изменения статуса */
    const creteStatusHistoryHandler = useCallback((payment) => (e) => {
        if (haveAccess(PPayDocumentsMapper.StatusHistory)) {
            e.stopPropagation();
            setPaymentId(payment.id);
            setPaymentStatus(payment.status);
            setPaymentsPopupOpened(PopupValues.STATUS);
        }
    }, [haveAccess]);
    const [loadItems, reload] = useReload(({ limitRows, currentPage }) => orderApi
        .getOrders({
        filter: {
            status: filterStatus ? filterStatus.value : undefined,
            date: range
                ? {
                    from: range[0],
                    to: range[1],
                }
                : undefined,
        },
        paginationParams: {
            pageIndex: currentPage,
            pageSize: limitRows,
        },
    })
        .then(({ data: items, count: total }) => ({
        items,
        total,
    })), [filterStatus, range]);
    const columns = useMemo(() => [
        {
            key: 1,
        },
        {
            key: 2,
            title: 'документ',
            cellContent: DocumentCell,
        },
        {
            key: 3,
            title: 'плательщик',
            cellContent: PayerCell,
            headCellProps: {
                className: tableStyles.medium,
            },
        },
        {
            key: 4,
            title: 'получатель',
            cellContent: RecipientCell,
            headCellProps: {
                className: tableStyles.medium,
            },
        },
        {
            key: 5,
            title: 'сумма',
            cellContent: SumCell,
            headCellProps: {
                className: tableStyles.medium,
            },
        },
        {
            key: 6,
            title: 'статус',
            cellContent: item => StatusCell(item.status, creteStatusHistoryHandler(item)),
        },
        {
            key: 7,
            title: 'назначение платежа',
            cellContent: PurposeCell,
        },
        {
            key: 8,
            cellContent: item => haveAccess(PPayDocumentsMapper.ExportPdf) &&
                ExportPdfCell(item.id, downloadOrderId, onDownload, downloadLoading, item.isPDFExportable),
        },
    ], [creteStatusHistoryHandler, downloadLoading, downloadOrderId, haveAccess, onDownload]);
    const onLoadError = useCallback(() => errorAlert('Ошибка во время загрузки'), [errorAlert]);
    /** Навигация по идентификатору */
    const navigateById = useCallback((itemId) => () => {
        if (haveAccess(PPayDocumentsMapper.View)) {
            navigate(itemId);
        }
    }, [haveAccess, navigate]);
    /** Запросить все неподписанные */
    const onRequestUnsigned = useCallback(() => {
        if (paginationRef.current)
            paginationRef.current({ currentPage: 1, limitRows: 500 });
        setFilterStatus(PaymentStatusSelectOptions.find(({ value }) => OrderStatuses.New === value) ?? null);
        setRange(null);
        setChecked([]);
    }, [paginationRef]);
    /** Тулбар с фильтрами */
    const toolbar = useMemo(() => ({
        left: (React.createElement("div", { className: styles.filterContainer },
            React.createElement(SelectUI, { withErrorText: false, isClearable: true, getValue: d => d.value, options: PaymentStatusSelectOptions, title: '\u0421\u0442\u0430\u0442\u0443\u0441', onChange: setFilterStatus, value: filterStatus }),
            React.createElement(DateInputRange, { isClearable: true, value: range, onChange: setRange, title: '\u0414\u0430\u0442\u0430 \u0438\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u0430' }))),
        right: (React.createElement(Tooltip, { tooltipText: '\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u043D\u0435\u043F\u043E\u0434\u043F\u0438\u0441\u0430\u043D\u043D\u044B\u0435' },
            React.createElement("button", { type: 'button', onClick: onRequestUnsigned },
                React.createElement("img", { className: styles.actionButton, src: documentsIcon, alt: '\u0412\u0441\u0435 \u043D\u0435\u043F\u043E\u0434\u043F\u0438\u0441\u0430\u043D\u043D\u044B\u0435' })))),
    }), [filterStatus, onRequestUnsigned, range]);
    /** Свойства строки */
    const rowProps = useCallback(item => ({ onClick: navigateById(item.id) }), [navigateById]);
    /** Свойства колонки с чекбоксами */
    const rowCheckProps = useMemo(() => ({
        checked,
        setChecked,
        selectAllCheck: true,
    }), [checked]);
    /** Состояние открытия диалога массового удаления */
    const [isDelete, setDelete] = useState(false);
    /** Начало массового удаления */
    const onBeginMassiveDelete = useCallback(() => setDelete(true), []);
    /** Закрытие диалога подтверждения на удаление */
    const closeDeletePopup = useCallback(() => setDelete(false), []);
    /** Состояние открытия диалога подписания */
    const [signPopupOpenMode, setSignPopupOpenMode] = useState(null);
    /** Отмена подписания */
    const onCancelSign = useCallback(() => setSignPopupOpenMode(null), []);
    /** Подписать */
    const onSign = useCallback(() => setSignPopupOpenMode(SignPopupMode.Sign), []);
    /** Подписать и отправить */
    const onSignAndSend = useCallback(() => setSignPopupOpenMode(SignPopupMode.SignAndSend), []);
    /** Завершение подписания */
    const onSignComplete = useCallback((result) => {
        setSignPopupOpenMode(null);
        setLoading(true);
        setChecked([]);
        const signResults = result.map(item => {
            switch (item.status) {
                case 'fulfilled':
                    const { value: { signature, base64, withCertInfo }, } = item;
                    const { bodyData: { id, number }, } = withCertInfo;
                    return (signPopupOpenMode === SignPopupMode.Sign
                        ? orderApi.saveSignedOrder.bind(orderApi)
                        : orderApi.saveAndSendSignedOrder.bind(orderApi))({
                        orderId: id,
                        signedOrderData: {
                            body: base64,
                            signature,
                            rawBody: withCertInfo,
                        },
                    })
                        .then(() => successAlert(`Платеж № ${number} успешно подписан${signPopupOpenMode === SignPopupMode.SignAndSend ? ' и отправлен' : ''}`))
                        .catch(() => errorAlert(`Ошибка при подписании и отправке платежа № ${number}`));
                default:
                    return Promise.resolve();
            }
        });
        /** Перезагрузка коллекции */
        Promise.allSettled(signResults).finally(() => {
            setLoading(false);
            reload();
        });
    }, [errorAlert, reload, signPopupOpenMode, successAlert]);
    /** Получение данных для подписания */
    const getDataForSign = useCallback(() => Promise.all(checked.map(({ id }) => orderApi.getOrder(id))), [checked]);
    /** Подтверждение массового удаления */
    const onConfirmMassiveDelete = useCallback(() => {
        setLoading(true);
        const promises = checked.map(({ id: orderId, number }) => orderApi
            .delete({ orderId })
            .then(() => successAlert(`Платеж № ${number} успешно удален`))
            .catch(() => errorAlert(`Ошибка при удалении платежа № ${number}`)));
        Promise.allSettled(promises).finally(() => {
            setLoading(false);
            setChecked([]);
            reload();
        });
    }, [checked, errorAlert, reload, successAlert]);
    /** Состояние доступности кнопки массовых действий */
    const massiveButtonMode = useMemo(() => {
        if (!checked.length)
            return MassiveButtonMode.None;
        /** Все новые */
        if (checked.every(({ status }) => status === OrderStatuses.New))
            return MassiveButtonMode.NewOrders;
        /** Все подписанные */
        if (checked.every(({ status }) => status === OrderStatuses.Signed))
            return MassiveButtonMode.SignedOrders;
        /** Любые другие случаи */
        return MassiveButtonMode.None;
    }, [checked]);
    /** Множественные действия */
    const massiveActions = useMemo(() => {
        const pdfExportItem = {
            title: 'Экспорт PDF',
            onClick: () => {
                setLoading(true);
                orderApi
                    .getPdfLink(checked.map(i => i.id))
                    .then(download)
                    .catch(() => errorAlert('Ошибка во время загрузки PDF'))
                    .finally(() => setLoading(false));
            },
            disabled: !checked.length ||
                checked.some(item => !item.isPDFExportable) ||
                !haveAccess(PPayDocumentsMapper.ExportPdf),
        };
        switch (massiveButtonMode) {
            case MassiveButtonMode.SignedOrders:
                return [
                    {
                        title: 'Отправить',
                        onClick: () => {
                            setLoading(true);
                            const promises = checked.map(({ id: orderId, number }) => orderApi
                                .sendSignedOrder({ orderId })
                                .then(() => successAlert(`Платеж № ${number} успешно отправлен`))
                                .catch(() => errorAlert(`Ошибка при отправке платежа № ${number}`)));
                            Promise.allSettled(promises).finally(() => {
                                setLoading(false);
                                setChecked([]);
                                reload();
                            });
                        },
                        disabled: !haveAccess(PPayDocumentsMapper.SendSigned),
                    },
                    pdfExportItem,
                ];
            case MassiveButtonMode.NewOrders:
                return [
                    {
                        title: 'Подписать',
                        onClick: onSign,
                        disabled: !haveAccess(PPayDocumentsMapper.SaveSignedOrder),
                    },
                    {
                        title: 'Подписать и отправить',
                        onClick: onSignAndSend,
                        disabled: !haveAccess(PPayDocumentsMapper.SaveSendSigned),
                    },
                    {
                        title: 'Удалить',
                        onClick: onBeginMassiveDelete,
                        disabled: !haveAccess(PPayDocumentsMapper.Delete),
                    },
                    pdfExportItem,
                ];
            default:
                return [pdfExportItem];
        }
    }, [
        checked,
        download,
        errorAlert,
        haveAccess,
        massiveButtonMode,
        onBeginMassiveDelete,
        onSign,
        onSignAndSend,
        reload,
        successAlert,
    ]);
    return (React.createElement("div", { className: stylesMain.contentWrapper },
        React.createElement(PageTitle, { title: '\u041F\u043B\u0430\u0442\u0435\u0436\u0438 \u0432 \u0440\u0443\u0431\u043B\u044F\u0445' },
            React.createElement("div", { className: styles.flexGap },
                React.createElement(DropdownButton, { position: 'bottom', disabled: loading || !massiveActions.length, dropDownItems: massiveActions }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0441 \u043F\u043B\u0430\u0442\u0435\u0436\u0430\u043C\u0438"),
                haveAccess(PPayDocumentsMapper.ImportFrom1c) && (React.createElement(RectButtonUI, { disabled: loading, onClick: onImport }, "\u0418\u043C\u043F\u043E\u0440\u0442 \u043F\u043B\u0430\u0442\u0435\u0436\u0435\u0439")),
                haveAccess(PPayDocumentsMapper.Create) && (React.createElement(RectButtonUI, { disabled: loading, onClick: onBeginCreate }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043F\u043B\u0430\u0442\u0435\u0436")))),
        React.createElement(GenericTable, { rowCheckProps: rowCheckProps, loading: loading, toolbar: toolbar, getItems: loadItems, columns: columns, onLoadError: onLoadError, pagination: pagination, getItemKey: getItemKey, rowProps: rowProps, paginationRef: paginationRef }),
        React.createElement(HistoryStatusPaymentPopup, { open: paymentsPopupOpened === PopupValues.STATUS, onClose: onClosePopup, id: paymentId, statusOrder: paymentStatus }),
        React.createElement(ImportPaymentPopup, { onClose: onClosePopup, onImportDone: reload, open: paymentsPopupOpened === PopupValues.IMPORT }),
        React.createElement(ConfirmDeletePopup, { onSubmit: onConfirmMassiveDelete, title: `${checked.length} ${fromPluralDescriptor(checked.length, pluralDescriptor)}`, open: isDelete, onClose: closeDeletePopup }),
        React.createElement(MultiSignPopup, { open: !!signPopupOpenMode, onSignComplete: onSignComplete, onCancel: onCancelSign, getDataToSign: getDataForSign, getErrorMessage: getErrorMessage, certificatePredicate: certificatePredicate })));
};
