import React from 'react';
import cn from 'classnames';
import styles from './tabs.module.scss';
/**
 * табы без рендера основного контента
 * */
export const Tabs = ({ options, onChange, activeTab }) => {
    /** Создать обработчик клика на таб */
    const createHandleTab = (tab) => () => onChange(tab);
    return (React.createElement("div", { className: styles.tabContainer }, options.map(option => (React.createElement("button", { onClick: createHandleTab(option), key: option.key, className: cn(styles.tabButton, {
            [styles.tabButtonActive]: activeTab === option,
        }), type: 'button' },
        React.createElement("div", { className: styles.tabNameContainer },
            option.tabTitle,
            option.notification && option.notification > 0 && (React.createElement("span", { className: styles.tabNotification }, option.notification))),
        React.createElement("span", { className: cn(styles.tabLine, {
                [styles.active]: activeTab === option,
            }) }))))));
};
