import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RangePicker } from '../range-picker/date-input';
import deleteIcon from '../../static/images/icons/close-green.svg';
import dateIcon from '../../static/images/icons/dateSign16.svg';
import { formatRangeDate } from '../../utils/smallFunctions';
import { WrapContainerUI } from '../wrap-container-ui';
import styles from './date-input-range.module.scss';
/**
 * Компонент выбора даты
 * */
export function DateInputRange({ placeholder = '', onChange: onChangeExternal, value, title, isClearable, className, disabled = false, }) {
    const [isOpened, setOpened] = useState(false);
    const ref = useRef(null);
    /** Обработка раскрытия выбора даты */
    const handleOpened = useCallback(() => setOpened(prev => !prev && !disabled), [disabled]);
    /** Обработка клика на документ для закрытия */
    useEffect(() => {
        const listener = ({ target }) => {
            const { current } = ref;
            if (current !== target)
                setOpened(false);
        };
        document.addEventListener('click', listener);
        return () => document.removeEventListener('click', listener);
    }, []);
    /** Обработка очистки интервала даты */
    const onClear = useCallback((e) => {
        e.stopPropagation();
        onChangeExternal(null);
        setOpened(false);
    }, [onChangeExternal]);
    const onChange = useCallback((range) => {
        onChangeExternal(range);
        setOpened(false);
    }, [onChangeExternal]);
    return (React.createElement(WrapContainerUI, { disabled: disabled, className: className, hasError: false, withErrorText: false, title: title },
        React.createElement("div", { "data-testid": 'container', ref: ref, onClick: handleOpened, className: styles.select },
            React.createElement("div", { className: styles.selectInputContainer },
                React.createElement("img", { className: styles.iconTableImageDate, src: dateIcon, alt: '\u043A\u0430\u043B\u0435\u043D\u0434\u0430\u0440\u044C' }),
                React.createElement("div", { className: styles.selectInput },
                    React.createElement("span", null, value ? formatRangeDate(value[0], value[1]) : placeholder)),
                isClearable && value && (React.createElement("img", { className: styles.closeIcon, onClick: onClear, src: deleteIcon, alt: '\u043E\u0447\u0438\u0441\u0442\u043A\u0430' }))),
            isOpened && (React.createElement("div", { "data-testid": 'calendar', onClick: e => e.stopPropagation() },
                React.createElement(RangePicker, { value: value, onChange: onChange }))))));
}
