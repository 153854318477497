import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import '../submodules/inkh-core-frontend/src/utils/styles/reset.scss';
import { App } from './components/app';
import store from './store/store';
import { getUserInfo, refreshToken } from './utils/api/generalApi';
import { AuthContext, DownloadContext } from './utils/general-code-import/components-import';
import { iniAPM } from './utils/general-code-import/config-import';
/** Инициализация elastic APM */
iniAPM('dev_local_Inkh_Legal_Web');
const container = document.querySelector('#root');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(BrowserRouter, { basename: '/' },
        React.createElement(Provider, { store: store },
            React.createElement(AuthContext, { refreshToken: refreshToken, getUserInfo: getUserInfo },
                React.createElement(DownloadContext, null,
                    React.createElement(App, null))))));
}
